import { useCallback } from 'react';
import { useSigning } from '@noah-labs/fe-shared-feature-signing';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import type { TpPinForm } from '@noah-labs/fe-shared-ui-signing';
import { SafeWrapper } from '@noah-labs/shared-cryptography';
import { logger } from '@noah-labs/shared-logger/browser';
import { CreatePinScene } from '../scenes/CreatePin';

export type PpCreatePin = {
  backTo?: string;
  canSkip?: boolean;
  setPhrase: (phrase: SafeWrapper<string>) => void;
  successTo: string;
};

export function CreatePin({
  backTo,
  canSkip,
  setPhrase,
  successTo,
}: PpCreatePin): React.ReactElement {
  const { push } = useRouter();
  const { createNewWallet } = useSigning();

  /**
   * Generates a new wallet and persists the phrase
   */
  const onSubmit = useCallback(
    async ({ pin }: TpPinForm): Promise<void> => {
      try {
        const mnemonic = await createNewWallet(new SafeWrapper(pin));
        if (!mnemonic) {
          return;
        }

        setPhrase(mnemonic);
        push(successTo);
      } catch (error) {
        logger.error(error);
      }
    },
    [createNewWallet, push, setPhrase, successTo],
  );

  return (
    <CreatePinScene backTo={backTo} canSkip={canSkip} exitTo={successTo} onSubmit={onSubmit} />
  );
}
