import type {
  BooleanMap,
  CountryCode,
  FeatureRestrictedCountryRegionMap,
} from '@noah-labs/shared-schema-gql';
import type { Country } from 'countries-list';
import type { TpCountryNeeds } from './filter-functions';
import { getfilterCountryForFeature } from './filter-functions';
import type { TpCountryFilterFunction } from './types';

export async function countryFromCode(code: CountryCode): Promise<Country | undefined> {
  const CountriesList = await import('countries-list');
  return CountriesList.default.countries[code];
}

export function getFilterCountryForFeatureData(
  needs: TpCountryNeeds,
  prohibitedCountries: BooleanMap,
  restrictedCountries: FeatureRestrictedCountryRegionMap,
): TpCountryFilterFunction {
  return getfilterCountryForFeature({
    needs,
    prohibitedCountries,
    restrictedCountries,
  });
}
