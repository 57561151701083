import type { Dispatch, ReactElement, SetStateAction } from 'react';
import { useCallback } from 'react';
import { useSigning } from '@noah-labs/fe-shared-feature-signing';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import type { TpPinForm } from '@noah-labs/fe-shared-ui-signing';
import {
  GenericPINError,
  IncorrectPinError,
  LockedPinError,
  SafeWrapper,
} from '@noah-labs/shared-cryptography';
import type { UseFormSetError } from 'react-hook-form';
import { EnterCurrentPinScene } from '../scenes/EnterCurrentPin';

type EnterCurrentPinProps = {
  setMnemonic: Dispatch<SetStateAction<SafeWrapper<string> | undefined>>;
  successTo: string;
};

export function EnterCurrentPin({ setMnemonic, successTo }: EnterCurrentPinProps): ReactElement {
  const { push } = useRouter();
  const { deriveScw } = useSigning();

  const onSubmit = useCallback(
    async ({ pin }: TpPinForm, setError: UseFormSetError<TpPinForm>): Promise<void> => {
      try {
        const wp = new SafeWrapper(pin.trim().toLowerCase());

        const wallet = await deriveScw(wp);

        setMnemonic(wallet.mnemonic);
      } catch (err) {
        if (err instanceof IncorrectPinError) {
          return setError('pin', { message: err.message });
        }

        if (err instanceof LockedPinError) {
          return setError('pin', { message: err.message });
        }

        if (err instanceof GenericPINError) {
          return setError('pin', { message: err.message });
        }

        return setError('pin', { message: 'Sorry, something went wrong.' });
      }

      return push(successTo);
    },
    [push, successTo, deriveScw, setMnemonic],
  );

  return <EnterCurrentPinScene onSubmit={onSubmit} />;
}
