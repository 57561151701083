import { useUserAccountsReceive } from '@noah-labs/fe-shared-feature-user';
import { generatePath } from '@noah-labs/fe-shared-ui-components';
import { cryptoCurrencyFromCode } from '@noah-labs/fe-shared-ui-shared';
import { AccountCardList } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { isFalseyOrEmptyArray } from '@noah-labs/shared-util-vanilla';
import { AccountCardData } from '../../components/balance/AccountCardData';
import { SelectCryptoScene } from '../../scenes/SelectCrypto';
import { getReceiveCryptoRoute } from '../../utils/getReceiveCryptoRoute';

export function Receive(): React.ReactElement {
  const { data: accountsData, isFetched } = useUserAccountsReceive();

  const AccountsSlot = (
    <AccountCardList isFetched={isFetched}>
      {!isFalseyOrEmptyArray(accountsData) &&
        accountsData.map((account) => {
          const cryptoCurrency = cryptoCurrencyFromCode(account.CurrencyCode);
          const receivePath = getReceiveCryptoRoute(cryptoCurrency.requireNetworkSelection.receive);
          return (
            <AccountCardData
              key={account.ID}
              balance={account.Balance?.Available}
              cryptoCurrency={cryptoCurrency}
              href={generatePath(receivePath, {
                accountType: account.AccountType,
                currencyCode: account.CurrencyCode,
              })}
              id={account.ID}
            />
          );
        })}
    </AccountCardList>
  );

  return (
    <SelectCryptoScene
      AccountsSlot={AccountsSlot}
      backTo={walletRoutes().base}
      subTitle="Choose the currency you want to receive to your NOAH wallet."
      title="Receive"
    />
  );
}
