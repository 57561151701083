import { Switch404 } from '@noah-labs/fe-shared-ui-components';
import { TpAuthStatus } from '@noah-labs/fe-shared-ui-shared';
import { authRoutes } from '@noah-labs/fe-shared-util-routes';
import type { AwsCaptchaApi, SardineFlows } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { HelpIconButtonData } from '../../components/buttons/HelpIconButtonData';
import type { PpAccessControlData } from './AccessControlData';
import { AuthError } from './controllers/AuthError';
import { ForgottenPassword } from './controllers/ForgottenPassword';
import { ForgottenPasswordNewPassword } from './controllers/ForgottenPasswordNewPassword';
import { SignedOut } from './controllers/SignedOut';
import { SignIn } from './controllers/SignIn';
import { SignUp } from './controllers/SignUp';
import { VerifyEmail } from './controllers/VerifyEmail';
import { ForgottenPasswordSuccessScene } from './scenes/ForgottenPasswordSuccess';
import { SignOut } from './scenes/SignOut';

export type PpRouter = {
  AccessControlData: (props: PpAccessControlData) => React.ReactElement;
  authedPath: string;
  captcha: AwsCaptchaApi | undefined;
  helpButton?: React.ReactNode;
  referralCode: string | null;
  updateGuestSardineConfig: (flow: SardineFlows) => string;
};

export function Router({
  AccessControlData,
  authedPath,
  captcha,
  helpButton,
  referralCode,
  updateGuestSardineConfig,
}: PpRouter): React.ReactElement {
  return (
    <Switch404>
      <Route exact path={authRoutes.signUp}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.guest]} redirectAuthStatus={authedPath}>
          <SignUp
            captcha={captcha}
            helpButton={helpButton}
            referralCode={referralCode}
            updateGuestSardineConfig={updateGuestSardineConfig}
          />
        </AccessControlData>
      </Route>
      <Route exact path={authRoutes.signIn}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.guest]} redirectAuthStatus={authedPath}>
          <SignIn captcha={captcha} helpButton={helpButton} />
        </AccessControlData>
      </Route>
      <Route exact path={authRoutes.signOut}>
        {/* TODO (cs): this is current behaviour, maybe this should only be available to pbGroup though, need to test */}
        <SignOut />
      </Route>
      <Route exact path={authRoutes.signedOut}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.guest]}>
          <SignedOut />
        </AccessControlData>
      </Route>
      <Route exact path={authRoutes.verify}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticatedNotVerified]}
          redirectVerify={false}
        >
          <VerifyEmail helpButton={helpButton} />
        </AccessControlData>
      </Route>
      <Route exact path={authRoutes.recovery}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.guest]}>
          <ForgottenPassword helpButton={helpButton} returnTo={authRoutes.newPasswordEntry} />
        </AccessControlData>
      </Route>
      <Route exact path={authRoutes.newPasswordEntry}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <ForgottenPasswordNewPassword helpButton={<HelpIconButtonData />} />
        </AccessControlData>
      </Route>
      <Route exact path={authRoutes.newPasswordComplete}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <ForgottenPasswordSuccessScene helpButton={<HelpIconButtonData />} />
        </AccessControlData>
      </Route>

      <Route exact path={authRoutes.error}>
        <AuthError />
      </Route>
    </Switch404>
  );
}
