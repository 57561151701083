import { useDestinations } from '@noah-labs/fe-shared-data-access-analytics';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { useConsentOnSubmit } from '../hooks/useConsentOnSubmit';
import { useUserError } from '../hooks/useUserError';
import { ConsentScene } from '../scenes/Consent';

type PpConsent = {
  logoutTo?: string;
  nextAction?: () => void;
  nextActionDirty: () => void;
};

export function Consent({ logoutTo, nextAction, nextActionDirty }: PpConsent): React.ReactElement {
  const { data: userData } = useUserInitUi();
  const { data: { groupedDestinations } = {} } = useDestinations([
    webConfigBrowser.segment.writeKey,
    webConfigBrowser.analytics.cdnUrl,
  ]);

  const { error, handleConsentSubmit } = useConsentOnSubmit({
    groupedDestinations,
    nextAction,
    nextActionDirty,
  });

  const { ApiErrorScene } = useUserError(error);
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <ConsentScene
      destinations={groupedDestinations}
      email={userData?.userProfile.Email}
      logoutTo={logoutTo}
      userSettingsConsent={userData?.userSettingsConsent}
      variant="settings"
      onSubmit={handleConsentSubmit}
    />
  );
}
