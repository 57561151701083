import { formatFiatNoDecimals } from '@noah-labs/shared-currencies';
import { logger } from '@noah-labs/shared-logger/browser';
import type { QuestTask } from '@noah-labs/shared-schema-gql';
import { QuestTaskRequirement } from '@noah-labs/shared-schema-gql';
import type { TpQuestTaskCopy } from '../types';

export function getQuestTaskCopy(task: QuestTask): TpQuestTaskCopy | undefined {
  try {
    switch (task.Requirement) {
      /**
       * Deposit quest: infer currency and amount from task object.
       */
      case QuestTaskRequirement.Deposit: {
        if (!task.RequiredCurrencyCode) {
          switch (task.QuestTaskID) {
            case 'WarpStone/0':
              return {
                description:
                  'Make a single $20 bitcoin purchase with your NOAH account to secure the prestigious Warp Stone and 1000 NOAH Points.',
                reward: '+1000 points',
                title: '72 Hours | $20 Bitcoin Purchase',
              };
            default:
              throw new Error('missing currency');
          }
        }
        switch (task.QuestTaskID) {
          case 'Deposit/0': {
            if (!task.FiatCurrencyCode) {
              throw new Error('missing currency');
            }
            if (!task.RequiredAmount) {
              throw new Error('missing amount for deposit card');
            }
            return {
              description: `Fortune favors those who save. By depositing ${formatFiatNoDecimals(
                task.RequiredAmount,
                task.FiatCurrencyCode,
              )} in your NOAH account, you've taken the first step toward a bright future.`,
              reward: `+${task.RewardNoah} points`,
              title: `Deposit ${formatFiatNoDecimals(
                task.RequiredAmount,
                task.FiatCurrencyCode,
              )} worth of ${task.RequiredCurrencyCode}`,
            };
          }
          case 'FBlackFriday2022/0':
            return {
              description:
                'Deposit $20 into your NOAH account and get the Limited Edition Ark Stone | 001 — plus 300 NP along with it.',
              reward: '+300 points',
              title: `Deposit $20 worth of BTC`,
            };
          case 'FNewYear2023/0':
            return {
              description:
                'Deposit 100,000 satoshis at once into your NOAH account and get 500 NOAH Points.',
              reward: '+500 points',
              title: 'Deposit 100,000 sats',
            };
          case 'FValentine2023/0':
            return {
              description:
                'Deposit 100,000 satoshis at once into your NOAH account and get the Limited Edition Ark Stone | 002.',
              reward: '+500 points',
              title: 'Deposit 100,000 sats',
            };
          case 'BuyBitcoin/0':
            return {
              description:
                'One successful transaction is all it takes to earn you the Starlight Stone and 2000 NOAH Points.',
              reward: '+2000 points',
              title: 'Buy bitcoin with NOAH ',
            };
          default: {
            throw new Error('task id not recognized');
          }
        }
      }
      case QuestTaskRequirement.Claim: {
        return {
          description:
            'Embark on a thrilling journey to click the hidden Easter egg in our app and get the delightful Ark Stone | 003.',
          reward: '+200 points',
          title: 'Ark Stone | 003 Easter Egg Hunt',
        };
      }
      case QuestTaskRequirement.MarketingSignup: {
        return {
          description: 'Sign up to marketing comms.',
          reward: `+${task.RewardNoah} points`,
          title: 'Sign up to marketing comms',
        };
      }
      case QuestTaskRequirement.VerifyEmail: {
        return {
          description: 'Verify your email to complete onboarding.',
          reward: `+${task.RewardNoah} points`,
          title: 'Verify your email',
        };
      }
      case QuestTaskRequirement.UserSignup: {
        return {
          description: 'Complete the NOAH sign up process.',
          reward: `+${task.RewardNoah} points`,
          title: 'Sign up',
        };
      }
      case QuestTaskRequirement.TotalReferrals: {
        switch (task.QuestTaskID) {
          case 'Wanderer/0':
            return {
              description: `Refer ${
                task.RequiredCount || ''
              } friends to complete the quest and claim the sleek and sturdy Wanderer Badge.`,
              reward: `+100 points per referral`,
              title: `Refer ${task.RequiredCount || ''} friends`,
            };
          case 'Traveler/0': {
            return {
              description: `Refer ${
                task.RequiredCount || ''
              } friends to complete the quest and claim the sleek and sturdy Traveler Badge.`,
              reward: `+100 points per referral`,
              title: `Refer ${task.RequiredCount || ''} friends`,
            };
          }
          case 'Arkonaut/0': {
            return {
              description: `Refer ${
                task.RequiredCount || ''
              } friends to complete the quest and claim the sleek and sturdy Arkonaut Badge.`,
              reward: `+100 points per referral`,
              title: `Refer ${task.RequiredCount || ''} friends`,
            };
          }
          case 'Founder/0': {
            return {
              description: `Refer ${
                task.RequiredCount || ''
              } friends to complete the quest and claim the sleek and sturdy Founders Badge.`,
              reward: `+100 points per referral`,
              title: `Refer ${task.RequiredCount || ''} friends`,
            };
          }
          default: {
            throw new Error('task id not recognized');
          }
        }
      }
      case QuestTaskRequirement.RegisterLightningAddress: {
        return {
          description: 'Start here and register your very own NOAH.me Lightning Address.',
          reward: `+${task.RewardNoah} points`,
          title: 'Register a Lightning address',
        };
      }
      case QuestTaskRequirement.CreateLightningInvoice: {
        return {
          description: 'Creating a Lightning invoice will get you paid — lightning fast.',
          reward: `+${task.RewardNoah} points`,
          title: 'Create Lightning invoice',
        };
      }
      case QuestTaskRequirement.LightningInvoiceSettled: {
        return {
          description: 'Your journey ends with getting paid — cha-ching!',
          reward: `+${task.RewardNoah} points`,
          title: 'Have Lightning invoice paid',
        };
      }
      case QuestTaskRequirement.Referrals: {
        if (task.QuestTaskID.startsWith('FReferral20230927')) {
          return {
            description:
              'Refer your friends to NOAH through through Wednesday 11th and receive an extra 100 NP for every referral.',
            reward: '+100 points per referral',
            title: 'NOAH Boost',
          };
        }
        switch (task.QuestTaskID) {
          case 'FBlackFridayReferral2022/0': {
            return {
              description:
                'There’s always room for more aboard the Ark. Refer 5 friends to NOAH and we’ll triple the rewards. That’s 1500 NP for 5 friends. This weekend only. Light speed, Arkonauts.',
              reward: ``,
              title: ``,
            };
          }
          case 'FNewYearReferral2023/0':
            return {
              description:
                'Refer 5 friends to and receive 1000 NOAH points instead of the usual 500.',
              reward: '+1000 points',
              title: 'Refer 5 friends',
            };
          case 'FReferral20230203/0': {
            return {
              description: `Refer 5 new friends to the NOAH App before February 5th and receive a bonus +500 NOAH Points!`,
              reward: `+1000 points for 5 referrals`,
              title: `Refer ${task.RequiredCount || ''} friends`,
            };
          }
          case 'FValentineReferral2023/0': {
            return {
              description:
                'Refer your friends to NOAH before February 19th and receive 200 NP each for your next 10 friends!',
              reward: '+100 points per referral',
              title: 'Valentine’s Referral Quest',
            };
          }
          default: {
            throw new Error('task id not recognized');
          }
        }
      }
      case QuestTaskRequirement.Withdraw:
        switch (task.QuestTaskID) {
          case 'Bitrefill/0':
            return {
              description:
                'Embark on a new kind of adventure — make a gift card or phone top up purchase using Bitrefill to claim the Bitrefill Stone and earn 500 NOAH Points as a reward for your efforts.',
              reward: '+500 points',
              title: 'Make a Bitrefill purchase',
            };
          case 'FBitrefill/0':
            return {
              description:
                'Join the elite ranks with the limited edition Bitrefill Stone and 500 NOAH Points. Make a purchase using Bitrefill until the end of February 11th to set yourself apart from the rest.',
              reward: '+500 points',
              title: 'Make a Bitrefill purchase',
            };
          default: {
            throw new Error('task id not recognized');
          }
        }
      case QuestTaskRequirement.BotConnection: {
        switch (task.QuestTaskID) {
          case 'Discord/0':
            return {
              description:
                'Join our Discord community by connecting your account with NOAH Wallet and earn 200 NOAH Points as a reward.',
              reward: '+200 points',
              title: 'Connect Discord account',
            };
          default: {
            throw new Error('task id not recognized');
          }
        }
      }
      default: {
        throw new Error('task requirement not recognized');
      }
    }
  } catch (err) {
    logger.error(err);
    return undefined;
  }
}
