import { useUserAccountsSend } from '@noah-labs/fe-shared-feature-user';
import { generatePath } from '@noah-labs/fe-shared-ui-components';
import { cryptoCurrencyFromCode } from '@noah-labs/fe-shared-ui-shared';
import { AccountCardList } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { isFalseyOrEmptyArray } from '@noah-labs/shared-util-vanilla';
import { AccountCardData } from '../../components/balance/AccountCardData';
import { SelectCryptoScene } from '../../scenes/SelectCrypto';

export function Send(): React.ReactElement {
  const { data: accountsData, isFetched } = useUserAccountsSend();

  const AccountsSlot = (
    <AccountCardList isFetched={isFetched}>
      {!isFalseyOrEmptyArray(accountsData) &&
        accountsData.map((account) => (
          <AccountCardData
            key={account.ID}
            balance={account.Balance?.Available}
            cryptoCurrency={cryptoCurrencyFromCode(account.CurrencyCode)}
            href={generatePath(walletRoutes().address.text, {
              accountType: account.AccountType,
              currencyCode: account.CurrencyCode,
            })}
            id={account.ID}
          />
        ))}
    </AccountCardList>
  );

  return (
    <SelectCryptoScene
      AccountsSlot={AccountsSlot}
      backTo={walletRoutes().base}
      subTitle="Choose the currency you want to send to another crypto wallet or exchange."
      title="Send"
    />
  );
}
