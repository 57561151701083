import { Switch404 } from '@noah-labs/fe-shared-ui-components';
import { TpAuthStatus } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Route } from 'react-router-dom';
import { AccessControlData } from '../../../auth/AccessControlData';
import { KycAccessControlData } from '../../../kyc/controllers/KycAccessControlData';
import { KycReminderOrigin } from '../../../kyc/controllers/Reminder';
import { Receive } from './Receive';
import { Send } from './Send';

export function SelectCryptoRouter(): React.ReactElement {
  return (
    <Switch404>
      <Route exact path={walletRoutes().selectCrypto.receive}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <KycAccessControlData origin={KycReminderOrigin.Deposit}>
            <Receive />
          </KycAccessControlData>
        </AccessControlData>
      </Route>

      <Route exact path={walletRoutes().selectCrypto.send}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <KycAccessControlData bypassIfUserHasBalance origin={KycReminderOrigin.Withdraw}>
            <Send />
          </KycAccessControlData>
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
