import { generatePath } from '@noah-labs/fe-shared-ui-components';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { getDefaults } from '../../../utils/getDefaults';
import { routes } from '../routes';
import { TpRewardsTabs } from '../types';
import { questNames } from './questCopy';

const { accountType, cryptoCurrency } = getDefaults();

export function getBadgeButtonLink(
  questId: string | undefined | null,
  questFeatureEnabled?: boolean | undefined,
): string | undefined {
  if (!questId) {
    return undefined;
  }

  if (questId === 'BuyBitcoin' || questId === 'WarpStone') {
    return generatePath(walletRoutes().buy.base, {
      accountType,
      currencyCode: cryptoCurrency.code,
    });
  }

  if (questFeatureEnabled) {
    return generatePath(routes.quest, {
      id: questId,
    });
  }
  return generatePath(routes.tabs, {
    tab: TpRewardsTabs.Quests,
  });
}

export function getBadgeButtonCopy(
  questId: string | undefined | null,
  questFeatureEnabled?: boolean | undefined,
): string {
  if (!questFeatureEnabled || !questId) {
    return 'Back to Quests';
  }
  if (questId === 'BuyBitcoin' || questId === 'WarpStone') {
    return 'Buy bitcoin';
  }
  return `Go to ${questNames[questId]}`;
}
