import { Fragment } from 'react';
import { TpAuthStatus } from '@noah-labs/fe-shared-ui-shared';
import { userRoutes } from '@noah-labs/fe-shared-util-routes';
import { Route } from 'react-router-dom';
import { AccessControlData } from '../auth/AccessControlData';
import { EditPaymentMethod } from './controllers/EditPaymentMethod';
import { NewPaymentMethod } from './controllers/NewPaymentMethod';
import { PaymentMethods } from './controllers/PaymentMethods';

export function PaymentMethodsRouter(): React.ReactElement {
  return (
    <Fragment>
      {/* preload new payment form because it uses checkout Frames */}
      <Route
        exact
        path={[userRoutes.settings.paymentMethods, userRoutes.settings.newPaymentMethod]}
      >
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <NewPaymentMethod />
        </AccessControlData>
      </Route>

      <Route exact path={userRoutes.settings.editPaymentMethod}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <EditPaymentMethod />
        </AccessControlData>
      </Route>

      <Route exact path={userRoutes.settings.paymentMethods}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <PaymentMethods />
        </AccessControlData>
      </Route>
    </Fragment>
  );
}
