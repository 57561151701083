import { KeyholeIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/KeyholeIcon';
import {
  AppContainer,
  AppFooter,
  AppMain,
  PrimaryButton,
  SceneIcon,
  SceneMain,
  SceneParagraph,
  SceneTitleLarge,
} from '@noah-labs/fe-shared-ui-components';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';

export function CompletePendingScene(): React.ReactElement {
  return (
    <AppContainer>
      <Helmet>
        <title>Verification in process</title>
      </Helmet>
      <AppMain>
        <AppHeaderData disableFade exitButton helpButton />
        <SceneMain dataQa="completePending">
          <SceneIcon>
            <KeyholeIcon />
          </SceneIcon>
          <SceneTitleLarge>Verification in process</SceneTitleLarge>
          <SceneParagraph>
            Thanks for submitting your documentation to keep your account safe from fraud. We
            usually verify within 24 hours, but in some rare cases it can take up to 48 hours.
          </SceneParagraph>
          <SceneParagraph>
            You can check the status of your verification in settings.
          </SceneParagraph>
        </SceneMain>
      </AppMain>
      <AppFooter solidFade>
        <PrimaryButton data-qa="done-button" href={walletRoutes().base}>
          Done
        </PrimaryButton>
      </AppFooter>
    </AppContainer>
  );
}
