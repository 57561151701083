import { useUserAccounts } from '@noah-labs/fe-shared-feature-user';
import { generatePath } from '@noah-labs/fe-shared-ui-components';
import { cryptoCurrencyFromCode } from '@noah-labs/fe-shared-ui-shared';
import { AccountCardList } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { SardineFlows } from '@noah-labs/shared-schema-gql';
import { isFalseyOrEmptyArray } from '@noah-labs/shared-util-vanilla';
import { useSardineFlow } from '../../../sardine/hooks/useSardineFlow';
import { AccountCardData } from '../../components/balance/AccountCardData';
import { CurrentBalanceDataWithCurrencyDialog } from '../../components/balance/CurrentBalanceDataWithCurrencyDialog';
import { ActionsMenuData } from '../../components/menus/ActionsMenuData';
import { AccountsScene } from '../../scenes/Accounts';

export function Accounts(): React.ReactElement {
  useSardineFlow({ flow: SardineFlows.Dashboard });
  const { data, isFetched } = useUserAccounts();

  const AccountsSlot = (
    <AccountCardList isFetched={isFetched}>
      {!isFalseyOrEmptyArray(data) &&
        data.map((account) => (
          <AccountCardData
            key={account.ID}
            balance={account.Balance?.Available}
            cryptoCurrency={cryptoCurrencyFromCode(account.CurrencyCode)}
            href={generatePath(walletRoutes().account.base, {
              accountType: account.AccountType,
              currencyCode: account.CurrencyCode,
            })}
            id={account.ID}
          />
        ))}
    </AccountCardList>
  );

  return (
    <AccountsScene
      AccountsSlot={AccountsSlot}
      ActionsMenuSlot={<ActionsMenuData />}
      TotalBalanceSlot={<CurrentBalanceDataWithCurrencyDialog />}
    />
  );
}
