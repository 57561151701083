import type { TpAllowance } from '@noah-labs/fe-shared-data-access-user';
import { CryptoAmount } from '@noah-labs/fe-shared-ui-components';
import type { TpCryptoUnitAmount } from '@noah-labs/fe-shared-ui-shared';
import type { TpFiatCurrency } from '@noah-labs/shared-currencies';
import { isNonZero, isUndefinedOrNull, safeBN } from '@noah-labs/shared-util-vanilla';
import * as yup from 'yup';
import { maxSafetyAmount } from './amounts';
import { getCryptoWithdrawalLimitsSchema } from './getCryptoWithdrawalLimitsSchema';
import type { TpAmountForm } from './types';

type TpGetLnSendSchema = {
  allowance: TpAllowance | undefined;
  fiatCurrency: TpFiatCurrency | undefined;
  maxAmounts: TpCryptoUnitAmount | undefined;
  minAmounts: TpCryptoUnitAmount | undefined;
};
export function getLnSendSchema({
  allowance,
  fiatCurrency,
  maxAmounts,
  minAmounts,
}: TpGetLnSendSchema): yup.ObjectSchema<Partial<TpAmountForm>> {
  return yup.object<Partial<TpAmountForm>>({
    cryptoAmount: yup.lazy(() => {
      let schema = getCryptoWithdrawalLimitsSchema(allowance);
      if (!isUndefinedOrNull(maxAmounts)) {
        schema = schema.test({
          message: ({ value }) => {
            if (!isNonZero(value as string)) {
              return null;
            }
            return (
              <span>
                Maximum amount is{' '}
                <CryptoAmount
                  amount={maxAmounts.amountBtc}
                  currency={maxAmounts.cryptoCurrency}
                  currencyUnit={maxAmounts.unit}
                />
              </span>
            );
          },
          test: (valueBtc) => safeBN(valueBtc).lte(maxAmounts.amountBtc),
        });
      }

      if (!isUndefinedOrNull(minAmounts)) {
        schema = schema.test({
          message: ({ value }) => {
            if (!isNonZero(value as string)) {
              return null;
            }
            return (
              <span>
                Minimum amount is{' '}
                <CryptoAmount
                  amount={minAmounts.amountBtc}
                  currency={minAmounts.cryptoCurrency}
                  currencyUnit={minAmounts.unit}
                />
              </span>
            );
          },
          test: (valueBtc) => {
            if (!isNonZero(valueBtc)) {
              return false;
            }
            return safeBN(valueBtc).gte(minAmounts.amountBtc);
          },
        });
      }

      return schema;
    }),
    fiatAmount: maxSafetyAmount(fiatCurrency),
  });
}
