import { Alert } from '@mui/material';
import {
  AppMain,
  AvatarIconFromSvg,
  GetInTouchLink,
  List,
  ListItem,
  ListItemButton,
  ListItemCard,
  ListItemContent,
  ListItemIcon,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
  SceneHeader,
  SceneMain,
  SceneParagraph,
  SceneTitleMedium,
} from '@noah-labs/fe-shared-ui-components';
import type { TpCryptoNetworkUi } from '@noah-labs/fe-shared-ui-shared';

export type PpSelectNetworkScene = {
  action: 'send' | 'receive';
  networks: Array<TpCryptoNetworkUi>;
  onNetworkSelect: (network: TpCryptoNetworkUi) => void;
};

export function SelectNetworkScene({
  action,
  networks,
  onNetworkSelect,
}: PpSelectNetworkScene): React.ReactElement {
  return (
    <AppMain>
      <SceneHeader dense>
        <SceneTitleMedium>Select network</SceneTitleMedium>
        <SceneParagraph>
          Choose the currency network on which you want to {action} funds.
        </SceneParagraph>
      </SceneHeader>
      <SceneMain dense dataQa="select-network">
        <List spacing={1}>
          {networks.map((network) => (
            <ListItem key={network.id} data-qa={`network-${network.id}`}>
              <ListItemButton onClick={(): void => onNetworkSelect(network)}>
                <ListItemCard>
                  <ListItemContent>
                    <ListItemIcon>
                      <AvatarIconFromSvg svg={network.svg} />
                    </ListItemIcon>
                    <ListItemStartContent>
                      <ListItemPrimaryText>{network.label}</ListItemPrimaryText>
                      {network.description && (
                        <ListItemSecondaryText>{network.description}</ListItemSecondaryText>
                      )}
                    </ListItemStartContent>
                  </ListItemContent>
                </ListItemCard>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Alert severity="warning">
          Please ensure you are using the correct network. It is important and using the wrong one
          may result in the loss of funds. Please <GetInTouchLink text="get help" /> if you are
          unsure.
        </Alert>
      </SceneMain>
    </AppMain>
  );
}
