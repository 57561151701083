import type { UserAppSettingsUpdateInput } from '@noah-labs/shared-schema-gql';
import { useQueryClient } from 'react-query';
import { updateUserInitCache } from '../data/updateUserInitCache';
import type { UserInitQuery } from '../data/user.generated';

// This key must be the same as the query declared here: apps/fe/web/src/modules/user/data/user.graphql
const queryKey = ['UserInit'];

type TpQueryContext = {
  previousValue: UserInitQuery | undefined;
};

type TpOptimisticUpdateOptions = {
  onError: (err: unknown, _newValue: unknown, context?: TpQueryContext) => void;
  onMutate: (newValue: { Input: UserAppSettingsUpdateInput }) => Promise<TpQueryContext>;
  onSettled: () => void;
};

export function useOptimisticUserInitOptions(): TpOptimisticUpdateOptions {
  const queryClient = useQueryClient();
  return {
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_err, _newValue, context): void => {
      updateUserInitCache(
        queryClient,
        context?.previousValue?.userProfile,
        context?.previousValue?.userSettingsConsent,
      );
    },
    onMutate: async (newValue): Promise<TpQueryContext> => {
      await queryClient.cancelQueries(queryKey);

      // Snapshot the previous value
      const previousValue = queryClient.getQueryData<UserInitQuery>(queryKey);

      // Optimistically update to the new value
      updateUserInitCache(
        queryClient,
        // @ts-expect-error because partial is expecting undefined whereas this could be null - for us, this is okay
        newValue.Input,
        previousValue?.userSettingsConsent,
      );

      return { previousValue };
    },
    // Always refetch after error or success
    onSettled: (): void => {
      void queryClient.invalidateQueries(queryKey);
    },
  };
}
