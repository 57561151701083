import {
  AppContainer,
  AppMain,
  SceneHeader,
  SceneMain,
  SceneParagraph,
  SceneTitleMedium,
} from '@noah-labs/fe-shared-ui-components';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';

export type PpSelectCrypto = {
  AccountsSlot: React.ReactElement;
  backTo: string;
  subTitle: string;
  title: string;
};

export function SelectCryptoScene({
  AccountsSlot,
  backTo,
  subTitle,
  title,
}: PpSelectCrypto): React.ReactElement {
  return (
    <AppContainer>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AppMain>
        <AppHeaderData exitButton helpButton backTo={backTo} />
        <SceneHeader dense>
          <SceneTitleMedium>{title}</SceneTitleMedium>
          <SceneParagraph>{subTitle}</SceneParagraph>
        </SceneHeader>
        <SceneMain dense dataQa="select-crypto">
          {AccountsSlot}
        </SceneMain>
      </AppMain>
    </AppContainer>
  );
}
