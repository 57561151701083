import { useCallback } from 'react';
import type { TpFeFeature, TpFeFeatureFlag } from '@noah-labs/fe-shared-data-access-user';
import type { TpStateMachine } from '@noah-labs/fe-shared-ui-components';
import type { TpAddressData } from '@noah-labs/fe-shared-ui-components/crypto';
import type { TpCryptoCurrencyUI, TpCryptoNetworkUi } from '@noah-labs/fe-shared-ui-shared';
import { getAvailableCryptoNetworks } from '@noah-labs/fe-shared-ui-shared';
import { SelectNetworkScene } from '@noah-labs/fe-shared-ui-wallet';
import { Feature, Network } from '@noah-labs/shared-schema-gql';
import type { SmWithdraw } from '../types';

const NETWORKS_TO_DISPLAY = [
  Network.Ethereum,
  Network.EthereumTestSepolia,
  Network.PolygonPos,
  Network.PolygonTestAmoy,
];

type PpWithdrawNetworkController = TpStateMachine<SmWithdraw> & {
  cryptoCurrency: TpCryptoCurrencyUI;
  onNext: () => void;
  userFeatures: Map<TpFeFeature, TpFeFeatureFlag> | undefined | null;
};

export function WithdrawNetworkController({
  cryptoCurrency,
  onNext,
  state,
  updateState,
  userFeatures,
}: PpWithdrawNetworkController): React.ReactElement {
  const withdrawFF = userFeatures?.get(Feature.Withdraw);
  const networks = getAvailableCryptoNetworks(cryptoCurrency.code, withdrawFF, NETWORKS_TO_DISPLAY);

  const onNetworkSelect = useCallback(
    (network: TpCryptoNetworkUi) => {
      // because of the routing guards, state.payeeData should be set by this point
      if (!state.payeeData) {
        throw new Error('payee is not set');
      }
      updateState({
        // TODO (cs) use 'as' here for now because some discriminated union is complicating it
        payeeData: {
          ...state.payeeData,
          network: network.id,
        } as TpAddressData,
      });
      onNext();
    },
    [onNext, state.payeeData, updateState],
  );

  return <SelectNetworkScene action="send" networks={networks} onNetworkSelect={onNetworkSelect} />;
}
