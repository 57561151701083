import type { ReactElement } from 'react';
import { Fragment } from 'react';
import { Divider } from '@mui/material';
import { AvailableBalanceData, useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-shared';
import { getCurrencyAmountSlots, NetworkFeeItem } from '@noah-labs/fe-shared-ui-wallet';
import type { TpAmount } from '@noah-labs/shared-currencies';
import type { AccountType as TpAccountType } from '@noah-labs/shared-schema-gql';

type PpAvailableBalanceWithNetworkFeeData = {
  AccountType: TpAccountType;
  cryptoAmount: TpAmount;
  cryptoCurrency: TpCryptoCurrencyUI;
  fiatAmount: TpAmount;
};

export function AvailableBalanceWithNetworkFeeData({
  AccountType,
  cryptoAmount,
  cryptoCurrency,
  fiatAmount,
}: PpAvailableBalanceWithNetworkFeeData): ReactElement {
  const { data: userData } = useUserInitUi();

  const FeeAmountSlots = getCurrencyAmountSlots({
    cryptoAmount,
    cryptoCurrency,
    cryptoUnit: userData?.userProfile.DisplayUnit,
    fiatAmount,
    fiatCurrency: userData?.userProfile.fiatCurrency,
    isCryptoApprox: true,
    isFiatApprox: true,
    primaryCurrency: userData?.userProfile.PrimaryCurrency,
    roundDown: true,
  });

  return (
    <AvailableBalanceData
      AccountType={AccountType}
      cryptoCurrency={cryptoCurrency}
      ListItemsSlot={
        <Fragment>
          <Divider />
          <NetworkFeeItem cryptoCurrency={cryptoCurrency} {...FeeAmountSlots} />
        </Fragment>
      }
    />
  );
}
