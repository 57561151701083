import type { ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import { BellCircleIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/BellCircleIcon';
import { NoahCircleIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/NoahCircleIcon';
import { Avatar, AvatarBadge, AvatarIcon, StatusBadge } from '@noah-labs/fe-shared-ui-components';

export function MarketingNotificationIcon(): ReactElement {
  const theme = useTheme();

  return (
    <Avatar>
      <AvatarIcon>
        <NoahCircleIcon />
      </AvatarIcon>

      <AvatarBadge>
        <StatusBadge
          badgeColor={theme.palette.system.green.main}
          badgeSize={2}
          Icon={<BellCircleIcon />}
        />
      </AvatarBadge>
    </Avatar>
  );
}
