import {
  AppContainer,
  AppFooter,
  AppMain,
  ButtonGroup,
  PrimaryButton,
  SceneImage,
  SceneMain,
  SceneParagraph,
  SceneTitleLarge,
} from '@noah-labs/fe-shared-ui-components';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';
import DiscordNoahImage from '../../../images/discord-noah-connect.svg?url';

export type PpConfirmScene = {
  discordConnectCreated: string;
  discordUsername: string;
  isCancelling: boolean;
  isConfirming: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export function ConfirmScene({
  discordConnectCreated,
  discordUsername,
  isCancelling,
  isConfirming,
  onCancel,
  onConfirm,
}: PpConfirmScene): React.ReactElement {
  return (
    <AppContainer>
      <Helmet>
        <title>Confirm link to Discord account</title>
      </Helmet>
      <AppMain sx={{ textAlign: 'center' }}>
        <AppHeaderData exitButton helpButton />
        <SceneMain dataQa="confirm">
          <SceneImage alt="" src={DiscordNoahImage} />
          <SceneTitleLarge>Confirm connection</SceneTitleLarge>
          <SceneParagraph>
            You are about to connect your NOAH account to Discord requested by{' '}
            <strong>{discordUsername}</strong> on{' '}
            <strong>{dayjs(discordConnectCreated).toString()}</strong>.
          </SceneParagraph>
        </SceneMain>
      </AppMain>
      <AppFooter>
        <ButtonGroup>
          <PrimaryButton color="primaryBrand" loading={isConfirming} onClick={onConfirm}>
            Confirm
          </PrimaryButton>
          <PrimaryButton loading={isCancelling} variant="text" onClick={onCancel}>
            Cancel
          </PrimaryButton>
        </ButtonGroup>
      </AppFooter>
    </AppContainer>
  );
}
