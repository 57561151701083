import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CloseCircleIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CloseCircleIcon';
import { CodeIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CodeIcon';
import {
  AppContainer,
  AppFooter,
  AppHeaderTitle,
  AppMain,
  AvatarIconFromSvg,
  ElevatedCardListItem,
  FormGrid,
  FormItem,
  InputField,
  LearnMoreButton,
  List,
  ListItemContent,
  ListItemIcon,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
  ListSection,
  PrimaryButton,
  SceneMain,
  TextWithIcon,
  TruncatedText,
} from '@noah-labs/fe-shared-ui-components';
import type { TpAddressData } from '@noah-labs/fe-shared-ui-components/crypto';
import { cryptoCurrencyFromCode, useDebounce } from '@noah-labs/fe-shared-ui-shared';
import { AddressSummaryCard, SkeletonAddressSummaryCard } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import type { CurrencyCode } from '@noah-labs/shared-schema-gql';
import { Helmet } from 'react-helmet';
import type { UseFormSetError } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';
import { useBackHijack } from '../../../hooks/useBackHijack';
import type { TpRecentAddresses } from '../hooks/useRecentAddresses';
import { getAddressErrorMessageElement } from '../utils/errors';
import { getCurrencyInfo } from '../utils/getCurrencyInfo';

const defaultValues = { address: '' };
export type TpAddressForm = typeof defaultValues;

const addressValidationDelayMs = 1200;

const addressFormSchema = yup.object({
  address: yup.string().required('Address is a required field'),
});

export type PpAddressManualScene = {
  addressData: TpAddressData | undefined;
  currencyCode: CurrencyCode;
  handleAddress: (
    code: string,
    currencyCode: CurrencyCode,
    setError: UseFormSetError<TpAddressForm>,
  ) => void;
  handleNextUrl: () => void;
  handleRedirectToScan: () => void;
  isLoading: boolean;
  recentAddresses: TpRecentAddresses[];
  scannedAddress: string;
  scannerUnavailable: boolean;
};

export function AddressManualScene({
  addressData,
  currencyCode,
  handleAddress,
  handleNextUrl,
  handleRedirectToScan,
  isLoading,
  recentAddresses,
  scannedAddress,
  scannerUnavailable,
}: PpAddressManualScene): React.ReactElement {
  const theme = useTheme();
  const debounce = useDebounce();
  const { backTo, goBack } = useBackHijack(walletRoutes().selectCrypto.send);

  const methods = useForm<TpAddressForm>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(addressFormSchema),
  });
  const {
    formState: { errors },
    setError,
    setValue,
    watch,
  } = methods;

  const addressValue = watch('address');

  const styles = {
    addressListItem: css`
      &:not(:last-child) {
        margin-bottom: ${theme.spacing(1)};
      }
    `,
  };

  const ScanIconButton = scannerUnavailable ? undefined : (
    <IconButton
      aria-label="scan address"
      disabled={isLoading}
      edge="end"
      onClick={handleRedirectToScan}
    >
      <CodeIcon color="primary" />
    </IconButton>
  );

  const ClearIconButton = addressValue ? (
    <IconButton
      aria-label="clear address"
      disabled={isLoading}
      edge="end"
      onClick={(): void => setValue('address', '', { shouldValidate: true })}
    >
      <CloseCircleIcon color={isLoading ? 'disabled' : 'primary'} />
    </IconButton>
  ) : undefined;

  const { label, learnMoreLink, subTitle } = getCurrencyInfo(currencyCode);
  const helperText = getAddressErrorMessageElement(errors.address?.type);

  const showAlertAndRecentAddresses = !isLoading && !addressData && !helperText;
  const showAlert = showAlertAndRecentAddresses && subTitle && learnMoreLink;

  useEffect(() => {
    if (!addressValue && !addressData?.address) {
      return;
    }
    debounce(() => handleAddress(addressValue, currencyCode, setError), addressValidationDelayMs);
  }, [addressValue, currencyCode, addressData?.address, handleAddress, setError, debounce]);

  useEffect(() => {
    if (!scannedAddress) {
      return;
    }
    setValue('address', scannedAddress);
  }, [scannedAddress, setValue]);

  return (
    <AppContainer>
      <Helmet>
        <title>Enter Address</title>
      </Helmet>
      <AppMain>
        <AppHeaderData exitButton helpButton backButton={goBack} backTo={backTo}>
          <AppHeaderTitle>Enter Address</AppHeaderTitle>
        </AppHeaderData>
        <SceneMain dataQa="enter-address">
          <Stack spacing={2}>
            <FormProvider {...methods}>
              <form id="addressForm">
                <FormGrid>
                  <FormItem fullWidth>
                    <InputField
                      fullWidth
                      required
                      aria-label={label}
                      dataQa="address"
                      disabled={isLoading}
                      endSlot={ClearIconButton || ScanIconButton}
                      helperText={helperText}
                      label="To"
                      name="address"
                      placeholder={label}
                    />
                  </FormItem>
                </FormGrid>
              </form>
            </FormProvider>

            {showAlert && (
              <Alert severity="warning">
                {subTitle} <LearnMoreButton href={learnMoreLink} target="_blank" />
              </Alert>
            )}

            {scannerUnavailable && (
              <Alert data-qa="no-scanner-alert" severity="info">
                Camera unavailable, please enter the text address manually.
              </Alert>
            )}

            {isLoading && !addressData && <SkeletonAddressSummaryCard />}

            {addressData && (
              <React.Fragment>
                <AddressSummaryCard
                  address={addressData.address}
                  cryptoCurrency={cryptoCurrencyFromCode(addressData.currencyCode)}
                  network={addressData.network}
                />
                <Typography color="text.light" variant="paragraphBodyS">
                  Compatible address found. The list of addresses you can send to is determined by
                  the currency and network.
                </Typography>
              </React.Fragment>
            )}
          </Stack>

          {showAlertAndRecentAddresses && recentAddresses.length > 0 && (
            <ListSection sx={{ mt: 4 }} title="Recent addresses" titleColor="text.dark">
              <List disablePadding data-qa="recent-addresses">
                {recentAddresses.map(({ address, cryptoCurrency, cryptoNetwork }) => (
                  <ElevatedCardListItem
                    key={address}
                    css={styles.addressListItem}
                    dataQa={`address-${address}`}
                    onClick={(): void => {
                      setValue('address', address, { shouldValidate: true });
                    }}
                  >
                    <ListItemContent padding={0}>
                      <ListItemIcon>
                        <AvatarIconFromSvg size={3} svg={cryptoCurrency.svg} />
                      </ListItemIcon>
                      <ListItemStartContent>
                        <ListItemPrimaryText>
                          <TruncatedText prefix={12} text={address} />
                        </ListItemPrimaryText>
                        <ListItemSecondaryText>
                          <TextWithIcon>
                            <AvatarIconFromSvg size={1.5} svg={cryptoNetwork.svg} />
                            <span>Via {cryptoNetwork.label}</span>
                          </TextWithIcon>
                        </ListItemSecondaryText>
                      </ListItemStartContent>
                    </ListItemContent>
                  </ElevatedCardListItem>
                ))}
              </List>
            </ListSection>
          )}
        </SceneMain>
      </AppMain>
      <AppFooter>
        <PrimaryButton
          color="primaryBrand"
          data-qa="continue-button"
          disabled={!addressData}
          form="addressForm"
          loading={isLoading}
          onClick={handleNextUrl}
        >
          Continue
        </PrimaryButton>
      </AppFooter>
    </AppContainer>
  );
}
