import React, { useCallback, useRef } from 'react';
import { Stack } from '@mui/material';
import type { TpPaymentMethodChange } from '@noah-labs/fe-shared-feature-wallet';
import { LockIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/LockIcon';
import {
  AppContainer,
  AppFooter,
  AppHeaderTitle,
  AppMain,
  Balance,
  FooterContentText,
  LearnMoreButton,
  ListSection,
  PrimaryButton,
  SceneMain,
} from '@noah-labs/fe-shared-ui-components';
import type { TpCryptoCurrencyUI, TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import { CheckoutFeeDialog, TransactionSummaryCard } from '@noah-labs/fe-shared-ui-wallet';
import type { Promotion } from '@noah-labs/shared-schema-gql';
import { Helmet } from 'react-helmet';
import { VerifyAccountDialog } from '../../../components/dialogs/VerifyAccountDialog';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';
import { getPromoContent } from '../../../utils/promos';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { CryptoVolatilityDialog } from '../components/dialogs/CryptoVolatilityDialog';
import { ProcessingTimeDialog } from '../components/dialogs/ProcessingTimeDialog';
import { PaymentMethodsPicker } from '../components/payment/PaymentMethodsPicker/PaymentMethodsPicker';
import type { StBuyRouter } from '../controllers/buy/BuyRouter';

export type PpConfirmBuyScene = {
  CryptoPriceSlot: React.ReactElement;
  FeeAmountSlot: React.ReactNode;
  NetCryptoAmountSlot: React.ReactNode;
  TotalFiatAmountSlot: React.ReactNode;
  applePayEnabled: boolean;
  backTo: string;
  cryptoCurrency: TpCryptoCurrencyUI;
  ctaButtonLabel?: string;
  feePromotion?: Promotion;
  googlePayEnabled: boolean;
  isLoading: boolean;
  kycRequired: boolean;
  onConfirm: () => void;
  onPaymentMethodChange: ({ card, type }: TpPaymentMethodChange) => void;
  onSubmitCardDetailsRedirect: string;
  pageTitle: string;
} & Pick<StBuyRouter, 'paymentMethod' | 'selectedPaymentCard'>;

const { feePercentage } = webConfigBrowser.cko;

export function ConfirmBuyScene({
  applePayEnabled,
  backTo,
  cryptoCurrency,
  CryptoPriceSlot,
  ctaButtonLabel = 'Continue',
  FeeAmountSlot,
  feePromotion,
  googlePayEnabled,
  isLoading,
  kycRequired,
  NetCryptoAmountSlot,
  onConfirm,
  onPaymentMethodChange,
  onSubmitCardDetailsRedirect,
  pageTitle,
  paymentMethod,
  selectedPaymentCard,
  TotalFiatAmountSlot,
}: PpConfirmBuyScene): React.ReactElement {
  const cryptoVolatilityDialog = useRef<TpDialogToggle>(null);
  const feeInfoDialog = useRef<TpDialogToggle>(null);
  const processingTimeDialog = useRef<TpDialogToggle>(null);
  const kycDialog = useRef<TpDialogToggle>(null);

  const handleConfirm = useCallback((): void => {
    if (kycRequired) {
      kycDialog.current?.toggle();
      return;
    }
    onConfirm();
  }, [kycRequired, onConfirm]);

  return (
    <AppContainer>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <AppMain>
        <AppHeaderData disableFade exitButton helpButton backTo={backTo}>
          <AppHeaderTitle>{pageTitle}</AppHeaderTitle>
        </AppHeaderData>
        <SceneMain dataQa="confirm" maxWidth="xs">
          <Balance PrimaryAmountSlot={TotalFiatAmountSlot} />
          <Stack mt={6} spacing={3}>
            <TransactionSummaryCard
              ActionAmount={NetCryptoAmountSlot}
              actionAmountLabel="Purchasing"
              cryptoCurrency={cryptoCurrency}
              CryptoPriceSlot={CryptoPriceSlot}
              FeeAmountSlot={FeeAmountSlot}
              feeLabel={`Fee (${feePercentage}%)`}
              feePromotion={!!feePromotion}
              FinalAmount={TotalFiatAmountSlot}
              finalAmountLabel="Total spend"
              processingTime="Instant"
              onFeeInfoClick={(): void => feeInfoDialog.current?.toggle()}
              onProcessingTimeClick={(): void => processingTimeDialog.current?.toggle()}
            />
            <ListSection title="Payment Method" titleVariant="paragraphBodySBold">
              <PaymentMethodsPicker
                applePayEnabled={applePayEnabled}
                googlePayEnabled={googlePayEnabled}
                paymentMethod={paymentMethod}
                selectedPaymentCard={selectedPaymentCard}
                onChange={onPaymentMethodChange}
                onSubmitCardDetailsRedirect={onSubmitCardDetailsRedirect}
              />
            </ListSection>
          </Stack>
          <CryptoVolatilityDialog ref={cryptoVolatilityDialog} />

          <ProcessingTimeDialog ref={processingTimeDialog} action="buying" />
          <CheckoutFeeDialog
            ref={feeInfoDialog}
            action="buying"
            content={getPromoContent(feePromotion?.PromoID)}
          />
          <VerifyAccountDialog ref={kycDialog} action="buying" />
        </SceneMain>
      </AppMain>
      <AppFooter solidFade>
        <Stack spacing={2}>
          <FooterContentText>
            Market conditions may affect the exact amount of bitcoin you receive. If the bitcoin
            value changes significantly, the order may be cancelled & refunded.{' '}
            <LearnMoreButton onClick={(): void => cryptoVolatilityDialog.current?.toggle()} />
          </FooterContentText>
          <PrimaryButton
            color="primaryBrand"
            data-qa="confirm-button"
            disabled={!paymentMethod}
            endIcon={<LockIcon />}
            loading={isLoading}
            onClick={handleConfirm}
          >
            {ctaButtonLabel}
          </PrimaryButton>
        </Stack>
      </AppFooter>
    </AppContainer>
  );
}
