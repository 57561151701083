import type { TpFiatCurrency } from '@noah-labs/shared-currencies';
import * as yup from 'yup';
import { maxSafetyAmount, minRampFiatAmount } from './amounts';
import type { TpAmountForm } from './types';

export function getCkoBuyAmountSchema(
  fiatCurrency: TpFiatCurrency | undefined,
  minimumFiatAmount: string,
): yup.ObjectSchema<Partial<TpAmountForm>> {
  return yup.object<Partial<TpAmountForm>>({
    fiatAmount: minRampFiatAmount({
      fiatCurrency,
      minFiatAmount: minimumFiatAmount,
      prefix: 'Minimum buy amount is',
    }).concat(maxSafetyAmount(fiatCurrency)),
    // this value may be null on the first load while the crypto price is being fetched
    secondaryAmount: yup.string().required(),
  });
}
