import { TpRewardsTabs } from './types';

const tabs = Object.keys(TpRewardsTabs).join('|').toLowerCase();

export const routes = {
  badge: `/rewards/${TpRewardsTabs.Badges}/:id`,
  base: '/rewards',
  inviteFriends: `/rewards/invite-friends`,
  quest: `/rewards/${TpRewardsTabs.Quests}/:id`,
  tabs: `/rewards/:tab(${tabs})`,
};
