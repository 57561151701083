import type { ReactElement, ReactNode } from 'react';
import { Fragment } from 'react';
import { RewardsIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/RewardsIcon';
import { WalletIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/WalletIcon';
import type { TpMuiSvgIcon } from '@noah-labs/fe-shared-ui-assets/types/muiSvgIcon';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Feature } from '@noah-labs/shared-schema-gql';
import { routes as rewardsRoutes } from '../../modules/rewards/routes';
import { RestrictedFeature } from '../../modules/user/components/RestrictedFeature';

type PpComponent = {
  Icon: TpMuiSvgIcon;
  label: ReactNode;
  to: string;
};

type PpNavItems = {
  Component: React.ComponentType<PpComponent>;
};
export function NavItems({ Component }: PpNavItems): ReactElement {
  return (
    <Fragment>
      <Component Icon={WalletIcon} label="Wallet" to={walletRoutes().base} />
      <RestrictedFeature needs={[Feature.UIRewards]}>
        <Component Icon={RewardsIcon} label="Rewards" to={rewardsRoutes.base} />
      </RestrictedFeature>
    </Fragment>
  );
}
