import { useCallback, useEffect, useState } from 'react';
import {
  getOryFlowId,
  getOryUiError,
  getOryVerificationCode,
  useOry,
  useOryResendCodeCallback,
  useOryVerificationFlow,
  useOryVerifyAccountCallback,
} from '@noah-labs/fe-shared-data-access-auth';
import { orySessionKey, useAuth } from '@noah-labs/fe-shared-feature-auth';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import type { ErrorOption } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { VerifyEmailEmailSentScene } from '../scenes/VerifyEmailEmailSent';
import { VerifyEmailEnterCodeScene } from '../scenes/VerifyEmailEnterCode';

type PpVerifyEmail = {
  helpButton?: React.ReactNode;
};

export function VerifyEmail({ helpButton }: PpVerifyEmail): React.ReactElement {
  const { data, isFetching } = useAuth();
  const { returnTo } = useOry();
  const queryClient = useQueryClient();
  const { replace } = useRouter();

  const [awaitVerificationStateUpdate, setAwaitVerificationStateUpdate] = useState(false);

  /**
   * onRefresh is called when the user clicks on 'continue' and rechecks if they are verified
   */
  const onRefresh = useCallback(async () => {
    await queryClient.invalidateQueries(orySessionKey);
  }, [queryClient]);

  const onVerify = useOryVerifyAccountCallback(() => {
    setAwaitVerificationStateUpdate(true);
  });

  const resendCodeCallback = useOryResendCodeCallback();

  const onResend = useCallback(
    async (email: string, setError: (name: `root.${string}`, error: ErrorOption) => void) => {
      const flowId = await resendCodeCallback(email, setError);
      if (flowId === undefined) {
        return;
      }
      replace({
        search: new URLSearchParams([['flow', flowId]]).toString(),
      });
    },
    [replace, resendCodeCallback],
  );

  const flow = useOryVerificationFlow();

  const flowId = getOryFlowId();
  const code = new URL(window.location.href).searchParams.get('code');
  const email = data?.email ?? '';

  useEffect(() => {
    if (isFetching || !awaitVerificationStateUpdate || data?.verified) {
      return;
    }
    void onRefresh();
  }, [isFetching, awaitVerificationStateUpdate, data?.verified, onRefresh]);

  useEffect(() => {
    if (!data?.verified) {
      return;
    }
    window.location.assign(returnTo);
  }, [data?.verified, returnTo]);

  useEffect(() => {
    const verificationCode = getOryVerificationCode(flow?.ui.nodes || []);
    if (!verificationCode) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    params.append('code', verificationCode);

    replace({
      search: params.toString(),
    });
  }, [flow?.ui.nodes, replace]);

  if (flowId) {
    return (
      <VerifyEmailEnterCodeScene
        code={code}
        email={email}
        error={getOryUiError(flow?.ui.messages)}
        helpButton={helpButton}
        loading={isFetching}
        onResend={onResend}
        onVerify={onVerify}
      />
    );
  }

  return (
    <VerifyEmailEmailSentScene
      email={email}
      helpButton={helpButton}
      loading={isFetching}
      onRefresh={onRefresh}
      onResend={onResend}
    />
  );
}
