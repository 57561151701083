import React from 'react';
import { TpFeExtraFeature } from '@noah-labs/fe-shared-data-access-user';
import {
  DepositNetworkController,
  LightningReceiveEnterAmountController,
  LightningReceiveViewInvoiceController,
  ReceiveController,
} from '@noah-labs/fe-shared-feature-wallet';
import type { SmDeposit } from '@noah-labs/fe-shared-feature-wallet';
import {
  AppContainer,
  AppHeaderTitle,
  generatePath,
  Switch404,
  useStateMachine,
} from '@noah-labs/fe-shared-ui-components';
import {
  cryptoCurrencyFromCode,
  getDefaultCryptoNetwork,
  TpAuthStatus,
  useRouter,
  useWalletParams,
} from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Feature } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { AppHeaderData } from '../../../../components/layout/AppHeaderData';
import { AccessControlData } from '../../../auth/AccessControlData';

const emptyState: SmDeposit = {
  fiatAmount: '',
  network: undefined,
  paymentRequest: '',
  publicID: '',
};

export function ReceiveRouter(): React.ReactElement {
  const { AccountType, CurrencyCode, params } = useWalletParams();
  const { push } = useRouter();

  const sm = useStateMachine<SmDeposit>({
    emptyState,
    name: 'Deposit',
  });

  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);
  const viewInvoiceInvalid = !sm.state.paymentRequest;
  const network = sm.state.network || getDefaultCryptoNetwork(CurrencyCode);

  return (
    <Switch404>
      <Route exact path={walletRoutes().receive.network}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[TpFeExtraFeature.DepositRoutes]}
        >
          <AppContainer headTitle="Select network">
            <AppHeaderData backButton exitButton helpButton />
            <DepositNetworkController
              {...sm}
              cryptoCurrency={cryptoCurrency}
              onNext={(): void => push(generatePath(walletRoutes().receive.base, params))}
            />
          </AppContainer>
        </AccessControlData>
      </Route>

      <Route exact path={walletRoutes().receive.base}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <AppContainer headTitle={`Receive ${cryptoCurrency.label}`}>
            <AppHeaderData backButton disableFade helpButton>
              <AppHeaderTitle>Receive</AppHeaderTitle>
            </AppHeaderData>
            <ReceiveController
              AccountType={AccountType}
              cryptoCurrency={cryptoCurrency}
              lightningInvoiceHref={generatePath(walletRoutes().receive.lightning.enterAmount, {
                accountType: AccountType,
                currencyCode: cryptoCurrency.code,
              })}
              network={network}
            />
          </AppContainer>
        </AccessControlData>
      </Route>

      <Route exact path={walletRoutes().receive.lightning.enterAmount}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.LnInvoice]}
        >
          <AppContainer headTitle="Enter Request Amount">
            <AppHeaderData backButton exitButton helpButton>
              <AppHeaderTitle>Enter Request Amount</AppHeaderTitle>
            </AppHeaderData>
            <LightningReceiveEnterAmountController {...sm} />
          </AppContainer>
        </AccessControlData>
      </Route>

      <Route exact path={walletRoutes().receive.lightning.viewInvoice}>
        <AccessControlData
          // redirect to the beginning of the flow if the payment request has not yet been generated
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.LnInvoice]}
          redirectInvalid={
            viewInvoiceInvalid && generatePath(walletRoutes().receive.lightning.enterAmount, params)
          }
        >
          <AppContainer headTitle="Invoice">
            <AppHeaderData exitButton helpButton>
              <AppHeaderTitle>Invoice</AppHeaderTitle>
            </AppHeaderData>
            <LightningReceiveViewInvoiceController {...sm} />
          </AppContainer>
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
