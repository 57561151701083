import React, { useEffect } from 'react';
import { generatePath, Switch404, useStateMachine } from '@noah-labs/fe-shared-ui-components';
import type { TpLightningAddressData } from '@noah-labs/fe-shared-ui-components/crypto';
import { TpAuthStatus, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { CurrencyUnit, Feature, SardineFlows } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { AccessControlData } from '../../../auth/AccessControlData';
import { KycAccessControlData } from '../../../kyc/controllers/KycAccessControlData';
import { KycReminderOrigin } from '../../../kyc/controllers/Reminder';
import { useSardineFlow } from '../../../sardine/hooks/useSardineFlow';
import { useAddress } from '../../hooks/useAddress';
import { useSendAmounts } from '../../hooks/useSendAmounts';
import { ExpiredInvoiceScene } from '../../scenes/ExpiredInvoice';
import { Complete } from './Complete';
import { Confirm } from './Confirm';
import { EnterAmount } from './EnterAmount';
import { ExternalRequest } from './ExternalRequest';
import type { StLightningSendRouter } from './types';

const emptyState: StLightningSendRouter = {
  cryptoAmount: '',
  cryptoUnit: CurrencyUnit.Default,
  fetchedAt: '',
  fiatAmount: '',
  lightningAddressProxy: undefined,
  paymentRequest: undefined,
  paymentRequestData: undefined,
  price: '',
};
export function LightningSendRouter(): React.ReactElement {
  useSardineFlow({ flow: SardineFlows.CryptoWithdraw });
  const { data, setData } = useAddress();

  /**
   * Remove payment request from the address context on unmount
   */
  useEffect(() => () => setData(undefined), [setData]);

  let initialState: StLightningSendRouter | undefined;
  if (data) {
    initialState = {
      ...emptyState,
      cryptoAmount: data.amount || '',
      lightningAddressProxy: data.lightningAddressProxy,
      // can use 'as' here because we know it will only be routed here if parsedAddressData is TpLightningAddressData
      paymentRequestData: data as TpLightningAddressData,
    };

    // If an invoice is entered directly, set it as the paymentRequest
    if (data.addressType === 'lnbc') {
      initialState.paymentRequest = data.address;
    }
  }

  const sm = useStateMachine<StLightningSendRouter>({
    emptyState,
    initialState,
    name: 'LightningSendRouter',
  });

  /**
   * Get params and fetch the fiatAmount for the cryptoAmount in the paymentRequestData,
   * so that we can display it on the confirm & complete screeens.
   * Always fetch a new fiatAmount in case the stored value is out of date
   */
  const { params } = useWalletParams();

  const { cryptoUnit, fetchedAt, fiatAmount, price } = useSendAmounts({
    cryptoAmount: sm.state.cryptoAmount,
    fiatAmount: sm.state.fiatAmount,
  });

  const { updateState } = sm;
  useEffect(() => {
    updateState({ cryptoUnit, fetchedAt, fiatAmount, price });
  }, [cryptoUnit, fetchedAt, fiatAmount, price, updateState]);

  /**
   * Setup invalid screen redirects, we always need a cryptoAmount and a paymentRequest for lightning send
   */

  // Go back to the scan screen in case we don't have the paymentRequestData address
  const enterAmountInvalidRedirect =
    !sm.state.paymentRequestData?.address && generatePath(walletRoutes().address.text, params);

  // Go back to enterAmount screen in case we don't have an amount on the confirm screen
  const confirmAndCompleteInvalidRedirect =
    !sm.state.cryptoAmount && generatePath(walletRoutes().lightningSend.enterAmount, params);

  return (
    <Switch404>
      <Route exact path={walletRoutes().lightningSend.enterAmount}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.LnPayment]}
          redirectInvalid={enterAmountInvalidRedirect}
        >
          <KycAccessControlData bypassIfUserHasBalance origin={KycReminderOrigin.Withdraw}>
            <EnterAmount {...sm} />
          </KycAccessControlData>
        </AccessControlData>
      </Route>

      <Route exact path={walletRoutes().lightningSend.confirm}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.LnPayment]}
          redirectInvalid={confirmAndCompleteInvalidRedirect}
        >
          <KycAccessControlData bypassIfUserHasBalance origin={KycReminderOrigin.Withdraw}>
            <Confirm {...sm} />
          </KycAccessControlData>
        </AccessControlData>
      </Route>

      <Route exact path={walletRoutes().lightningSend.complete}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.LnPayment]}
          redirectInvalid={confirmAndCompleteInvalidRedirect}
        >
          <Complete {...sm} />
        </AccessControlData>
      </Route>

      <Route exact path={walletRoutes().lightningSend.invoiceExpired}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.LnPayment]}
          redirectInvalid={enterAmountInvalidRedirect}
        >
          <ExpiredInvoiceScene />
        </AccessControlData>
      </Route>

      <Route exact path={walletRoutes().lightningSend.request}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.LnPayment]}
        >
          <ExternalRequest {...sm} />
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
