import { useTheme } from '@mui/material/styles';
import { CircleTickIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CircleTickIcon';
import { CloseCircleIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CloseCircleIcon';
import { KycApprovalStatus } from '@noah-labs/shared-schema-gql';

export type TpKycApprovalStatusData = {
  Icon?: React.ReactElement;
  color?: string;
  description: string;
};

export function useKycApprovalStatusData(
  status: KycApprovalStatus | null | undefined,
): TpKycApprovalStatusData {
  const theme = useTheme();

  const kycResponse = {
    [KycApprovalStatus.Pending]: {
      description: 'Pending',
    },
    [KycApprovalStatus.Approved]: {
      color: theme.palette.success.main,
      description: 'Account verified',
      Icon: <CircleTickIcon color="success" />,
    },
    [KycApprovalStatus.Declined]: {
      color: theme.palette.error.main,
      description: 'Verification failed',
      Icon: <CloseCircleIcon color="error" />,
    },
    default: {
      description: 'Not yet verified',
    },
  };

  if (!status) {
    return kycResponse.default;
  }

  return kycResponse[status];
}
