import { Switch404 } from '@noah-labs/fe-shared-ui-components';
import { TpAuthStatus } from '@noah-labs/fe-shared-ui-shared';
import { Route } from 'react-router-dom';
import { AccessControlData } from '../auth/AccessControlData';
import { Confirm } from './controllers/Confirm';
import { Confirmed } from './controllers/Confirmed';
import { routes } from './routes';

export function Router(): React.ReactElement {
  return (
    <Switch404>
      <Route exact path={routes.confirm}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <Confirm />
        </AccessControlData>
      </Route>
      <Route exact path={routes.confirmed}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <Confirmed />
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
