import { getAllowedBankCurrencies } from '@noah-labs/fe-shared-data-access-wallet';
import { TpPaymentMethod } from '@noah-labs/fe-shared-ui-shared';
import type { CountryCode, FullName } from '@noah-labs/shared-schema-gql';
import { BankingSystem, TransferDestinationType } from '@noah-labs/shared-schema-gql';

const US_COUNTRY_CODE = 'US';
const USD_CURRENCY_CODE = 'USD';

export function IsCountryAllowed(countryCode: string, currencies: string[]): boolean {
  const allowedBankCurrencies = getAllowedBankCurrencies();
  const isAllowed = currencies.some((c) => allowedBankCurrencies[c]);

  if (!isAllowed) {
    return false;
  }

  if (currencies.includes(USD_CURRENCY_CODE) && countryCode !== US_COUNTRY_CODE) {
    return false;
  }

  return true;
}

export function getAccountHolderName(fullName: string): FullName | undefined {
  if (!fullName) {
    return undefined;
  }

  const [firstName, ...otherNames] = fullName.trim().split(/\s+/);
  const lastName = otherNames.pop() || '';

  const middleName = otherNames.join(' ');

  return {
    FirstName: firstName,
    LastName: lastName,
    MiddleName: middleName,
  };
}

export function getPayoutTansferDestinationType(pm?: TpPaymentMethod): TransferDestinationType {
  switch (pm) {
    case TpPaymentMethod.BankTransfer:
      return TransferDestinationType.ManualRamp;
    default:
      return TransferDestinationType.Checkout;
  }
}

export function getBankingSystem(country: CountryCode | undefined): BankingSystem {
  switch (country) {
    case 'GB':
      return BankingSystem.SortCode;
    default:
      return BankingSystem.Swift;
  }
}
