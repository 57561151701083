import { useSigning } from '@noah-labs/fe-shared-feature-signing';
import { userRoutes } from '@noah-labs/fe-shared-util-routes';
import { useBackHijack } from '../../../hooks/useBackHijack';
import { SecurityPinScene } from '../scenes/SecurityPin';

export function SecurityPin(): React.ReactElement {
  const { backTo } = useBackHijack(userRoutes.settings.base);
  const { sdStatus } = useSigning();

  return (
    <SecurityPinScene backTo={backTo} pinSetupRequired={Boolean(sdStatus?.pinSetupRequired)} />
  );
}
