import { Switch404 } from '@noah-labs/fe-shared-ui-components';
import { TpAuthStatus } from '@noah-labs/fe-shared-ui-shared';
import { userRoutes, walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Feature } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { AccessControlData } from '../auth/AccessControlData';
import { RevokePin } from './controllers/RevokePin';
import { UnrevokePin } from './controllers/UnrevokePin';

export function PinRevocationRouter(): React.ReactElement {
  return (
    <Switch404>
      {/* 
       Authenticated route to revoke/unrevoke the PIN.
       */}
      <Route path={userRoutes.pinRevocation.revoke}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Pin]}
        >
          <RevokePin successTo={walletRoutes().base} />
        </AccessControlData>
      </Route>
      {/* 
       Authenicated or unauthenticated route to unrevoke the PIN.
       */}
      <Route path={userRoutes.pinRevocation.unrevoke}>
        <UnrevokePin successTo={walletRoutes().base} />
      </Route>
    </Switch404>
  );
}
