import type { ReactElement } from 'react';
import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  AppContainer,
  AppFooter,
  AppHeaderTitle,
  AppMain,
  FormatInputField,
  List,
  ListItem,
  ListItemCard,
  PrimaryButton,
  RadioButton,
  RadioGroup,
  SceneHeader,
  SceneMain,
  SceneParagraph,
} from '@noah-labs/fe-shared-ui-components';
import { FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';

const StyledFormatInputField = styled(FormatInputField)(({ theme }) => ({
  padding: theme.spacing(1.5, 0),
  width: '100%',
}));

const StyledInputContainer = styled('li')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  width: '100%',
}));

export type PpTransactionPinThresholdScene = {
  customLimitInputsAreDisabled?: boolean;
  onSubmit: () => void;
};

export function TransactionThresholdScene({
  customLimitInputsAreDisabled,
  onSubmit,
}: PpTransactionPinThresholdScene): ReactElement {
  return (
    <AppContainer>
      <Helmet>
        <title>Transaction PIN Threshold</title>
      </Helmet>
      <AppMain>
        <AppHeaderData backButton helpButton>
          <AppHeaderTitle>Transaction PIN Threshold</AppHeaderTitle>
        </AppHeaderData>
        <SceneHeader>
          <SceneParagraph>Set the transaction amount limit for PIN protection.</SceneParagraph>
        </SceneHeader>
        <SceneMain dataQa="user-transaction-pin-threshold">
          <RadioGroup
            label="Set the transaction amount limit for PIN protection."
            name="transactionPinThreshold"
          >
            <List disablePadding spacing={1}>
              <ListItem>
                <ListItemCard>
                  <RadioButton
                    label="Always Require PIN"
                    labelPlacement="start"
                    value="You'll be asked to verify all transactions"
                  />
                </ListItemCard>
              </ListItem>

              <ListItem disablePadding>
                <ListItemCard>
                  <List disablePadding>
                    <ListItem>
                      <RadioButton
                        disablePadding={false}
                        label="Set your own PIN threshold"
                        labelPlacement="start"
                        value="You decide when we ask for your PIN"
                      />
                    </ListItem>

                    <StyledInputContainer>
                      <Divider />

                      <StyledFormatInputField
                        InputFieldAtomProps={{
                          disabled: customLimitInputsAreDisabled,
                          endSlot: FiatCurrencyCode.EUR,
                          label: 'Max daily limit',
                          name: 'dailyLimit',
                          placeholder: 'Amount',
                        }}
                      />
                    </StyledInputContainer>

                    <StyledInputContainer>
                      <StyledFormatInputField
                        InputFieldAtomProps={{
                          disabled: customLimitInputsAreDisabled,
                          endSlot: FiatCurrencyCode.EUR,
                          label: 'Single Transaction Limit',
                          name: 'singleTransactionLimit',
                          placeholder: 'Amount',
                          sx: { paddingTop: (theme) => theme.spacing(0.5) },
                        }}
                      />
                    </StyledInputContainer>
                  </List>
                </ListItemCard>
              </ListItem>
            </List>
          </RadioGroup>
        </SceneMain>
      </AppMain>
      <AppFooter>
        <PrimaryButton color="primaryBrand" data-qa="save-btn" onClick={onSubmit}>
          Save
        </PrimaryButton>
      </AppFooter>
    </AppContainer>
  );
}
