import { z } from 'zod';
import { billingAddressSchema } from './billingAddress';

const baseBankFormSchema = z.object({
  accountCurrency: z.string().min(1, 'Account currency is required'),
  accountCurrencyName: z.string().min(1, 'Account currency is required'),
  accountHolderName: z.string().min(1, 'Account holder name is required'),
  billingAddress: billingAddressSchema,
  saveAccount: z.boolean(),
});

const swiftRegex = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;

export const defaultBankFormSchema = z
  .object({
    accountNumber: z.string().min(1, 'IBAN is required'),
    bankCode: z.string().regex(swiftRegex, 'Invalid SWIFT/BIC'),
  })
  .merge(baseBankFormSchema);

export const USBankFormSchema = z
  .object({
    accountNumber: z.string().min(1, 'Account number is required'),
    bankCode: z.string().min(1, 'Wire routing number is required'),
  })
  .merge(baseBankFormSchema);

export const SortCodeFormSchema = z
  .object({
    accountNumber: z.string().regex(/^\d{8}$/, 'Invalid account number'),
    bankCode: z.string().regex(/^\d{6}$/, 'Invalid sort code'),
  })
  .merge(baseBankFormSchema);
