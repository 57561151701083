import { getDefaultAccount } from '@noah-labs/fe-shared-data-access-wallet';
import { ErrorPage, generatePath, PrimaryButton } from '@noah-labs/fe-shared-ui-components';
import { useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';

export function ExpiredInvoiceScene(): React.ReactElement {
  const da = getDefaultAccount();
  const { params } = useWalletParams({
    accountType: da.AccountType,
    currencyCode: da.CurrencyCode,
  });
  return (
    <ErrorPage
      AppHeaderSlot={<AppHeaderData backButton exitButton helpButton />}
      FooterSlot={
        <PrimaryButton
          data-qa="done-button"
          href={generatePath(walletRoutes().address.text, params)}
        >
          Start over
        </PrimaryButton>
      }
      helpText="Ask the sender to create a new one and try again."
      message="This invoice has expired"
      pageTitle="Invoice Expired"
    />
  );
}
