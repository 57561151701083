import { getDefaultAccount } from '@noah-labs/fe-shared-data-access-wallet';
import { TpAnalyticsEvent, useAnalytics } from '@noah-labs/fe-shared-feature-analytics';
import { MinusIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/MinusIcon';
import { PlusIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/PlusIcon';
import { SendIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/SendIcon';
import { WalletReceiveIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/WalletReceiveIcon';
import {
  ActionsMenu,
  ActionsMenuItem,
  generatePath,
  useNavigation,
} from '@noah-labs/fe-shared-ui-components';
import { useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { useLocation } from 'react-router-dom';

type TpActionsMenuData = {
  customRoutes?: {
    receive: string;
    send: string;
  };
};

export function ActionsMenuData({ customRoutes }: TpActionsMenuData): React.ReactElement {
  const da = getDefaultAccount();
  const { params } = useWalletParams({
    accountType: da.AccountType,
    currencyCode: da.CurrencyCode,
  });

  const { pathname } = useLocation();
  const { updateExitTo } = useNavigation();
  const { track } = useAnalytics();

  return (
    <ActionsMenu>
      <ActionsMenuItem
        // @ts-expect-error false positive
        href={{
          pathname: generatePath(walletRoutes().buy.base, params),
          state: { from: pathname },
        }}
        Icon={PlusIcon}
        label="Buy"
        onClick={(): void => {
          updateExitTo(pathname);
          track(TpAnalyticsEvent.BuyClicked);
        }}
      />

      <ActionsMenuItem
        href={generatePath(walletRoutes().sell.base, params)}
        Icon={MinusIcon}
        label="Sell"
        onClick={(): void => {
          updateExitTo(pathname);
          track(TpAnalyticsEvent.SellClicked);
        }}
      />

      <ActionsMenuItem
        href={generatePath(customRoutes?.receive || walletRoutes().selectCrypto.receive)}
        Icon={WalletReceiveIcon}
        label="Receive"
        onClick={(): void => {
          updateExitTo(pathname);
          track(TpAnalyticsEvent.ReceiveClicked);
        }}
      />

      <ActionsMenuItem
        // @ts-expect-error false positive
        href={{
          pathname: generatePath(customRoutes?.send || walletRoutes().selectCrypto.send),
          state: { from: pathname },
        }}
        Icon={SendIcon}
        label="Send"
        onClick={(): void => {
          updateExitTo(pathname);
          track(TpAnalyticsEvent.SendClicked);
        }}
      />
    </ActionsMenu>
  );
}
