import { isUndefinedOrNull } from '@noah-labs/shared-util-vanilla';
import { webConfigBrowser } from '../webConfigBrowser';

export function getReferralUrl(referralCode: string | undefined): string | undefined {
  if (isUndefinedOrNull(referralCode)) {
    return undefined;
  }
  const referralUrl = new URL(webConfigBrowser.url);
  referralUrl.searchParams.set(webConfigBrowser.settings.referralCodeParam, referralCode);
  return referralUrl.href;
}
