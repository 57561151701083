import type { TpAllowance } from '@noah-labs/fe-shared-data-access-user';
import type { TpFiatCurrency } from '@noah-labs/shared-currencies';
import * as yup from 'yup';
import { maxSafetyAmount, minRampFiatAmount } from './amounts';
import { getCryptoWithdrawalLimitsSchema } from './getCryptoWithdrawalLimitsSchema';
import type { TpAmountForm } from './types';

type TpGetCkoSellAmountSchema = {
  allowance: TpAllowance | undefined;
  feePercentage: string;
  fiatCurrency: TpFiatCurrency | undefined;
  minFiatCurrencyAmount: string;
};
export function getCkoSellAmountSchema({
  allowance,
  feePercentage,
  fiatCurrency,
  minFiatCurrencyAmount,
}: TpGetCkoSellAmountSchema): yup.ObjectSchema<Partial<TpAmountForm>> {
  return yup.object<Partial<TpAmountForm>>({
    cryptoAmount: getCryptoWithdrawalLimitsSchema(allowance, {
      buffer: feePercentage,
      bufferInfo: '(fees included)',
    }),
    fiatAmount: minRampFiatAmount({
      fiatCurrency,
      minFiatAmount: minFiatCurrencyAmount,
      prefix: 'Minimum sell amount is',
    }).concat(maxSafetyAmount(fiatCurrency)),
    // this value may be null on the first load while the crypto price is being fetched
    secondaryAmount: yup.string(),
  });
}
