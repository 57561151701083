import type { ReactElement } from 'react';
import { Fragment, useCallback, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { Button, CardActionArea, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { EnlargeIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/EnlargeIcon';
import { InfoIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/InfoIcon';
import { ShareIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ShareIcon';
import {
  AppLogo,
  ElevatedCard,
  List,
  ListItem,
  ListItemContent,
  ListItemEndContent,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
  QRCodeWithImage,
  TextOrSkeleton,
  TruncatedText,
  useShare,
} from '@noah-labs/fe-shared-ui-components';
import type {
  TpCryptoCurrencyUI,
  TpCryptoNetworkUi,
  TpDialogToggle,
} from '@noah-labs/fe-shared-ui-shared';
import { assetPaths } from '@noah-labs/shared-constants';
import { OnChainQRCodeDialog } from '../dialogs/OnChainQRCodeDialog';
import { ReceiveLearnMoreDialog } from '../dialogs/ReceiveLearnMoreDialog';
import { QRCodeArea } from '../qr/QRCodeArea';

type PpReceiveOnChainChard = {
  OnChainCopyIcon: ReactElement;
  address?: string;
  cryptoCurrency: TpCryptoCurrencyUI;
  network: TpCryptoNetworkUi;
  onChainCopyHandler: () => void;
};

export function OnChainReceiveCard({
  address,
  cryptoCurrency,
  network,
  onChainCopyHandler,
  OnChainCopyIcon,
}: PpReceiveOnChainChard): ReactElement {
  const theme = useTheme();

  const [showQRDialog, setShowQRDialog] = useState(false);

  const showDialogRef = useRef<TpDialogToggle>(null);

  const handleOnChainDialogToggle = useCallback(() => {
    showDialogRef.current?.toggle();
  }, []);

  const { handleSharing, isSupported } = useShare({ text: address });

  const styles = {
    card: css`
      padding: ${theme.spacing(2)};
      border-radius: ${theme.borderRadius.lg};
    `,
    enlarge: css`
      margin: ${theme.spacing(4, 0, 2, -0.5)};
    `,
    qrCodeArea: css`
      width: 100%;
      height: 100%;
      gap: ${theme.spacing(1.5)};
      padding: ${theme.spacing(1.5, 1.5, 1.5, 2.5)};
    `,
  };

  return (
    <Fragment>
      <ElevatedCard css={styles.card} elevation={3}>
        <List disablePadding spacing={2}>
          <ListItem>
            <Button
              color="ghost"
              endIcon={<InfoIcon />}
              size="xs"
              variant="contained"
              onClick={handleOnChainDialogToggle}
            >
              {network.label} network
            </Button>
          </ListItem>
          <ListItem>
            <ListItemContent padding={0}>
              <ListItemStartContent>
                <ListItemSecondaryText gutterBottom>
                  Your {network.label} address
                </ListItemSecondaryText>
                <ListItemPrimaryText>
                  <TextOrSkeleton>
                    {address && <TruncatedText prefix={12} text={address} />}
                  </TextOrSkeleton>
                </ListItemPrimaryText>
              </ListItemStartContent>

              <ListItemEndContent>
                <Fragment>
                  <IconButton
                    color="primary"
                    data-qa="copy-button"
                    data-qa-address={address}
                    disabled={!address}
                    onClick={onChainCopyHandler}
                  >
                    {OnChainCopyIcon}
                  </IconButton>
                  {isSupported && (
                    <IconButton color="primary" onClick={handleSharing}>
                      <ShareIcon />
                    </IconButton>
                  )}
                </Fragment>
              </ListItemEndContent>
            </ListItemContent>
          </ListItem>
          <QRCodeArea network={network}>
            <CardActionArea
              css={styles.qrCodeArea}
              data-qa="open-qrcode-dialog"
              disabled={!address}
              onClick={(): void => setShowQRDialog(true)}
            >
              <Stack alignItems="stretch" direction="row" justifyContent="space-between">
                <Stack justifyContent="flex-end">
                  <AppLogo color={theme.palette.grayscale[100]} />
                  <Typography variant="paragraphBodyS">{cryptoCurrency.label}</Typography>
                  <Stack alignItems="center" css={styles.enlarge} direction="row">
                    <EnlargeIcon />
                    <Typography component="span" variant="paragraphBodyS">
                      Enlarge QR
                    </Typography>
                  </Stack>
                </Stack>
                <QRCodeWithImage
                  data={address}
                  height={600}
                  imageUrl={`${assetPaths.images}/logo-icon-black.svg`}
                  width={600}
                  wrapperSize={150}
                />
              </Stack>
            </CardActionArea>
          </QRCodeArea>
        </List>
      </ElevatedCard>

      <ReceiveLearnMoreDialog
        ref={showDialogRef}
        cryptoCurrency={cryptoCurrency.code}
        network={network}
      />

      <OnChainQRCodeDialog
        address={address}
        cryptoCurrency={cryptoCurrency}
        network={network}
        open={showQRDialog}
        onClose={(): void => setShowQRDialog(false)}
      />
    </Fragment>
  );
}
