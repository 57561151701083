import { ErrorPage, PrimaryButton } from '@noah-labs/fe-shared-ui-components';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';

export type PpFailedScene = {
  backTo: string;
  errorHelpText: string;
  errorMessage?: string;
};

export function FailedScene({
  backTo,
  errorHelpText,
  errorMessage,
}: PpFailedScene): React.ReactElement {
  return (
    <ErrorPage
      AppHeaderSlot={<AppHeaderData exitButton helpButton />}
      FooterSlot={<PrimaryButton href={backTo}>Close</PrimaryButton>}
      helpText={errorHelpText}
      message={errorMessage}
    />
  );
}
