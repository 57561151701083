/* eslint-disable react/iframe-missing-sandbox */
import { Fragment, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { BitrefillService } from '@noah-labs/core-services';
import type { TpUseWithdrawFeeWithTransaction } from '@noah-labs/fe-shared-data-access-wallet';
import {
  useCalculateFiatFromCrypto,
  useLnPaymentSendMutation,
  useWithdrawFeeWithTransaction,
  useWithdrawOrderCreateMutation,
} from '@noah-labs/fe-shared-data-access-wallet';
import type { TpOnSign } from '@noah-labs/fe-shared-feature-signing';
import { useSignWithdrawal } from '@noah-labs/fe-shared-feature-signing';
import { useUserWithdrawalAllowance } from '@noah-labs/fe-shared-feature-user';
import type { PpOnSubmitWithdrawOrder } from '@noah-labs/fe-shared-feature-wallet';
import { getFeeQuoteExpiresIn, useWithdrawNewSignature } from '@noah-labs/fe-shared-feature-wallet';
import { getWithdrawFeeAmounts } from '@noah-labs/fe-shared-feature-wallet/src/utils/getWithdrawFeeAmounts';
import { LoadingPage, usePushAlert, useSearchParams } from '@noah-labs/fe-shared-ui-components';
import type { TpAddressData } from '@noah-labs/fe-shared-ui-components/crypto';
import { parseAddressData } from '@noah-labs/fe-shared-ui-components/crypto';
import { cryptoCurrencyFromCode, getAppType } from '@noah-labs/fe-shared-ui-shared';
import type { TpDialogToggle, TpFiatCurrencyUI } from '@noah-labs/fe-shared-ui-shared';
import { getCurrencyAmountSlots, useWalletError } from '@noah-labs/fe-shared-ui-wallet';
import { WithdrawNetworkFeeDialog } from '@noah-labs/fe-shared-ui-wallet/src/dialogs/WithdrawNetworkFeeDialog';
import { getEnvNetwork } from '@noah-labs/shared-currencies';
import { logger } from '@noah-labs/shared-logger/browser';
import type { CurrencyUnit } from '@noah-labs/shared-schema-gql';
import {
  CurrencyCode,
  CurrencyDisplayType,
  Network,
  TransferDestinationType,
  TransferDestinationTypeInput,
} from '@noah-labs/shared-schema-gql';
import { safeBN } from '@noah-labs/shared-util-vanilla';
import { getDefaults } from '../../../../utils/getDefaults';
import { isProd } from '../../../../webConfigBrowser';
import {
  InsufficientBalance,
  LightningLimitReached,
  ParsingLnPaymentError,
  SomethingWentWrong,
  UnsupportedCurrency,
} from '../Alerts';
import { Complete } from './Complete';
import { Confirm } from './Confirm';

const defaults = getDefaults();

type PpBitrefill = {
  cryptoUnit: CurrencyUnit | undefined;
  email: string | undefined;
  fiatCurrency: TpFiatCurrencyUI | undefined;
};

export function Bitrefill({ cryptoUnit, email, fiatCurrency }: PpBitrefill): React.ReactElement {
  const [isLoading, setIsLoading] = useState(true);
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const searchParams = useSearchParams();
  const [addressData, setAddressData] = useState<TpAddressData | null>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [service, resetService] = useReducer(() => new BitrefillService(), new BitrefillService());
  const url = useMemo(() => {
    if (!email) {
      return undefined;
    }

    const appType = getAppType();

    return service.iframeUrl(email, appType);
  }, [email, service]);

  const cryptoCurrency = cryptoCurrencyFromCode(defaults.cryptoCurrency.code);
  const allowance = useUserWithdrawalAllowance({
    accountType: defaults.accountType,
    cryptoCurrency,
    network: getEnvNetwork(Network.Bitcoin, isProd),
  });

  const { data: priceData, isLoading: isPriceLoading } = useCalculateFiatFromCrypto({
    cryptoAmount: service.paymentInfo?.amount,
    cryptoCurrency,
    fiatCurrency,
    priceProvider: 'market',
  });
  const [hasDismissed, dismiss] = useReducer(() => true, false);
  const pushAlert = usePushAlert();
  const [iframeKey, setIframeKey] = useState('bitrefill');

  const styles = {
    hideIframe: css`
      visibility: hidden;
    `,
    iframe: css`
      border: 0;
      flex-grow: 1;
    `,
  };

  const {
    error: lnPaymentSendError,
    isLoading: lnPaymentSendIsLoading,
    mutateAsync: lnPaymentSend,
    status: lnPaymentSendStatus,
  } = useLnPaymentSendMutation();

  const {
    error: btcPaymentError,
    isLoading: btcPaymentLoading,
    mutateAsync: btcPaymentSend,
    status: btcPaymentSendStatus,
  } = useWithdrawOrderCreateMutation();

  const forceBtc = searchParams?.has('btc');

  const invoiceAmount = useMemo(
    () => safeBN(service.paymentInfo?.amount),
    [service.paymentInfo?.amount],
  );

  const isEligibleForLn =
    allowance?.lnAllowanceCrypto && allowance.lnAllowanceCrypto.amount.gt(invoiceAmount);

  const payWithLightning = !forceBtc && isEligibleForLn;

  const currentFeeDataRef = useRef<TpUseWithdrawFeeWithTransaction>();
  const feeIncreasedDialogRef = useRef<TpDialogToggle>(null);

  const onSubmit = useCallback(
    async ({ cryptoAmount, feeQuote, signature }: PpOnSubmitWithdrawOrder) => {
      if (!service.paymentInfo || !priceData || !fiatCurrency) {
        return;
      }

      try {
        await btcPaymentSend({
          Input: {
            AccountType: defaults.accountType,
            Amount: cryptoAmount,
            CurrencyCode: defaults.cryptoCurrency.code,
            Destination: {
              DestinationAddress: {
                Address: service.paymentInfo.address,
              },
              DestinationType: TransferDestinationType.Bitrefill,
              ID: service.paymentInfo.invoiceId,
            },
            FeeQuote: feeQuote,
            Network: getEnvNetwork(Network.Bitcoin, isProd),
            RequestedAmount: {
              Amount: priceData.fiatAmount,
              FetchedAt: priceData.fetchedAt,
              FiatCurrency: fiatCurrency.code,
              Price: priceData.price,
            },
            ...(signature && { Nonce: signature.nonce, Signature: signature.signature }),
          },
        });
      } catch (error) {
        logger.error(error);
      }
    },
    [btcPaymentSend, fiatCurrency, priceData, service.paymentInfo],
  );

  const { isSubmitWithNewSignatureLoading, onSubmitWithdrawOrder, onSubmitWithNewSignature } =
    useWithdrawNewSignature({
      AccountType: defaults.accountType,
      address: service.paymentInfo?.address || '',
      cryptoCurrency,
      currentFeeDataRef,
      onSubmit,
      toggleFeeIncreasedDialog: () => feeIncreasedDialogRef.current?.toggle(),
    });

  const enableWithdrawFeeQuery =
    !isSubmitWithNewSignatureLoading &&
    !btcPaymentLoading &&
    !payWithLightning &&
    !!service.paymentInfo?.address;

  const feeData = useWithdrawFeeWithTransaction({
    address: service.paymentInfo?.address || '',
    cryptoAmount: service.paymentInfo?.amount || '',
    cryptoCurrency,
    enabled: enableWithdrawFeeQuery,
    fiatCurrency,
    network: getEnvNetwork(Network.Bitcoin, isProd),
  });
  currentFeeDataRef.current = feeData;

  const totalAmounts = getWithdrawFeeAmounts({
    cryptoAmount: service.paymentInfo?.amount || '',
    feeCryptoAmount: feeData.feeCryptoAmount,
    feeFiatAmount: feeData.feeFiatAmount,
    fiatAmount: priceData?.fiatAmount || '',
  });

  const FeeAmounts = getCurrencyAmountSlots({
    cryptoAmount: feeData.feeCryptoAmount,
    cryptoCurrency,
    cryptoUnit,
    fiatAmount: feeData.feeCryptoAmount ? feeData.feeFiatAmount : undefined,
    fiatCurrency,
    primaryCurrency: CurrencyDisplayType.Crypto,
    roundDown: true,
  });

  const TotalAmounts = getCurrencyAmountSlots({
    cryptoAmount: totalAmounts.cryptoAmountWithFee,
    cryptoCurrency,
    cryptoUnit,
    fiatAmount: totalAmounts.fiatAmountWithFee,
    fiatCurrency,
    primaryCurrency: CurrencyDisplayType.Crypto,
    roundDown: true,
  });

  useEffect(() => {
    if (!service.unsupportedCurrency) {
      return;
    }

    pushAlert(UnsupportedCurrency);
  }, [pushAlert, service.unsupportedCurrency]);

  useEffect(() => {
    setShowConfirmDialog(service.awaitingPayment === true);
  }, [service.awaitingPayment]);

  const showCompleteDialog =
    !hasDismissed &&
    (lnPaymentSendStatus === 'success' || btcPaymentSendStatus === 'success') &&
    service.awaitingPayment === false;

  /**
   * Resets the payment flow and refreshes the iframe
   * Basket is persisted by cookies
   */
  const cancelPayment = useCallback(() => {
    setShowConfirmDialog(false);
    resetService();
    setIsLoading(true);
    // setting a key for the iframe and changing actually refreshes the content within it
    setIframeKey(`bitrefill-${Math.random() * 100}`);
  }, []);

  /**
   * Submit payment for the LN invoice if possible
   * Otherwise, try to submit a BTC payment
   */
  const submitPayment = useCallback(
    async ({ pin, signature }: TpOnSign) => {
      if (!priceData || !fiatCurrency) {
        return;
      }

      if (!service.paymentInfo || !allowance) {
        pushAlert(SomethingWentWrong);
        cancelPayment();
        return;
      }

      const insufficientFunds =
        allowance.balanceUserCrypto && invoiceAmount.gt(allowance.balanceUserCrypto);
      const insufficientBalanceForWithdrawFee =
        !payWithLightning && allowance.balanceUserCrypto?.lt(totalAmounts.cryptoAmountWithFee);

      if (insufficientFunds || insufficientBalanceForWithdrawFee) {
        pushAlert(InsufficientBalance);
        cancelPayment();
        return;
      }

      if (!isEligibleForLn) {
        pushAlert(LightningLimitReached);
      }

      try {
        if (payWithLightning) {
          await lnPaymentSend({
            Input: {
              AccountType: defaults.accountType,
              Amount: service.paymentInfo.amount,
              CurrencyCode: CurrencyCode.BTC,
              DestinationType: TransferDestinationTypeInput.Bitrefill,
              PaymentRequest: service.paymentInfo.lnInvoice || '',
              RequestedAmount: {
                Amount: priceData.fiatAmount,
                FetchedAt: priceData.fetchedAt,
                FiatCurrency: fiatCurrency.code,
                Price: priceData.price,
              },
              ...(signature && { Nonce: signature.nonce, Signature: signature.signature }),
            },
          });
        } else {
          await onSubmitWithdrawOrder({
            cryptoAmount: service.paymentInfo.amount,
            cryptoAmountWithFee: totalAmounts.cryptoAmountWithFee,
            feeFiatAmount: feeData.feeFiatAmount,
            feeQuote: feeData.feeQuote,
            feeQuoteExpiresIn: getFeeQuoteExpiresIn(feeData.feeFetchedAt),
            fiatAmount: priceData.fiatAmount,
            pin,
            signature,
          });
        }

        service.awaitingPayment = false;
        setShowConfirmDialog(false);
      } catch (e) {
        logger.error(e);
        pushAlert(SomethingWentWrong);
        cancelPayment();
      }
    },
    [
      allowance,
      cancelPayment,
      feeData.feeFetchedAt,
      feeData.feeFiatAmount,
      feeData.feeQuote,
      fiatCurrency,
      invoiceAmount,
      isEligibleForLn,
      lnPaymentSend,
      onSubmitWithdrawOrder,
      payWithLightning,
      priceData,
      pushAlert,
      service,
      totalAmounts.cryptoAmountWithFee,
    ],
  );

  const { loading: signLoading, sign } = useSignWithdrawal({
    network: payWithLightning
      ? getEnvNetwork(Network.Lightning, isProd)
      : getEnvNetwork(Network.Bitcoin, isProd),
    payload: {
      AccountType: defaults.accountType,
      Amount: totalAmounts.cryptoAmountWithFee,
      CurrencyCode: defaults.cryptoCurrency.code,
      Destination: TransferDestinationTypeInput.Bitrefill,
      inputType: 'withdraw',
      NetworkFee: payWithLightning ? undefined : feeData.feeCryptoAmount,
    },
  });

  const onConfirm = useCallback(async () => {
    await sign(submitPayment);
  }, [sign, submitPayment]);

  const handleMessage = useCallback(
    (event: MessageEvent): void => {
      const { data, origin } = event;
      if (origin !== service.messageOrigin) {
        return;
      }
      service.parseMessage(data);
      try {
        if (service.paymentInfo?.lnInvoice) {
          setAddressData(parseAddressData(service.paymentInfo.lnInvoice, isProd));
        }
      } catch (err) {
        logger.error('Error parsing LN payment request', err);
        pushAlert(ParsingLnPaymentError);
      }
    },
    [pushAlert, service],
  );

  useEffect(() => {
    const abortController = new AbortController();
    window.addEventListener('message', handleMessage, {
      signal: abortController.signal,
    });
    return () => {
      abortController.abort();
    };
  });

  const submitButtonLoading =
    signLoading || lnPaymentSendIsLoading || btcPaymentLoading || isPriceLoading;

  const isCtaDisabled = payWithLightning ? false : !feeData.isFeeFetched;

  const { ApiErrorScene } = useWalletError(lnPaymentSendError || btcPaymentError);
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <Fragment>
      {isLoading && <LoadingPage sx={{ inset: 0, position: 'absolute' }} />}
      {url && (
        <iframe
          key={iframeKey}
          ref={iFrameRef}
          css={isLoading ? styles.hideIframe : styles.iframe}
          height="100%"
          sandbox="allow-popups allow-same-origin allow-scripts allow-forms"
          src={url}
          title="Bitrefill"
          width="100%"
          onLoad={(): void => setIsLoading(false)}
        >
          <p>Your browser does not support iframes.</p>
        </iframe>
      )}
      <Confirm
        addressData={addressData}
        cancelPayment={cancelPayment}
        email={email}
        FeeAmountSlots={FeeAmounts}
        isCtaDisabled={isCtaDisabled}
        isLightningPayment={!!payWithLightning}
        loadedPrice={priceData?.price}
        open={showConfirmDialog}
        submitLoading={submitButtonLoading}
        TotalAmountSlots={TotalAmounts}
        onConfirm={onConfirm}
      />
      <WithdrawNetworkFeeDialog
        ref={feeIncreasedDialogRef}
        FeeAmountSlots={{
          PrimaryAmountSlot: FeeAmounts.PrimaryAmountSlot,
          SecondaryAmountSlot: undefined,
        }}
        isLoading={isSubmitWithNewSignatureLoading}
        TotalAmountSlots={{
          PrimaryAmountSlot: TotalAmounts.PrimaryAmountSlot,
          SecondaryAmountSlot: undefined,
        }}
        onContinue={(): Promise<void> =>
          onSubmitWithNewSignature({
            cryptoAmount: totalAmounts.cryptoAmountWithFee,
            feeQuote: feeData.feeQuote,
            networkFee: feeData.feeCryptoAmount,
          })
        }
      />
      <Complete open={showCompleteDialog} onDismiss={dismiss} />
    </Fragment>
  );
}
