import { useCallback } from 'react';
import { useFeature } from '@noah-labs/fe-shared-feature-user';
import { generatePath, parseMarketingUrl, usePushAlert } from '@noah-labs/fe-shared-ui-components';
import { useRouter, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Feature } from '@noah-labs/shared-schema-gql';
import { isProd } from '../../../../webConfigBrowser';
import { useAddress } from '../../hooks/useAddress';
import { AddressScanScene } from '../../scenes/AddressScan';
import { handleParseScannedAddress } from '../../utils/address';

type PpAddressScan = {
  handleScannedAddress: (scanData: string) => void;
};

export function AddressScan({ handleScannedAddress }: PpAddressScan): React.ReactElement {
  const { params } = useWalletParams();
  const { push } = useRouter();
  const pushAlert = usePushAlert();
  const { feature: withdrawFF } = useFeature(Feature.Withdraw);

  const { setScannerUnavailable } = useAddress();

  const handleAddress = useCallback(
    (qrAddress: string) => {
      if (!withdrawFF?.Networks) {
        return;
      }

      const marketingUrl = parseMarketingUrl(qrAddress);
      if (marketingUrl) {
        push(marketingUrl);
        return;
      }

      const addressData = handleParseScannedAddress({
        address: qrAddress,
        availableNetworks: withdrawFF.Networks,
        isProd,
      });

      if (!addressData) {
        pushAlert({
          dismissable: true,
          key: 'addressError',
          message: 'That doesn’t look like a valid address, please try again.',
          preventDuplicate: true,
          severity: 'error',
        });
        return;
      }

      handleScannedAddress(addressData.address);
      push(generatePath(walletRoutes().address.text, params));
    },
    [push, params, withdrawFF?.Networks, pushAlert, handleScannedAddress],
  );

  return (
    <AddressScanScene
      onSuccess={handleAddress}
      onUnavailable={(): void => setScannerUnavailable(true)}
    />
  );
}
