import type { ReactElement } from 'react';
import {
  AppContainer,
  AppFooter,
  AppMain,
  PrimaryButton,
  SceneMain,
  SceneParagraph,
  SceneTitleLarge,
} from '@noah-labs/fe-shared-ui-components';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';

export type PpRevokePinScene = {
  isLoading: boolean;
  onRevokePin: () => void;
};

export function RevokePinScene({ isLoading, onRevokePin }: PpRevokePinScene): ReactElement {
  return (
    <AppContainer>
      <Helmet>
        <title>Revoke PIN</title>
      </Helmet>
      <AppMain>
        <AppHeaderData exitButton helpButton />
        <SceneMain dataQa="revoke-pin">
          <SceneTitleLarge>Revoke PIN</SceneTitleLarge>
          <SceneParagraph>
            Reset your PIN and recovery phrase by email. Do not select this option unless you have
            been instructed to do so by our customer support team. This process will take 7 days.
          </SceneParagraph>
        </SceneMain>
      </AppMain>
      <AppFooter>
        <PrimaryButton color="primaryBrand" loading={isLoading} onClick={onRevokePin}>
          Revoke Your PIN
        </PrimaryButton>
      </AppFooter>
    </AppContainer>
  );
}
