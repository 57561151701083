import React, { useCallback } from 'react';
import { useWithdrawFeeApprox } from '@noah-labs/fe-shared-data-access-wallet';
import { useUserInitUi, useUserWithdrawalAllowance } from '@noah-labs/fe-shared-feature-user';
import type { TpStateMachine } from '@noah-labs/fe-shared-ui-components';
import { generatePath } from '@noah-labs/fe-shared-ui-components';
import {
  cryptoCurrencyFromCode,
  getCryptoUnitAmount,
  useRouter,
  useWalletParams,
} from '@noah-labs/fe-shared-ui-shared';
import { EnterAmountScene, TransactionsLimitsIndicator } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import type { TpAmountForm } from '@noah-labs/fe-shared-util-validation-schemas';
import { getOnchainWithdrawSchema } from '@noah-labs/fe-shared-util-validation-schemas';
import { truncateAmount } from '@noah-labs/shared-currencies';
import { isUndefinedOrNull } from '@noah-labs/shared-util-vanilla';
import BigNumber from 'bignumber.js';
import { AvailableBalanceWithNetworkFeeData } from '../../components/AvailableBalanceWithNetworkFeeData';
import type { SmWithdraw } from '../types';

export function WithdrawEnterAmountController({
  state,
  updateState,
}: TpStateMachine<SmWithdraw>): React.ReactElement {
  // because of the routing guards, state.payeeData should be set by this point
  if (!state.payeeData?.network) {
    throw new Error('payee does not have required data');
  }

  const { AccountType, CurrencyCode, params } = useWalletParams();
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);
  const { data: userData } = useUserInitUi();
  const allowance = useUserWithdrawalAllowance({
    accountType: AccountType,
    cryptoCurrency,
    network: state.payeeData.network,
  });

  const feeAmounts = useWithdrawFeeApprox({
    cryptoCurrency,
    fiatCurrency: userData?.userProfile.fiatCurrency,
    network: state.payeeData.network,
  });

  const minAmounts =
    allowance?.withdrawMinimumSingleCrypto &&
    getCryptoUnitAmount({
      amount: allowance.withdrawMinimumSingleCrypto.toString(),
      cryptoCurrency,
      cryptoUnit: userData?.userProfile.DisplayUnit,
    });

  const schema = getOnchainWithdrawSchema({
    allowance,
    feeCryptoAmount: feeAmounts.feeCryptoAmount,
    fiatCurrency: userData?.userProfile.fiatCurrency,
    minAmounts,
  });

  const { push } = useRouter();
  const onSubmit = useCallback(
    (values: TpAmountForm) => {
      updateState({
        ...values,
        cryptoAmount: truncateAmount({
          amount: values.cryptoAmount,
          decimalPlaces: cryptoCurrency.decimals,
          roundingMode: BigNumber.ROUND_DOWN,
        }),
        fiatAmount: truncateAmount({
          amount: values.fiatAmount,
          decimalPlaces: userData?.userProfile.fiatCurrency.decimals,
          roundingMode: BigNumber.ROUND_DOWN,
        }),
      });
      push(generatePath(walletRoutes().withdraw.confirm, params));
    },
    [
      cryptoCurrency.decimals,
      params,
      push,
      updateState,
      userData?.userProfile.fiatCurrency.decimals,
    ],
  );

  const dailySendLimit = allowance?.accountDayCountLimitCrypto?.toNumber();
  const remainingTransactions = allowance?.withdrawalRemainingTxs?.toNumber();
  const showLimitsIndicator =
    !isUndefinedOrNull(dailySendLimit) && !isUndefinedOrNull(remainingTransactions);

  return (
    <EnterAmountScene
      amountRequired
      ContentSlot={
        <AvailableBalanceWithNetworkFeeData
          AccountType={AccountType}
          cryptoAmount={feeAmounts.feeCryptoAmount}
          cryptoCurrency={cryptoCurrency}
          fiatAmount={feeAmounts.feeCryptoAmount ? feeAmounts.feeFiatAmount : undefined}
        />
      }
      cryptoAmount={state.cryptoAmount}
      cryptoCurrency={cryptoCurrency}
      cryptoUnit={userData?.userProfile.DisplayUnit}
      ctaButtonDisabled={!schema}
      fiatAmount={state.fiatAmount}
      fiatCurrency={userData?.userProfile.fiatCurrency}
      FooterContentSlot={
        showLimitsIndicator ? (
          <TransactionsLimitsIndicator
            dailyLimit={dailySendLimit}
            remainingTransactions={remainingTransactions}
          />
        ) : undefined
      }
      priceProvider="market"
      primaryCurrency={userData?.userProfile.PrimaryCurrency}
      yupSchema={schema}
      onSubmit={onSubmit}
    />
  );
}
