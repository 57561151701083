import type { ComponentType, ReactElement } from 'react';
import { useAuth } from '@noah-labs/fe-shared-feature-auth';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { LoadingPage } from '@noah-labs/fe-shared-ui-components';
import type { PpAccessControl, TpAccessControlRedirect } from '@noah-labs/fe-shared-ui-shared';
import { AccessControl, useRouter } from '@noah-labs/fe-shared-ui-shared';
import { authRoutes } from '@noah-labs/fe-shared-util-routes';
import { Redirect } from 'react-router-dom';

export type PpAccessControlData = Pick<
  PpAccessControl,
  'children' | 'needsAuthStatus' | 'needsFeature'
> & {
  /**
   * redirectAuthStatus controls where a user is redirected to in case they do not have the needed auth status
   * default: "/"
   */
  redirectAuthStatus?: string;
  /**
   * redirectFeature controls where user is redirected to in case they do not have requested feature enabled
   * default: "/feature-disabled"
   */
  redirectFeature?: string;
  /**
   * if a redirectInvalid path (string) is provided, the component will immediately redirect to it - use to control stepped flows etc
   * default: undefined
   */
  redirectInvalid?: string | false;
  /**
   * if a redirectVerify is supplied, the user will be redirect to this path if they are authenticated but not verified
   * default: `routes.verify.path`
   */
  redirectVerify?: string | false;
};

export function AccessControlData({
  children,
  needsAuthStatus,
  needsFeature,
  redirectAuthStatus = '/',
  redirectFeature = '/feature-disabled',
  redirectInvalid,
  redirectVerify = authRoutes.verify,
}: PpAccessControlData): ReactElement {
  const { pathname, search } = useRouter();
  const { authStatus } = useAuth();
  const { data: userData, isFetched: userIsFetched } = useUserInitUi();

  return (
    <AccessControl
      Loading={LoadingPage}
      location={{ pathname, search }}
      needsAuthStatus={needsAuthStatus}
      needsFeature={needsFeature}
      // we can use the assertion here because Redirect from react-router-dom has wider types
      Redirect={Redirect as ComponentType<TpAccessControlRedirect>}
      redirectAuthStatus={redirectAuthStatus}
      redirectFeature={redirectFeature}
      redirectInvalid={redirectInvalid}
      redirectVerify={redirectVerify}
      userAuthStatus={authStatus}
      userFeatures={userData?.userProfile.features}
      userIsFetched={userIsFetched}
    >
      {children}
    </AccessControl>
  );
}
