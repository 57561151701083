import type { TpUserWithdrawalAllowance } from '@noah-labs/fe-shared-data-access-user';
import { CryptoAmount } from '@noah-labs/fe-shared-ui-components';
import type { TpCryptoUnitAmount } from '@noah-labs/fe-shared-ui-shared';
import type { TpAmount, TpFiatCurrency } from '@noah-labs/shared-currencies';
import { isNonZero, isUndefinedOrNull, safeBN } from '@noah-labs/shared-util-vanilla';
import * as yup from 'yup';
import { maxSafetyAmount } from './amounts';
import { getCryptoWithdrawalLimitsSchema } from './getCryptoWithdrawalLimitsSchema';
import type { TpAmountForm } from './types';

type TpGetOnchainWithdrawSchema = {
  allowance: TpUserWithdrawalAllowance | undefined;
  feeCryptoAmount: TpAmount;
  fiatCurrency: TpFiatCurrency | undefined;
  minAmounts: TpCryptoUnitAmount | undefined;
};
export function getOnchainWithdrawSchema({
  allowance,
  feeCryptoAmount,
  fiatCurrency,
  minAmounts,
}: TpGetOnchainWithdrawSchema): yup.ObjectSchema<Partial<TpAmountForm>> {
  return yup.object<Partial<TpAmountForm>>({
    cryptoAmount: yup.lazy(() => {
      // We need to set this first so we can override the other error messages
      if (allowance?.withdrawalRemainingTxs?.eq(0)) {
        return yup
          .string()
          .test('remainingTxs', 'Sorry, you have reached your daily send limits.', () => false);
      }

      let schema = getCryptoWithdrawalLimitsSchema(allowance?.accountAllowanceCrypto);

      schema = schema.test('WithdrawFee', '', function Test(cryptoAmount) {
        if (
          isUndefinedOrNull(feeCryptoAmount) ||
          isUndefinedOrNull(allowance?.balanceUserCrypto) ||
          isUndefinedOrNull(cryptoAmount)
        ) {
          return false;
        }

        const cryptoAmountWithFee = safeBN(cryptoAmount).plus(feeCryptoAmount);
        if (cryptoAmountWithFee.lte(allowance.balanceUserCrypto)) {
          return true;
        }

        return this.createError({
          message:
            'Your balance is too low to cover the sending amount and fees. Please adjust the amount or add funds to continue.',
        });
      });

      if (!isUndefinedOrNull(minAmounts)) {
        schema = schema.test({
          message: ({ value }) => {
            if (!isNonZero(value as string)) {
              return null;
            }
            return (
              <span>
                Minimum amount is{' '}
                <CryptoAmount
                  amount={minAmounts.amountBtc}
                  currency={minAmounts.cryptoCurrency}
                  currencyUnit={minAmounts.unit}
                />
              </span>
            );
          },
          test: (valueBtc) => safeBN(valueBtc).gte(minAmounts.amountBtc),
        });
      }

      return schema;
    }),
    fiatAmount: maxSafetyAmount(fiatCurrency),
  });
}
