import {
  Balance,
  generatePath,
  PrimaryButton,
  SceneHeaderCard,
  TabsButton,
  TextOrSkeleton,
} from '@noah-labs/fe-shared-ui-components';
import { routes } from '../../routes';
import type { TpRewardsTabProps, TpRewardsTabs } from '../../types';

type PpRewardsHeaderCard = {
  activeTab: TpRewardsTabs;
  tabs: TpRewardsTabProps[];
  userPoints: string | undefined;
};

export function RewardsHeader({
  activeTab,
  tabs,
  userPoints,
}: PpRewardsHeaderCard): React.ReactElement {
  return (
    <SceneHeaderCard>
      {/* TODO: add TextIconButton with dialog trigger */}
      <Balance label="Points" PrimaryAmountSlot={<TextOrSkeleton>{userPoints}</TextOrSkeleton>} />
      <TabsButton aria-label="rewards" value={activeTab}>
        {tabs.map((t) => (
          <PrimaryButton
            key={t.value}
            color={activeTab === t.value ? 'primaryLight' : 'ghost'}
            href={generatePath(routes.tabs, {
              tab: t.value,
            })}
            size="small"
            value={t.value}
          >
            {t.header}
          </PrimaryButton>
        ))}
      </TabsButton>
    </SceneHeaderCard>
  );
}
