import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import type { PpQRScanner } from '@noah-labs/fe-shared-ui-components';
import {
  AppContainer,
  AppHeaderTitle,
  AppMain,
  QRScanner,
  SceneMain,
} from '@noah-labs/fe-shared-ui-components';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';

export function AddressScanScene(props: PpQRScanner): React.ReactElement {
  const theme = useTheme();
  const backgroundColor = theme.palette.common.black;
  const fontColor = theme.palette.common.white;

  const [appHeaderHeight, setAppHeaderHeight] = useState(0);
  const appHeaderRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function updateAppHeaderHeight(): void {
      const { height } = appHeaderRef.current?.getBoundingClientRect() || { height: 0 };
      setAppHeaderHeight(height);
    }

    updateAppHeaderHeight();
  }, []);

  return (
    <AppContainer backgroundColor={backgroundColor} fontColor={fontColor}>
      <Helmet>
        <title>Scan address</title>
      </Helmet>
      <AppMain paddingBottom={0}>
        <div ref={appHeaderRef}>
          <AppHeaderData
            backButton
            exitButton
            helpButton
            backgroundColor={backgroundColor}
            fontColor={fontColor}
          >
            <AppHeaderTitle>Scan address</AppHeaderTitle>
          </AppHeaderData>
        </div>
        <SceneMain dataQa="scan" style={{ padding: 0 }}>
          <QRScanner {...props} appHeaderHeight={appHeaderHeight} />
        </SceneMain>
      </AppMain>
    </AppContainer>
  );
}
