import { useEffect } from 'react';
import { useCalculateFiatFromCrypto } from '@noah-labs/fe-shared-data-access-wallet';
import type { TpAmountForm } from '@noah-labs/fe-shared-util-validation-schemas';
import { isBTC, satsToBtc } from '@noah-labs/shared-currencies';
import { CurrencyUnit } from '@noah-labs/shared-schema-gql';
import { useFormContext } from 'react-hook-form';
import type { PpUsePrimaryBase } from './types';
import { TpDualCurrencyAmountFieldNames } from './types';

const validate = { shouldValidate: true };

type PpUsePrimaryCrypto = PpUsePrimaryBase & {
  cryptoUnit: CurrencyUnit | undefined;
};
export function usePrimaryCrypto({
  cryptoCurrency,
  cryptoUnit,
  enabled,
  fiatCurrency,
  priceProvider,
  primaryAmount,
  slippage,
}: PpUsePrimaryCrypto): void {
  const { setValue } = useFormContext<TpAmountForm>();

  // when in 'crypto' mode, the unit is sats and crypto is BTC, we need to convert primaryAmount from SATs to BTC
  const cryptoAmount =
    cryptoUnit === CurrencyUnit.SATS && isBTC(cryptoCurrency.code)
      ? satsToBtc(primaryAmount)
      : primaryAmount;

  const { data } = useCalculateFiatFromCrypto({
    cryptoAmount,
    cryptoCurrency,
    fiatCurrency,
    options: { enabled },
    priceProvider,
    slippage,
  });

  useEffect(() => {
    if (!enabled) {
      return;
    }

    // Sets the secondary display field - no validation is run on this
    setValue(TpDualCurrencyAmountFieldNames.secondary, data?.fiatAmount);

    // Set the fiat and crypto amount fields for validation and submission
    setValue(TpDualCurrencyAmountFieldNames.fiat, data?.fiatAmount, validate);
    setValue(TpDualCurrencyAmountFieldNames.crypto, cryptoAmount, validate);
  }, [cryptoAmount, data?.fiatAmount, enabled, setValue]);

  // update the price and fetchedAt values in a separate hook as they will change a lot less often
  useEffect(() => {
    if (!enabled) {
      return;
    }

    setValue(TpDualCurrencyAmountFieldNames.price, data?.price);
    setValue(TpDualCurrencyAmountFieldNames.fetchedAt, data?.fetchedAt);
  }, [data?.fetchedAt, data?.price, enabled, setValue]);
}
