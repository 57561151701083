import React from 'react';
import { Divider } from '@mui/material';
import {
  AvatarIconFromSvg,
  ElevatedCard,
  List,
  ListItem,
  ListItemContent,
  ListItemEndContent,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
  TextWithIcon,
} from '@noah-labs/fe-shared-ui-components';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-shared';
import type { Network } from '@noah-labs/shared-schema-gql';
import type { TpCurrencyAmountSlots } from '../utils/getCurrencyAmountSlots';
import { NetworkItem } from '../withdraw/NetworkItem';

export type PpAddressSummaryCard = {
  FeeSlot?: React.ReactElement;
  TotalAmountSlot?: TpCurrencyAmountSlots;
  address: React.ReactNode;
  cryptoCurrency: TpCryptoCurrencyUI;
  network: Network | undefined;
};

export function AddressSummaryCard({
  address,
  cryptoCurrency,
  FeeSlot,
  network,
  TotalAmountSlot,
}: PpAddressSummaryCard): React.ReactElement {
  return (
    <ElevatedCard sx={{ padding: [2, 2] }}>
      <List disablePadding spacing={1.5}>
        <ListItem>
          <ListItemContent padding={0}>
            <ListItemStartContent>
              <ListItemSecondaryText>Send</ListItemSecondaryText>
            </ListItemStartContent>
            <ListItemEndContent>
              <ListItemPrimaryText>
                <TextWithIcon sx={{ justifyContent: 'flex-end' }}>
                  <AvatarIconFromSvg size={3} svg={cryptoCurrency.svg} />
                  <span>{cryptoCurrency.label}</span>
                </TextWithIcon>
              </ListItemPrimaryText>
            </ListItemEndContent>
          </ListItemContent>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemContent padding={0}>
            <ListItemStartContent>
              <ListItemSecondaryText>To</ListItemSecondaryText>
            </ListItemStartContent>
            <ListItemEndContent sx={{ paddingLeft: 3, wordBreak: 'break-word' }}>
              <ListItemPrimaryText>{address}</ListItemPrimaryText>
            </ListItemEndContent>
          </ListItemContent>
        </ListItem>

        {network && (
          <React.Fragment>
            <Divider />
            <NetworkItem cryptoCurrency={cryptoCurrency} network={network} />
          </React.Fragment>
        )}

        {FeeSlot && (
          <React.Fragment>
            <Divider />
            {FeeSlot}
          </React.Fragment>
        )}

        {TotalAmountSlot && (
          <React.Fragment>
            <Divider />
            <ListItem data-qa="total">
              <ListItemContent padding={0}>
                <ListItemStartContent>
                  <ListItemPrimaryText>Total</ListItemPrimaryText>
                </ListItemStartContent>
                <ListItemEndContent>
                  <ListItemPrimaryText>{TotalAmountSlot.PrimaryAmountSlot}</ListItemPrimaryText>
                  <ListItemSecondaryText>
                    {TotalAmountSlot.SecondaryAmountSlot}
                  </ListItemSecondaryText>
                </ListItemEndContent>
              </ListItemContent>
            </ListItem>
          </React.Fragment>
        )}
      </List>
    </ElevatedCard>
  );
}
