import { useCallback } from 'react';
import type { TpNormalisedDestinations } from '@noah-labs/fe-shared-data-access-analytics';
import {
  updateUserInitCache,
  useUserSettingsConsentCreateMutation,
} from '@noah-labs/fe-shared-data-access-user';
import type { UserSettingsConsentInput } from '@noah-labs/shared-schema-gql';
import { useQueryClient } from 'react-query';
import { webConfigBrowser } from '../../../webConfigBrowser';
import type { TpConsentFormValues } from '../scenes/Consent';

type PpUseConsentOnSubmit = {
  groupedDestinations: TpNormalisedDestinations | undefined;
  /**
   * The Segment analytics script needs to be reloaded if there have been any changes so need use a real page navigation
   * This is why we have the nextAction and nextActionDirty functions passed to the Consent controller
   * This avoids some page reloads where the user accepts the default options
   * nextAction can be undefined when in the settings screen because we wouldn't allow a non-dirty onsubmit anyway
   */
  nextAction?: () => void;
  nextActionDirty: () => void;
};

type TpUseConsentOnSubmit = {
  error: unknown;
  handleConsentSubmit: (values: TpConsentFormValues, dirtyUserPrefs: boolean) => Promise<void>;
};
export function useConsentOnSubmit({
  groupedDestinations,
  nextAction,
  nextActionDirty,
}: PpUseConsentOnSubmit): TpUseConsentOnSubmit {
  const { error, mutateAsync } = useUserSettingsConsentCreateMutation();
  const queryClient = useQueryClient();

  const handleConsentSubmit = useCallback(
    async (values: TpConsentFormValues, dirtyUserPrefs: boolean): Promise<void> => {
      if (!groupedDestinations) {
        return undefined;
      }
      const { advertising, marketingAnalytics } = groupedDestinations;
      /**
       * if we don't have any destinations for a particular category, don't set a value in the mutation,
       * so that we can distinguish between a user actively disagreeing vs not having the choice yet
       */
      const userSettingsConsent: UserSettingsConsentInput = {
        CommitHash: webConfigBrowser.commitHash,
        Cookies: {
          advertising: advertising.length > 0 ? Boolean(values.Cookies.advertising) : undefined,
          functional: Boolean(values.Cookies.functional),
          marketingAndAnalytics:
            marketingAnalytics.length > 0
              ? Boolean(values.Cookies.marketingAndAnalytics)
              : undefined,
        },
        EmailContact: values.EmailContact,
        Terms: values.Terms,
      };

      try {
        /**
         * The update to the DDB table will trigger an event which updates downstream services
         */
        const response = await mutateAsync({ Input: userSettingsConsent });
        /**
         * Update the useInitCache directly so that we know the user has accepted terms now
         */
        updateUserInitCache(queryClient, undefined, [response.userSettingsConsentCreate]);

        /**
         * Perform the next action depending on if the form was dirty or not
         */
        if (dirtyUserPrefs) {
          nextActionDirty();
        } else if (nextAction) {
          nextAction();
        }
      } catch (err) {
        /**
         * Our API errors are handled in the useUserError
         */
      }

      // Return an empty promise so that the button remains in a loading state while the page is loading
      return new Promise(() => {});
    },
    [groupedDestinations, mutateAsync, nextAction, nextActionDirty, queryClient],
  );

  return {
    error,
    handleConsentSubmit,
  };
}
