import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  AppContainer,
  AppMain,
  AvatarIconFromSvg,
  SceneHeaderCard,
  SceneMain,
  TextWithIcon,
  useDesktop,
} from '@noah-labs/fe-shared-ui-components';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';
import { SidebarNavData } from '../../../components/navigation/SidebarNavData';
import { useBackHijack } from '../../../hooks/useBackHijack';

export type PpAccountScene = {
  ActionsMenuSlot: React.ReactElement;
  BalanceSlot: React.ReactElement;
  TransactionsSlot: React.ReactElement;
  cryptoCurrency: TpCryptoCurrencyUI;
};

export function AccountScene({
  ActionsMenuSlot,
  BalanceSlot,
  cryptoCurrency,
  TransactionsSlot,
}: PpAccountScene): React.ReactElement {
  const { backTo, goBack } = useBackHijack(walletRoutes().base);
  const theme = useTheme();
  const isDesktop = useDesktop();
  return (
    <AppContainer SideNavbarSlot={<SidebarNavData />}>
      <Helmet>
        <title>{cryptoCurrency.label}</title>
      </Helmet>
      <AppMain>
        <AppHeaderData
          disableFade
          helpButton
          scanButton
          backButton={goBack}
          backgroundColor={theme.palette.grayscale.offWhite}
          backTo={backTo}
          fullWidth={isDesktop}
        >
          <TextWithIcon>
            <AvatarIconFromSvg size={2.5} svg={cryptoCurrency.svg} />
            <span>{cryptoCurrency.label}</span>
          </TextWithIcon>
        </AppHeaderData>
        <SceneHeaderCard>
          {BalanceSlot}
          {ActionsMenuSlot}
        </SceneHeaderCard>
        <SceneMain dataQa="account">
          <Typography gutterBottom component="h1" variant="subHeadingS">
            Activity history
          </Typography>
          {TransactionsSlot}
        </SceneMain>
      </AppMain>
    </AppContainer>
  );
}
