import { Fragment, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mui/material';
import type { TpPriceProvider } from '@noah-labs/fe-shared-data-access-wallet';
import {
  AppFooter,
  AppMain,
  FormGrid,
  FormItem,
  InputField,
  PrimaryButton,
  SceneMain,
} from '@noah-labs/fe-shared-ui-components';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-shared';
import type { TpAmountForm } from '@noah-labs/fe-shared-util-validation-schemas';
import type { TpFiatCurrency, TpSlippage } from '@noah-labs/shared-currencies';
import { btcToSats } from '@noah-labs/shared-currencies';
import { CurrencyDisplayType, CurrencyUnit } from '@noah-labs/shared-schema-gql';
import { isUndefinedOrNull, zeroToBlankOrValue } from '@noah-labs/shared-util-vanilla';
import type { Resolver, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import type * as yup from 'yup';
import { DualCurrencyAmountField } from '../forms/DualCurrencyAmountField';

export type PpEnterAmountScene = {
  ContentSlot?: React.ReactElement;
  FooterContentSlot?: React.ReactElement;
  SwitchCurrencySlot?: React.ReactElement;
  amountPlaceholder?: string;
  amountRequired?: boolean;
  cryptoAmount: string;
  cryptoCurrency: TpCryptoCurrencyUI;
  cryptoUnit: CurrencyUnit | undefined;
  ctaButtonDisabled?: boolean;
  ctaButtonLabel?: string;
  description?: string;
  disableSwitch?: boolean;
  fiatAmount: string;
  fiatCurrency: TpFiatCurrency | undefined;
  isCryptoAmountNet?: boolean;
  onBlurValues?: (values: Pick<TpAmountForm, 'cryptoAmount' | 'fiatAmount'>) => void;
  onSubmit: SubmitHandler<TpAmountForm>;
  priceProvider: TpPriceProvider;
  primaryCurrency: CurrencyDisplayType | null | undefined;
  slippage?: TpSlippage;
  yupSchema?: yup.ObjectSchema<Partial<TpAmountForm>>;
};

export function EnterAmountScene({
  amountPlaceholder,
  amountRequired = false,
  ContentSlot,
  cryptoAmount,
  cryptoCurrency,
  cryptoUnit,
  ctaButtonDisabled = false,
  ctaButtonLabel = 'Continue',
  description,
  disableSwitch = false,
  fiatAmount,
  fiatCurrency,
  FooterContentSlot,
  isCryptoAmountNet,
  onBlurValues,
  onSubmit,
  priceProvider,
  primaryCurrency,
  slippage,
  SwitchCurrencySlot,
  yupSchema,
}: PpEnterAmountScene): React.ReactElement {
  let primaryAmount;

  switch (primaryCurrency) {
    case CurrencyDisplayType.Fiat:
      primaryAmount = fiatAmount;
      break;

    case CurrencyDisplayType.Crypto:
      primaryAmount = cryptoAmount;
      if (cryptoUnit === CurrencyUnit.SATS) {
        primaryAmount = btcToSats(cryptoAmount);
      }
      break;

    default:
      break;
  }

  const methods = useForm<TpAmountForm>({
    defaultValues: {
      cryptoAmount,
      description,
      fetchedAt: '',
      fiatAmount,
      price: '',
      primaryAmount: zeroToBlankOrValue(primaryAmount),
      secondaryAmount: '',
    },
    mode: 'all',
    resolver: yupSchema && (yupResolver(yupSchema) as Resolver<TpAmountForm>),
  });

  const errorMessage =
    methods.formState.errors.cryptoAmount?.message || methods.formState.errors.fiatAmount?.message;

  useEffect(() => {
    if (!yupSchema) {
      return;
    }
    void methods.trigger();
  }, [yupSchema, methods]);

  return (
    <Fragment>
      <AppMain>
        <SceneMain dense dataQa="enter-amount">
          <FormProvider {...methods}>
            <form id="amount-form" onSubmit={methods.handleSubmit(onSubmit)}>
              <FormGrid>
                <FormItem fullWidth>
                  <DualCurrencyAmountField
                    cryptoCurrency={cryptoCurrency}
                    cryptoUnit={cryptoUnit}
                    disableSwitch={disableSwitch}
                    errorMessage={errorMessage}
                    fiatCurrency={fiatCurrency}
                    InputFieldAtomProps={{
                      dataQa: 'amount',
                      placeholder: amountPlaceholder,
                      required: amountRequired,
                    }}
                    isCryptoAmountNet={isCryptoAmountNet}
                    priceProvider={priceProvider}
                    slippage={slippage}
                    SwitchCurrencySlot={SwitchCurrencySlot}
                    userPrimaryCurrency={primaryCurrency}
                    onBlurValues={onBlurValues}
                  />
                </FormItem>
                {!isUndefinedOrNull(description) && (
                  <FormItem fullWidth>
                    <InputField
                      fullWidth
                      dataQa="description"
                      label="Note (optional)"
                      name="description"
                      placeholder="Note..."
                      type="text"
                    />
                  </FormItem>
                )}
              </FormGrid>
            </form>
          </FormProvider>
          <Box sx={{ mt: 6 }}>{ContentSlot}</Box>
        </SceneMain>
      </AppMain>
      <AppFooter>
        <Stack spacing={5}>
          {FooterContentSlot}
          <PrimaryButton
            color="primaryBrand"
            disabled={!methods.formState.isValid || ctaButtonDisabled}
            form="amount-form"
            loading={methods.formState.isSubmitting}
            type="submit"
          >
            {ctaButtonLabel}
          </PrimaryButton>
        </Stack>
      </AppFooter>
    </Fragment>
  );
}
