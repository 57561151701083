import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { LowDialog, PrimaryButton } from '@noah-labs/fe-shared-ui-components';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import { useToggleDialog } from '@noah-labs/fe-shared-ui-shared';

export const CreatePinInfoDialog = forwardRef<TpDialogToggle>((_, ref): JSX.Element => {
  const { open, toggle } = useToggleDialog({ ref });

  return (
    <LowDialog open={open} onClose={toggle}>
      <DialogTitle>Why is a pin code safer than SMS 2FA?</DialogTitle>
      <DialogContent>
        <DialogContentText paragraph>
          An attacker can intercept or compromise text messages in many ways, including intercepting
          the message, compromising a phone number associated with the account, swapping sims, etc.
          The one-time PIN can be accessed by an attacker in this way and used to access the
          account.
        </DialogContentText>
        <DialogContentText>
          A 6-digit PIN code, on the other hand, is typically stored locally or in the user’s memory
          and is not transmitted over the network, making it less vulnerable to these types of
          attacks.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <PrimaryButton color="primaryBrand" onClick={toggle}>
          Got it!
        </PrimaryButton>
      </DialogActions>
    </LowDialog>
  );
});

CreatePinInfoDialog.displayName = CreatePinInfoDialog.name;
