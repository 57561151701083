import { useMemo } from 'react';
import { useInfiniteTransactionsQuery } from '@noah-labs/fe-shared-data-access-wallet';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { SortDirection } from '@noah-labs/shared-schema-gql';
import { duration } from '@noah-labs/shared-util-vanilla';
import { TransactionList } from './TransactionList';

export function TransactionsData(): React.ReactElement {
  const { data: userData } = useUserInitUi();
  const { CurrencyCode } = useWalletParams();
  const { data, fetchNextPage, hasNextPage, isFetched, isFetchingNextPage } =
    useInfiniteTransactionsQuery(
      {
        filter: {
          // TODO: this _should_ work but currently some tx's don't have this field
          // AccountType: {
          //   eq: AccountType,
          // },
          Currency: {
            eq: CurrencyCode,
          },
        },
        limit: 20,
        sortDirection: SortDirection.DESC,
      },
      {
        getNextPageParam: (prevPage) => {
          if (prevPage.transactions.nextToken) {
            return { nextToken: prevPage.transactions.nextToken };
          }
          return undefined;
        },
        refetchInterval: duration.seconds(3),
      },
    );

  const allTransactions = useMemo(
    () => data?.pages.flatMap((page) => page.transactions.items),
    [data?.pages],
  );

  return (
    <TransactionList
      cryptoUnit={userData?.userProfile.DisplayUnit}
      hasNextPage={hasNextPage}
      isFetched={isFetched}
      isFetchingNextPage={isFetchingNextPage}
      primaryCurrency={userData?.userProfile.PrimaryCurrency}
      transactions={allTransactions}
      userID={userData?.userProfile.UserID}
      onNextPage={fetchNextPage}
    />
  );
}
