import { useCallback } from 'react';
import { css } from '@emotion/react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TpAnalyticsEvent, useAnalytics } from '@noah-labs/fe-shared-feature-analytics';
import {
  coreThemeConstants,
  cssMixins,
  ElevatedCard,
  PrimaryButton,
  TextOrSkeleton,
} from '@noah-labs/fe-shared-ui-components';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import type { QuestTaskWithStatus } from '@noah-labs/shared-schema-gql';
import { isQuestTaskComplete } from '../../../data/utils';
import { getQuestTaskAction } from '../../../mappings/questTaskActions';
import { getQuestTaskCopy } from '../../../mappings/questTaskCopy';

export type PpQuestTaskCard = {
  task: QuestTaskWithStatus;
};

const pointsStyle = css`
  /* TODO Font variant missing */
  font-family: ${coreThemeConstants.fontFamily.extended};
  font-style: normal;
  font-weight: 900;
  font-size: 0.75rem;
  text-transform: uppercase;
  line-height: 12px;
`;

export function QuestTaskCard({ task }: PpQuestTaskCard): React.ReactElement {
  const theme = useTheme();
  const styles = {
    card: css`
      position: relative;
      padding: ${theme.spacing(2)};
    `,
    completed: css`
      ::after {
        content: '';
        position: absolute;
        ${cssMixins.stretchAll}
        background: ${theme.palette.grayscale.black40};
      }
    `,
  };
  const { track } = useAnalytics();
  const { pathname, push } = useRouter();

  const taskAction = getQuestTaskAction(task.Task);
  const taskCopy = getQuestTaskCopy(task.Task);
  const isComplete = isQuestTaskComplete(task);

  const cardStyles = [styles.card];
  if (isComplete) {
    cardStyles.push(styles.completed);
  }

  const handleClick = useCallback(() => {
    if (!taskAction) {
      return;
    }

    track(TpAnalyticsEvent.TaskClick, {
      requirement: taskAction.requirement,
    });

    if (!taskAction.href) {
      return;
    }

    if (taskAction.externalLink) {
      window.open(taskAction.href, '_blank');
      return;
    }

    push({ pathname: taskAction.href, state: { from: pathname } });
  }, [pathname, push, taskAction, track]);

  return (
    <ElevatedCard css={cardStyles}>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography variant="paragraphBodyMBold">
            <TextOrSkeleton>{taskCopy?.title}</TextOrSkeleton>
          </Typography>
          <Typography color="text.light" variant="paragraphBodyS">
            <TextOrSkeleton>{taskCopy?.description}</TextOrSkeleton>
          </Typography>
          <Typography color="brand.light" css={pointsStyle}>
            <TextOrSkeleton>{taskCopy?.reward}</TextOrSkeleton>
          </Typography>
        </Stack>
        {!isComplete && !!taskAction?.cta && (
          <PrimaryButton
            color="primaryBrand"
            loading={!taskAction}
            size="medium"
            onClick={handleClick}
          >
            {taskAction.cta}
          </PrimaryButton>
        )}
      </Stack>
    </ElevatedCard>
  );
}
