import { Switch404 } from '@noah-labs/fe-shared-ui-components';
import { TpAuthStatus } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Route, useRouteMatch } from 'react-router-dom';
import { AccessControlData } from '../../../auth/AccessControlData';
import { Account } from './Account';
import { Transaction } from './Transaction';

export function AccountRouter(): React.ReactElement {
  const match = useRouteMatch<{ id: string }>(walletRoutes().account.transactions);

  return (
    <Switch404>
      <Route path={walletRoutes().account.base}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <Account />
          <Transaction id={match?.params.id} />
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
