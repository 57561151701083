import type { ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { NovuProvider as BaseNovuProvider } from '@novu/notification-center';
import { useNovuSubscriberHashQuery } from '../data/notifications.generated';

type NovuProviderProps = {
  applicationId: string;
};

export function NovuProvider({ applicationId, children }: NovuProviderProps & PpWC): ReactElement {
  const theme = useTheme();
  const { data: userData } = useUserInitUi();

  const { data: subscriberHashQuery } = useNovuSubscriberHashQuery();

  const subscriberHash = subscriberHashQuery?.novuSubscriberHash.SubscriberHash;

  return (
    <BaseNovuProvider
      applicationIdentifier={subscriberHash ? applicationId : ''}
      styles={{
        header: {
          root: {
            backgroundColor: theme.palette.grayscale.offWhite,
          },
        },
      }}
      subscriberHash={subscriberHash}
      subscriberId={userData?.userProfile.UserID}
    >
      {children}
    </BaseNovuProvider>
  );
}
