import type { TpShareProps } from '@noah-labs/fe-shared-ui-components';
import { badgeNames } from './badgeCopy';

const cloudinaryPrefix = 'https://res.cloudinary.com/noahapp/image/upload/c_scale,w_1200/';
const imageFileExt = '.jpg';

export function getBadgeShareData(badgeId: string | undefined, referralLink: string): TpShareProps {
  if (!badgeId) {
    return { text: undefined };
  }

  const badgeName = badgeNames[badgeId];

  if (!badgeName) {
    // return empty share data otherwise we'd have to have a conditional hook which wouldn't work
    return { text: undefined };
  }

  const fileData = {
    filename: `${badgeName}${imageFileExt}`,
    fileType: 'image/jpg',
  };

  const data = {
    title: badgeName,
  };

  switch (badgeId) {
    case 'AdventureStone':
      return {
        ...data,
        file: {
          ...fileData,
          url: `${cloudinaryPrefix}v1676070576/App%20sharing%20images/Stones/Adventure_stone-Social_sharing_2x_nfelmm${imageFileExt}`,
        },
        text: `I just unlocked the Adventure Stone by registering for NOAH! Use my referral link to get started! #noahrewards ${referralLink}`,
      };
    case 'Arkonaut':
      return {
        ...data,
        file: {
          ...fileData,
          url: `${cloudinaryPrefix}v1676070388/App%20sharing%20images/Referral%20Badges/Arkonaut-app_2x_ru1hab${imageFileExt}`,
        },
        text: `I have officially unlocked the Arkonaut Badge by inviting 15 friends to NOAH. Use my referral link to get started with NOAH! #noahrewards ${referralLink}`,
      };
    case 'BitrefillStonePlus':
      return {
        ...data,
        file: {
          ...fileData,
          url: `${cloudinaryPrefix}v1676070578/App%20sharing%20images/Stones/Launch-Social_sharing-Fancy_2x_ggothd${imageFileExt}`,
        },
        text: `Achievement unlocked. I just claimed the Bitrefill Stone and 500 NP by making a purchase with Bitrefill through NOAH. Relish in the rewards by using my referral link to sign up now! #noahrewards ${referralLink}`,
      };
    case 'BitrefillStone':
      return {
        ...data,
        file: {
          ...fileData,
          url: `${cloudinaryPrefix}v1676070574/App%20sharing%20images/Stones/Social_sharing_2x_sybqgp${imageFileExt}`,
        },
        text: `Achievement unlocked. I just claimed the Bitrefill Stone and 500 NP by making a purchase with Bitrefill through NOAH. Relish in the rewards by using my referral link to sign up now! #noahrewards ${referralLink}`,
      };
    case 'Founder':
      return {
        ...data,
        file: {
          ...fileData,
          url: `${cloudinaryPrefix}v1676070391/App%20sharing%20images/Referral%20Badges/Founder-app_2x_ifrs5c${imageFileExt}`,
        },
        text: `I was one of NOAH’s earliest adopters and achieved the exclusive Founders Badge! Use my referral link to get started with NOAH! #noahrewards ${referralLink}`,
      };
    case 'LightningStone':
      return {
        ...data,
        file: {
          ...fileData,
          url: `${cloudinaryPrefix}v1676070574/App%20sharing%20images/Stones/Lightning_stone_-Social_sharing_2x_zie456${imageFileExt}`,
        },
        text: `I just unlocked the Lightning Stone for sending lightning fast bitcoin payments with NOAH! Use my referral link to get started with NOAH! #noahrewards ${referralLink}`,
      };
    case 'Traveler':
      return {
        ...data,
        file: {
          ...fileData,
          url: `${cloudinaryPrefix}v1676070388/App%20sharing%20images/Referral%20Badges/Traveller-app-US_2x_kecvfy${imageFileExt}`,
        },
        text: `My 10th referral just joined NOAH and I unlocked the Traveler Badge! Use my referral link to help me get the next badge! #noahrewards ${referralLink}`,
      };
    case 'TreasureStone':
      return {
        ...data,
        file: {
          ...fileData,
          url: `${cloudinaryPrefix}v1676070587/App%20sharing%20images/Stones/Treasure_stone-Social_sharing_2x_yhpbhe${imageFileExt}`,
        },
        text: `I just unlocked the Treasure Stone and it’s rewards just for holding my bitcoin in NOAH! Use my referral link to get started! #noahrewards ${referralLink}`,
      };
    case 'Wanderer':
      return {
        ...data,
        file: {
          ...fileData,
          url: `${cloudinaryPrefix}v1676070389/App%20sharing%20images/Referral%20Badges/Wanderer-app_2x_rnsmb5${imageFileExt}`,
        },
        text: `I just invited my 5th Arkonaut to NOAH and unlocked the Wanderer Badge. Use my referral link to help me get the next badge! #noahrewards ${referralLink}`,
      };
    case 'ArkStone001':
      return {
        ...data,
        file: {
          ...fileData,
          url: `${cloudinaryPrefix}v1676070579/App%20sharing%20images/Stones/Ark_stone-Social_sharing_2x_x6dszd${imageFileExt}`,
        },
        text: `I just completed the Black Friday Quest and claimed the Limited Edition Ark Stone | 001 and 300 NP along with it! Use my referral link to get started with NOAH! #noahrewards ${referralLink}`,
      };
    case 'ArkStone002':
      return {
        ...data,
        file: {
          ...fileData,
          url: `${cloudinaryPrefix}v1676070580/App%20sharing%20images/Stones/Social_sharing_2x-1_ed7qx3${imageFileExt}`,
        },
        text: `Love is in the air! I just claimed the heart-shaped Ark Stone | 002 and 500 NP by depositing 100k sats. Share the love, deposit your sats using my referral link, and let the good times roll! #noahrewards ${referralLink}`,
      };
    case 'ArkStone003':
      return {
        ...data,
        file: {
          ...fileData,
          url: `${cloudinaryPrefix}v1679672664/App%20sharing%20images/Stones/003-Social_sharing-600x600_fuq7za${imageFileExt}`,
        },
        text: `Victory is sweet! I just found the Ark Stone | 003 and earned 200 NP in the Easter Egg Hunt. Join the fun, discover your Easter egg using my referral link, and celebrate together!`,
      };
    case 'StarlightStone':
      return {
        ...data,
        file: {
          ...fileData,
          url: `${cloudinaryPrefix}v1677684848/App%20sharing%20images/Stones/Starlight_Stone_social_sharing_ypaot8${imageFileExt}`,
        },
        text: ` I just received the Starlight Stone and 2000 NP by buying bitcoin through the NOAH app. Try it for yourself using my referral link to take advantage of all-time low fees! #noahrewards ${referralLink}`,
      };
    case 'DiscordStone':
      return {
        ...data,
        file: {
          ...fileData,
          url: `${cloudinaryPrefix}v1681317967/App%20sharing%20images/Stones/Discord-stone-share-image_e7rozl${imageFileExt}`,
        },
        text: `I’ve just unlocked the Discord Stone by joining NOAH’s Discord channel. Want it for yourself? Complete the Discord Quest.`,
      };
    case 'WarpStone':
      return {
        ...data,
        file: {
          ...fileData,
          url: `${cloudinaryPrefix}v1689753479/App%20sharing%20images/Stones/Warp_Stone_Share_1_ezg71m${imageFileExt}`,
        },
        text: `Victory! Just unlocked the Warp Stone and pocketed a cool 1000 NOAH Points with a $20 deposit. Want in on the action? Use my referral link and join NOAH now. #noahrewards ${referralLink}`,
      };
    default:
      // return empty share data otherwise we'd have to have a conditional hook which wouldn't work
      return { text: undefined };
  }
}
