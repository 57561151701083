import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { Switch404 } from '@noah-labs/fe-shared-ui-components';
import { TpAuthStatus } from '@noah-labs/fe-shared-ui-shared';
import { Feature, KycApprovalStatus } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { AccessControlData } from '../auth/AccessControlData';
import { Complete } from './controllers/Complete';
import { KycReminder } from './controllers/Reminder';
import { Sumsub } from './controllers/Sumsub';
import { routes } from './routes';

export function KycRouter(): React.ReactElement {
  const { data: userData } = useUserInitUi();

  /**
   * Should only redirect to our complete path if
   * - user has a KycVerification.ApprovalStatus
   * - AND the status is not 'pending'
   */
  let redirectToComplete = false;
  if (
    userData?.userProfile.KycVerification.ApprovalStatus &&
    userData.userProfile.KycVerification.ApprovalStatus !== KycApprovalStatus.Pending
  ) {
    redirectToComplete = true;
  }

  return (
    <Switch404>
      <Route exact path={routes.base}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Sumsub]}
          redirectInvalid={redirectToComplete && routes.complete}
        >
          <Sumsub />
        </AccessControlData>
      </Route>
      <Route exact path={routes.complete}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Sumsub]}
        >
          <Complete />
        </AccessControlData>
      </Route>
      <Route exact path={routes.reminder}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Sumsub]}
        >
          <KycReminder />
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
