import type { Country, CountryCode } from '@noah-labs/shared-schema-gql';
import { compareStrings } from '@noah-labs/shared-util-vanilla';
import type { TpCountriesForSelect, TpCountryFilterFunction, TpCountryWithCode } from './types';

function compareCountryName(a: Country, b: Country): number {
  return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
}

let sortedCountriesCache: Array<TpCountryWithCode> | undefined;
async function sortedCountries(): Promise<Array<TpCountryWithCode>> {
  if (sortedCountriesCache) {
    return sortedCountriesCache;
  }
  const CountriesList = await import('countries-list');
  const countryArray = Object.entries(CountriesList.default.countries).map(([key, country]) => ({
    ...country,
    code: key as CountryCode,
  }));
  sortedCountriesCache = countryArray.sort(compareCountryName);
  return sortedCountriesCache;
}

/**
 * This function returns a list of countries and the default user country if provided.
 * It validates the user's country against the returned list.
 * If the user's country is provided and valid, the list is sorted with the user's country at the top.
 *
 * @returns
 * - countries: A list of countries - can be filtered against the filterFn.
 * - defaultCountry: The user's country validated against the returned list.
 *    If the user's country is not found in the list, it defaults to undefined.
 */
export async function countriesForSelect(
  userCountry?: CountryCode | null,
  filterFn?: TpCountryFilterFunction,
): Promise<TpCountriesForSelect> {
  let userDefaultCountry: CountryCode | undefined;
  const countryArray = await sortedCountries();
  const filteredCountries = countryArray.reduce<TpCountriesForSelect['countries']>(
    (acc, country) => {
      if (!filterFn || filterFn(country.code)) {
        const countryItem = {
          currency: country.currency,
          label: country.name,
          symbol: country.emoji,
          value: country.code,
        };

        if (compareStrings(userCountry, country.code)) {
          userDefaultCountry = userCountry as CountryCode;
          acc.unshift(countryItem);
          return acc;
        }

        acc.push(countryItem);
      }
      return acc;
    },
    [],
  );

  return {
    countries: filteredCountries,
    defaultCountry: userDefaultCountry,
  };
}
