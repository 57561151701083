import { AppEnvs, Network } from '@noah-labs/shared-schema-gql';

const cnTestMap: Record<Network, Network> = {
  Bitcoin: Network.BitcoinTest,
  BitcoinTest: Network.BitcoinTest,
  Ethereum: Network.EthereumTestSepolia,
  EthereumTestSepolia: Network.EthereumTestSepolia,
  Lightning: Network.LightningTest,
  LightningTest: Network.LightningTest,
  OffNetwork: Network.OffNetwork,
  PolygonPos: Network.PolygonTestAmoy,
  PolygonTestAmoy: Network.PolygonTestAmoy,
  PolygonTestMumbai: Network.PolygonTestAmoy,
  Tron: Network.TronTestShasta,
  TronTestShasta: Network.TronTestShasta,
};
const cnProdMap: Record<Network, Network> = {
  Bitcoin: Network.Bitcoin,
  BitcoinTest: Network.Bitcoin,
  Ethereum: Network.Ethereum,
  EthereumTestSepolia: Network.Ethereum,
  Lightning: Network.Lightning,
  LightningTest: Network.Lightning,
  OffNetwork: Network.OffNetwork,
  PolygonPos: Network.PolygonPos,
  PolygonTestAmoy: Network.PolygonPos,
  PolygonTestMumbai: Network.PolygonPos,
  Tron: Network.Tron,
  TronTestShasta: Network.Tron,
};

export function getEnvNetwork(code: Network, isProd: boolean): Network {
  return isProd ? cnProdMap[code] : cnTestMap[code];
}

export function getAppEnvNetwork(code: Network, env: AppEnvs): Network {
  return getEnvNetwork(code, env === AppEnvs.Prod);
}
