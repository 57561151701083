import { TpAvatarStatus } from '@noah-labs/fe-shared-ui-components';
import { TpCheckoutPaymentStatus, TpCheckoutPayoutStatus } from '@noah-labs/fe-shared-util-shared';

export function getCheckoutAvatarStatus(
  status: TpCheckoutPaymentStatus | TpCheckoutPayoutStatus,
): TpAvatarStatus {
  switch (status) {
    case TpCheckoutPaymentStatus.pending:
    case TpCheckoutPayoutStatus.pending:
      return TpAvatarStatus.pending;
    case TpCheckoutPaymentStatus.failure:
    case TpCheckoutPayoutStatus.failure:
      return TpAvatarStatus.failed;
    case TpCheckoutPaymentStatus.success:
    case TpCheckoutPayoutStatus.success:
    default:
      return TpAvatarStatus.success;
  }
}
