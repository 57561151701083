import type { TpPriceProvider } from '@noah-labs/fe-shared-data-access-wallet';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-shared';
import type { TpFiatCurrency, TpSlippage } from '@noah-labs/shared-currencies';

export enum TpDualCurrencyAmountFieldNames {
  crypto = 'cryptoAmount',
  fetchedAt = 'fetchedAt',
  fiat = 'fiatAmount',
  price = 'price',
  primary = 'primaryAmount',
  secondary = 'secondaryAmount',
}

export type PpUsePrimaryBase = {
  cryptoCurrency: TpCryptoCurrencyUI;
  enabled: boolean;
  fiatCurrency: TpFiatCurrency | undefined;
  priceProvider: TpPriceProvider;
  primaryAmount: string;
  slippage?: TpSlippage;
};
