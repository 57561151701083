import { useState } from 'react';
import { Switch404 } from '@noah-labs/fe-shared-ui-components';
import { TpAuthStatus } from '@noah-labs/fe-shared-ui-shared';
import { userRoutes, walletRoutes } from '@noah-labs/fe-shared-util-routes';
import type { SafeWrapper } from '@noah-labs/shared-cryptography';
import { Feature } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { AccessControlData } from '../auth/AccessControlData';
import { CreatePinRecover } from './controllers/CreatePinRecover';
import { EnterSecretPhrase } from './controllers/EnterSecretPhrase';
import { PinSuccessScene } from './scenes/PinSuccess';

/**
 * Users attempt to recover their wallet/pin with their secret phrase.
 */
export function RecoverPinWithSecretPhraseRouter(): React.ReactElement {
  const [phrase, setPhrase] = useState<SafeWrapper<string>>();

  return (
    <Switch404>
      <Route exact path={userRoutes.forgotPin.enterPhrase}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Pin]}
        >
          <EnterSecretPhrase setPhrase={setPhrase} successTo={userRoutes.forgotPin.createPin} />
        </AccessControlData>
      </Route>

      <Route exact path={userRoutes.forgotPin.createPin}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Pin]}
        >
          <CreatePinRecover phrase={phrase} successTo={userRoutes.forgotPin.success} />
        </AccessControlData>
      </Route>

      <Route exact path={userRoutes.forgotPin.success}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Pin]}
        >
          <PinSuccessScene
            pageTitle="PIN Updated"
            sceneDescription="You can now use your new PIN to verify transactions for added security."
            sceneTitle="PIN updated successfully"
            successTo={walletRoutes().base}
          />
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
