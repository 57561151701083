import { useCallback } from 'react';
import { useSigning } from '@noah-labs/fe-shared-feature-signing';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import type { TpPhraseForm } from '@noah-labs/fe-shared-ui-signing';
import {
  IncorrectMnemonicError,
  InvalidMnemonicError,
  SafeWrapper,
} from '@noah-labs/shared-cryptography';
import type { UseFormSetError } from 'react-hook-form';
import { EnterSecretPhraseScene } from '../scenes/EnterSecretPhrase';

export type PpEnterSecretPhrase = {
  setPhrase: (phrase: SafeWrapper<string>) => void;
  successTo: string;
};

export function EnterSecretPhrase({
  setPhrase,
  successTo,
}: PpEnterSecretPhrase): React.ReactElement {
  const { push } = useRouter();
  const { validateSecretPhrase } = useSigning();

  /**
   * Validates and persist the phrase in state
   */
  const onSubmit = useCallback(
    async ({ phrase }: TpPhraseForm, setError: UseFormSetError<TpPhraseForm>): Promise<void> => {
      try {
        const wp = new SafeWrapper(phrase.trim().toLowerCase());
        await validateSecretPhrase(wp);
        setPhrase(wp);
      } catch (err) {
        switch (true) {
          case err instanceof IncorrectMnemonicError:
          case err instanceof InvalidMnemonicError:
            setError('phrase', { message: (err as Error).message });
            return;
          default:
            return;
        }
      }

      push(successTo);
    },
    [push, setPhrase, validateSecretPhrase, successTo],
  );

  return <EnterSecretPhraseScene onSubmit={onSubmit} />;
}
