import { styled, useTheme } from '@mui/material/styles';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { AppContainer, AppLogo, AppMain, useDesktop } from '@noah-labs/fe-shared-ui-components';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../../components/layout/AppHeaderData';
import { NavBarData } from '../../../../components/navigation/NavBarData';
import { SidebarNavData } from '../../../../components/navigation/SidebarNavData';
import { Bitrefill as BitrefillIframe } from '../components/Bitrefill';

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
}));

export function BitrefillScene(): React.ReactElement {
  const { data: userData } = useUserInitUi();
  const theme = useTheme();

  const isDesktop = useDesktop();

  return (
    <AppContainer BottomNavbarSlot={<NavBarData />} SideNavbarSlot={<SidebarNavData />}>
      <Helmet>
        <title>Bitrefill</title>
      </Helmet>
      <AppMain
        paddingBottom={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <AppHeaderData
          avatarButton
          disableBalancing
          disableFade
          helpButton
          notificationCenterButton
          backgroundColor={theme.palette.grayscale.offWhite}
          fullWidth={isDesktop}
        >
          {!isDesktop && <AppLogo />}
        </AppHeaderData>
        <StyledDiv data-qa="bitrefill-scene">
          <BitrefillIframe
            cryptoUnit={userData?.userProfile.DisplayUnit}
            email={userData?.userProfile.Email}
            fiatCurrency={userData?.userProfile.fiatCurrency}
          />
        </StyledDiv>
      </AppMain>
    </AppContainer>
  );
}
