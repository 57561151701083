import { useCallback, useRef } from 'react';
import type {
  TpPayoutSignable,
  TpWithdrawOrderSignable,
} from '@noah-labs/fe-shared-data-access-signing';
import { useUserLimitsQuery } from '@noah-labs/fe-shared-data-access-user';
import { useDesktop, useDialogs } from '@noah-labs/fe-shared-ui-components';
import type { Network } from '@noah-labs/shared-schema-gql';
import { SignController } from '../controllers/SignController';
import type { FnOnSign, TpOnSign } from '../types';
import { canSkipSignature } from '../utils/policy';
import { useSigning } from './useSigning';

const SIGN_DIALOG_KEY = 'sign';

type TpSignWithdrawal = {
  loading: boolean;
  sign: (onSign: FnOnSign) => Promise<void>;
};

export function useSignWithdrawal({
  network,
  payload,
}: {
  network?: Network | null;
  payload: TpWithdrawOrderSignable | TpPayoutSignable;
}): TpSignWithdrawal {
  const { closeDialog, pushDialog } = useDialogs();
  const { sdStatus } = useSigning();
  const isDesktop = useDesktop();

  const { data: userLimitsData, isFetched } = useUserLimitsQuery({
    currencyCodes: [payload.CurrencyCode],
  });

  const payloadRef = useRef(payload);
  payloadRef.current = payload;

  return {
    loading: !isFetched,
    sign: useCallback(
      async (onSign) => {
        // This should never happen if the consumer of this hook checks the loading state
        if (!userLimitsData) {
          throw Error('userLimitsData is undefined');
        }

        /**
         * IF the withdraw request satisfies the signing limits, we can skip the signature
         */
        if (
          canSkipSignature({
            amount: payloadRef.current.Amount,
            currency: payloadRef.current.CurrencyCode,
            network,
            policies: userLimitsData.userLimit.Limits,
          })
        ) {
          await onSign({});
          return;
        }

        /**
         * For now, we can just proceed with the withdraw if the user has not setup a pin
         */
        if (sdStatus?.pinSetupRequired) {
          await onSign({});
          return;
        }

        pushDialog({
          Content: (
            <SignController
              payload={payloadRef.current}
              onSign={async (signPayload: TpOnSign): Promise<void> => {
                await onSign(signPayload);
                closeDialog(SIGN_DIALOG_KEY);
              }}
            />
          ),
          DialogProps: {
            fullScreen: !isDesktop,
          },
          key: SIGN_DIALOG_KEY,
          low: isDesktop,
        });
      },
      [pushDialog, closeDialog, userLimitsData, network, sdStatus?.pinSetupRequired, isDesktop],
    ),
  };
}
