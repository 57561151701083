import type { ReactElement } from 'react';
import { CircleTickIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CircleTickIcon';
import {
  AppContainer,
  AppFooter,
  AppMain,
  PrimaryButton,
  SceneIcon,
  SceneMain,
  SceneParagraph,
  SceneTitleLarge,
} from '@noah-labs/fe-shared-ui-components';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';

export type PpPinSuccessScene = {
  pageTitle: string;
  sceneDescription: string;
  sceneTitle: string;
  successTo: string;
};

export function PinSuccessScene({
  pageTitle,
  sceneDescription = 'You can now use your new PIN to verify transactions for added security.',
  sceneTitle,
  successTo,
}: PpPinSuccessScene): ReactElement {
  return (
    <AppContainer>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <AppMain>
        <AppHeaderData helpButton />
        <SceneMain dataQa="pin-success">
          <SceneIcon>
            <CircleTickIcon color="success" />
          </SceneIcon>
          <SceneTitleLarge>{sceneTitle}</SceneTitleLarge>
          <SceneParagraph>{sceneDescription}</SceneParagraph>
        </SceneMain>
      </AppMain>
      <AppFooter>
        <PrimaryButton data-qa="done-btn" href={successTo}>
          Done
        </PrimaryButton>
      </AppFooter>
    </AppContainer>
  );
}
