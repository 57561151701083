import React from 'react';
import { generatePath } from '@noah-labs/fe-shared-ui-components';
import { cryptoCurrencyFromCode, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { CurrentBalanceData } from '../../components/balance/CurrentBalanceData';
import { ActionsMenuData } from '../../components/menus/ActionsMenuData';
import { TransactionsData } from '../../components/transactions/TransactionsData';
import { AccountScene } from '../../scenes/Account';
import { getReceiveCryptoRoute } from '../../utils/getReceiveCryptoRoute';

export function Account(): React.ReactElement {
  const { CurrencyCode, params } = useWalletParams();
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);

  const receivePath = getReceiveCryptoRoute(cryptoCurrency.requireNetworkSelection.receive);

  return (
    <AccountScene
      ActionsMenuSlot={
        <ActionsMenuData
          customRoutes={{
            receive: generatePath(receivePath, params),
            send: generatePath(walletRoutes().address.text, params),
          }}
        />
      }
      BalanceSlot={<CurrentBalanceData />}
      cryptoCurrency={cryptoCurrency}
      TransactionsSlot={<TransactionsData />}
    />
  );
}
