import type { ReactElement } from 'react';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import type {
  TpPayloadSignature,
  TpUserLimitSignable,
} from '@noah-labs/fe-shared-data-access-signing';
import {
  type UserLimitsQuery,
  useUpdateUserLimitsMutation,
  useUserLimitsQuery,
} from '@noah-labs/fe-shared-data-access-user';
import {
  getSigningLimits,
  isPinAlwaysRequiredForTransactions,
} from '@noah-labs/fe-shared-data-access-wallet';
import type { TpSigningLimits } from '@noah-labs/fe-shared-data-access-wallet';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import {
  LoadingPage,
  useDesktop,
  useDialogs,
  usePushAlert,
} from '@noah-labs/fe-shared-ui-components';
import type { DefaultValues } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { getDefaults } from '../../../utils/getDefaults';
import { TransactionPinThresholdUpdated } from '../components/alerts/Alerts';
import { useTransactionPinThresholdError } from '../hooks/usePinTransactionThresholdError';
import { TransactionThresholdScene } from '../scenes/TransactionPinThreshold';
import { getLimitsSignaturePayload } from '../utils/getLimitsSignaturePayload';
import { SignTransactionPinThresholdUpdateDialog } from './SignTransactionPinThresholdUpdateDialog';

export type TpTransactionPinThresholdForm = yup.InferType<typeof transactionPinThresholdSchema>;

type TpTransactionPinThresholdSubmitParams = {
  dailyLimitSignature: TpPayloadSignature;
  dailyLimitSignaturePayload: TpUserLimitSignable;
  singleTransactionLimitSignature: TpPayloadSignature;
  singleTransactionLimitSignaturePayload: TpUserLimitSignable;
  userId: string;
};

const { cryptoCurrency } = getDefaults();

const signingLimitInput = yup.string().when('transactionPinThreshold', {
  is: 'You decide when we ask for your PIN',
  then: (schema) => schema.required('Please enter a custom amount.'),
});

const transactionPinThresholdSchema = yup.object({
  dailyLimit: signingLimitInput,
  singleTransactionLimit: signingLimitInput,
  transactionPinThreshold: yup
    .mixed()
    .oneOf(['You decide when we ask for your PIN', "You'll be asked to verify all transactions"]),
});

const transactionPinThresholFormDefaultValues: DefaultValues<TpTransactionPinThresholdForm> = {
  transactionPinThreshold: "You'll be asked to verify all transactions",
};

const PIN_DIALOG_KEY = 'sign-transaction-pin-threshold-update';

export function TransactionPinThreshold(): ReactElement | null {
  const { data: userData } = useUserInitUi();
  const isDesktop = useDesktop();

  const pushAlert = usePushAlert();

  const { closeDialog, pushDialog } = useDialogs();

  const methods = useForm({
    defaultValues: transactionPinThresholFormDefaultValues,
    resolver: yupResolver<TpTransactionPinThresholdForm>(transactionPinThresholdSchema),
  });

  const {
    data: userLimits,
    error: getUserLimitsError,
    isFetching,
  } = useUserLimitsQuery(
    {
      currencyCodes: [cryptoCurrency.code],
    },
    {
      refetchOnWindowFocus: false,
      select({ userLimit: { Limits } }: UserLimitsQuery): TpSigningLimits {
        return getSigningLimits(Limits);
      },
    },
  );

  const { error: updateUserLimitsError, mutateAsync: updateUserLimits } =
    useUpdateUserLimitsMutation();

  useTransactionPinThresholdError(updateUserLimitsError || getUserLimitsError);

  async function handleSubmit({
    dailyLimitSignature,
    dailyLimitSignaturePayload,
    singleTransactionLimitSignature,
    singleTransactionLimitSignaturePayload,
  }: TpTransactionPinThresholdSubmitParams): Promise<void> {
    try {
      await updateUserLimits({
        Input: {
          SigningDayLimit: {
            FiatAmount: {
              Amount: dailyLimitSignaturePayload.FiatAmount,
              FiatCurrency: dailyLimitSignaturePayload.FiatCurrencyCode,
            },
            LimitType: dailyLimitSignaturePayload.LimitType,
            Period: dailyLimitSignaturePayload.Period,
            Signature: dailyLimitSignature.signature,
          },
          SigningSingleLimit: {
            FiatAmount: {
              Amount: singleTransactionLimitSignaturePayload.FiatAmount,
              FiatCurrency: singleTransactionLimitSignaturePayload.FiatCurrencyCode,
            },
            LimitType: singleTransactionLimitSignaturePayload.LimitType,
            Period: singleTransactionLimitSignaturePayload.Period,
            Signature: singleTransactionLimitSignature.signature,
          },
        },
      });
      pushAlert(TransactionPinThresholdUpdated);
    } finally {
      closeDialog(PIN_DIALOG_KEY);
    }
  }

  async function promptForPin(data: TpTransactionPinThresholdForm): Promise<void> {
    if (!userData) {
      return;
    }

    const limitsSignaturePayload = getLimitsSignaturePayload(data);

    pushDialog({
      Content: (
        <SignTransactionPinThresholdUpdateDialog
          {...limitsSignaturePayload}
          onSignSuccess={(signatures): Promise<void> =>
            handleSubmit({
              ...signatures,
              ...limitsSignaturePayload,
              userId: userData.userProfile.UserID,
            })
          }
        />
      ),
      DialogProps: {
        fullScreen: !isDesktop,
      },
      key: PIN_DIALOG_KEY,
      low: isDesktop,
    });
  }

  useEffect(() => {
    if (!userLimits) {
      return;
    }

    if (isPinAlwaysRequiredForTransactions(userLimits)) {
      methods.reset(transactionPinThresholFormDefaultValues);

      return;
    }

    methods.reset({
      dailyLimit: userLimits.dailySigningLimit?.VolumeLimit?.Signing?.Amount,
      singleTransactionLimit: userLimits.singleSigningLimit?.VolumeLimit?.Signing?.Amount,
      transactionPinThreshold: 'You decide when we ask for your PIN',
    });
  }, [userLimits, methods]);

  if (isFetching) {
    return <LoadingPage />;
  }

  const customLimitInputsAreDisabled =
    methods.watch('transactionPinThreshold') === "You'll be asked to verify all transactions";

  return (
    <FormProvider {...methods}>
      <TransactionThresholdScene
        customLimitInputsAreDisabled={customLimitInputsAreDisabled}
        onSubmit={(): Promise<void> => methods.handleSubmit(promptForPin)()}
      />
    </FormProvider>
  );
}
