import type { ReactElement } from 'react';
import { cryptoCurrencyFromCode, getTransactionType } from '@noah-labs/fe-shared-ui-shared';
import { AvatarWithTransactionStatus } from '../../wallet/components/transactions/AvatarWithTransactionStatus';
import type { NotificationPayload } from '../types';
import {
  isNewReferralNotification,
  isQuestCompletedNotification,
  isTransactionNotification,
} from '../utils/checkNotificationType';
import { MarketingNotificationIcon } from './MarketingNotificationIcon';
import { NewReferralNotificationIcon } from './NewReferralNotificationIcon';
import { QuestCompletedNotificationIcon } from './QuestCompletedNotificationIcon';

type PpNotificationIcon = {
  payload?: NotificationPayload;
};

const defaultIcon = <MarketingNotificationIcon />;

export function NotificationIcon({ payload }: PpNotificationIcon): ReactElement {
  if (!payload?.type) {
    return defaultIcon;
  }

  if (isTransactionNotification(payload)) {
    const transactionType = getTransactionType(
      payload.sourceType === 'Checkout',
      payload.direction,
    );

    return (
      <AvatarWithTransactionStatus
        avatarSize={4}
        badgeSize={2}
        svg={cryptoCurrencyFromCode(payload.currency).svg}
        transactionStatus={payload.status}
        transactionType={transactionType}
      />
    );
  }

  if (isNewReferralNotification(payload)) {
    return <NewReferralNotificationIcon />;
  }

  if (isQuestCompletedNotification(payload)) {
    return <QuestCompletedNotificationIcon badgeId={payload.badgeId} />;
  }

  return defaultIcon;
}
