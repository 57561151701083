import React from 'react';
import { useTheme } from '@mui/material/styles';
import { ChevronRightIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ChevronRightIcon';
import {
  AppContainer,
  AppHeaderTitle,
  AppMain,
  generatePath,
  List,
  ListItem,
  ListItemButton,
  ListItemCard,
  ListItemContent,
  ListItemEndContent,
  ListItemPrimaryText,
  ListItemStartContent,
  SceneMain,
} from '@noah-labs/fe-shared-ui-components';
import type { TpPaymentCard } from '@noah-labs/fe-shared-ui-shared';
import { TpPaymentMethod } from '@noah-labs/fe-shared-ui-shared';
import { PaymentMethod } from '@noah-labs/fe-shared-ui-wallet';
import { userRoutes } from '@noah-labs/fe-shared-util-routes';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';
import type { TpBackAction } from '../../../hooks/useBackHijack';

export type PpPaymentMethodsScene = {
  pageTitle: string;
  paymentCards: TpPaymentCard[];
} & TpBackAction;

export function PaymentMethodsScene({
  backTo,
  goBack,
  pageTitle,
  paymentCards,
}: PpPaymentMethodsScene): React.ReactElement {
  const theme = useTheme();

  return (
    <AppContainer>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <AppMain>
        <AppHeaderData helpButton backButton={goBack} backTo={backTo}>
          <AppHeaderTitle>{pageTitle}</AppHeaderTitle>
        </AppHeaderData>
        <SceneMain dense dataQa="payment-methods">
          <List disablePadding spacing={1}>
            {paymentCards.map((paymentCard) => (
              <ListItem key={paymentCard.id}>
                <ListItemButton
                  href={generatePath(userRoutes.settings.editPaymentMethod, { id: paymentCard.id })}
                >
                  <ListItemCard>
                    <PaymentMethod
                      method={TpPaymentMethod.Card}
                      paymentCard={paymentCard}
                      ToggleSlot={<ChevronRightIcon sx={{ color: theme.palette.text.light }} />}
                    />
                  </ListItemCard>
                </ListItemButton>
              </ListItem>
            ))}
            <ListItem data-qa="add-payment-method">
              <ListItemButton href={userRoutes.settings.newPaymentMethod}>
                <ListItemCard>
                  <ListItemContent>
                    <ListItemStartContent>
                      <ListItemPrimaryText color="text.link">
                        Add a payment method
                      </ListItemPrimaryText>
                    </ListItemStartContent>
                    <ListItemEndContent>
                      <ChevronRightIcon sx={{ color: theme.palette.text.light }} />
                    </ListItemEndContent>
                  </ListItemContent>
                </ListItemCard>
              </ListItemButton>
            </ListItem>
          </List>
        </SceneMain>
      </AppMain>
    </AppContainer>
  );
}
