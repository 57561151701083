import { TransactionDirection } from '@noah-labs/shared-schema-gql';
import { TpTransactionType } from '../types';

export function getTransactionType(isCheckout: boolean, direction: undefined | null): undefined;
export function getTransactionType(
  isCheckout: boolean,
  direction: TransactionDirection,
): TpTransactionType;
export function getTransactionType(
  isCheckout: boolean,
  direction?: TransactionDirection | null,
): TpTransactionType | undefined;
export function getTransactionType(
  isCheckout: boolean,
  direction?: TransactionDirection | null,
): TpTransactionType | undefined {
  if (!direction) {
    return undefined;
  }

  if (isCheckout) {
    return {
      [TransactionDirection.In]: TpTransactionType.Buy,
      [TransactionDirection.Out]: TpTransactionType.Sell,
    }[direction];
  }

  return {
    [TransactionDirection.In]: TpTransactionType.Deposit,
    [TransactionDirection.Out]: TpTransactionType.Withdraw,
  }[direction];
}
