import { pinLength } from '@noah-labs/shared-cryptography';
import { compareStrings } from '@noah-labs/shared-util-vanilla';
import type { TestFunction } from 'yup';
import * as yup from 'yup';
import type { TpPinForm } from '../types';

type PinTest = {
  message: string;
  test: TestFunction<string | undefined>;
};

function threeConsecutive(value: string | undefined): boolean {
  if (typeof value !== 'string') {
    return false;
  }
  for (let i = 0; i < value.length - 2; i += 1) {
    const first = parseInt(value[i], 10);
    const second = parseInt(value[i + 1], 10);
    const third = parseInt(value[i + 2], 10);
    if (first + 1 === second && second + 1 === third) {
      return false;
    }
  }
  return true;
}

function threeIdentical(value: string | undefined): boolean {
  if (typeof value !== 'string') {
    return false;
  }
  for (let i = 0; i < value.length - 2; i += 1) {
    const first = value[i];
    const second = value[i + 1];
    const third = value[i + 2];
    if (first === second && second === third) {
      return false;
    }
  }
  return true;
}

function threeConsecutivePairs(value: string | undefined): boolean {
  if (typeof value !== 'string') {
    return false;
  }
  let count = 0;
  for (let i = 0; i < value.length - 1; i += 1) {
    const first = parseInt(value[i], 10);
    const second = parseInt(value[i + 1], 10);
    if (first + 1 === second) {
      count += 1;
    }
  }

  if (count > 2) {
    return false;
  }
  return true;
}

export const pinValidation: Record<string, PinTest> = {
  threeConsecutive: {
    message: 'PIN cannot contain three consecutive numbers in a row.',
    test: threeConsecutive,
  },
  threeConsecutivePairs: {
    message: 'PIN cannot contain three occurences of consecutive numbers.',
    test: threeConsecutivePairs,
  },
  threeIdentical: {
    message: 'PIN cannot contain three identical numbers in a row.',
    test: threeIdentical,
  },
};

export const verifyPinSchema = yup.object({
  pin: yup.string().length(pinLength, 'Enter your 6-digit PIN'),
});

export function createPinSchema(
  unconfirmedPin: string | null,
): yup.ObjectSchema<Partial<TpPinForm>> {
  let pinSchema = yup
    .string()
    .test({
      message: pinValidation.threeConsecutive.message,
      test: pinValidation.threeConsecutive.test,
    })
    .test({
      message: pinValidation.threeIdentical.message,
      test: pinValidation.threeIdentical.test,
    })
    .test({
      message: pinValidation.threeConsecutivePairs.message,
      test: pinValidation.threeConsecutivePairs.test,
    });

  if (unconfirmedPin) {
    pinSchema = pinSchema.length(pinLength, 'Confirm your 6-digit PIN').test({
      message: 'PINs do not match',
      test: (value) => compareStrings(value, unconfirmedPin),
    });
  } else {
    pinSchema = pinSchema.length(pinLength, 'Enter your 6-digit PIN');
  }

  return yup.object({
    pin: pinSchema,
  });
}

export const phraseSchema = yup.object({
  phrase: yup
    .string()
    .required('Phrase is required')
    .test({
      message: 'Phrase should be 24 words long',
      test: (value) => {
        const words = value.trim().split(/\s+/);
        return words.length === 24;
      },
    }),
});
