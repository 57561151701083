import type { AccountType, CurrencyCode } from '@noah-labs/shared-schema-gql';
import { capitalizeFirstLetter } from '@noah-labs/shared-util-vanilla';
import { useRouter } from '../routing/useRouter';

type TpWalletRouterLowerParams = {
  accountType: Lowercase<AccountType> | '';
  currencyCode: Lowercase<CurrencyCode> | '';
};

export type TpNormalisedParams = {
  AccountType: AccountType;
  CurrencyCode: CurrencyCode;
  params: TpWalletRouterLowerParams;
};

type PpUseWallet = {
  accountType: AccountType | '';
  currencyCode: CurrencyCode | '';
};

const emptyWalletParams: PpUseWallet = { accountType: '', currencyCode: '' };

export function useWalletParams(fallback: PpUseWallet = emptyWalletParams): TpNormalisedParams {
  const { useParams } = useRouter();
  const params = {
    ...fallback,
    ...useParams(),
  };

  const at = params.accountType.toLowerCase();
  const cc = params.currencyCode.toLowerCase();

  const AT = capitalizeFirstLetter(at);
  const CC = params.currencyCode.toUpperCase();

  return {
    AccountType: AT as AccountType,
    CurrencyCode: CC as CurrencyCode,
    params: {
      accountType: at as Lowercase<AccountType>,
      currencyCode: cc as Lowercase<CurrencyCode>,
    },
  };
}
