import React, { useEffect, useState } from 'react';
import {
  getErrorType,
  isAppSyncError,
  isGraphqlError,
} from '@noah-labs/fe-shared-data-access-shared';
import {
  AlertLink,
  ApiUnknown,
  ErrorPage,
  generatePath,
  PrimaryButton,
  usePushAlert,
} from '@noah-labs/fe-shared-ui-components';
import type { TpUseError } from '@noah-labs/fe-shared-ui-shared';
import { getFeError, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { authRoutes, userRoutes, walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { logger } from '@noah-labs/shared-logger/browser';
import { NoahErrorType } from '@noah-labs/shared-schema-gql';
import { LightningError } from '../errors';

export function useWalletError(error: unknown, showFullPageError?: boolean): TpUseError {
  const [apiErrorScene, setApiErrorScene] = useState<React.ReactElement | null>(null);
  const pushAlert = usePushAlert();

  const { AccountType, CurrencyCode } = useWalletParams();

  useEffect(() => {
    if (!error) {
      setApiErrorScene(null);
      return;
    }

    logger.error(error);

    if (showFullPageError) {
      const detailedError = error as Error;
      let errorProps = getFeError();

      if (detailedError.name === LightningError.ErrorName) {
        errorProps = {
          helpText: detailedError.message,
          message: LightningError.Title,
        };
      }

      setApiErrorScene(<ErrorPage {...errorProps} />);
      return;
    }

    if (isGraphqlError(error)) {
      error.response.errors.forEach((innerError) => {
        if (!isAppSyncError(innerError) || !innerError.message) {
          return;
        }
        const errorType = getErrorType(innerError.message);
        const errorProps = getFeError(errorType);
        switch (errorType) {
          case NoahErrorType.InvalidEmail: {
            setApiErrorScene(
              <ErrorPage
                {...errorProps}
                FooterSlot={
                  <PrimaryButton color="primaryBrand" href={authRoutes.signIn}>
                    Login
                  </PrimaryButton>
                }
              />,
            );

            break;
          }
          case NoahErrorType.UnsupportedCurrency: {
            setApiErrorScene(<ErrorPage {...errorProps} />);
            break;
          }

          case NoahErrorType.TransactionDetailsMissing: {
            setApiErrorScene(
              <ErrorPage
                FooterSlot={
                  <PrimaryButton
                    color="primaryBrand"
                    href={generatePath(walletRoutes().account.base, {
                      accountType: AccountType,
                      currencyCode: CurrencyCode,
                    })}
                  >
                    Go to account
                  </PrimaryButton>
                }
                helpText="Please go to your account page and check for a pending transaction."
                message="The transaction details are missing"
              />,
            );

            break;
          }

          case NoahErrorType.PolicyViolation: {
            pushAlert({
              key: 'policyViolationError',
              message: (
                <React.Fragment>
                  You have reached your limit on this transaction.{' '}
                  <AlertLink href={userRoutes.settings.accountLimits}>Check your limits</AlertLink>
                </React.Fragment>
              ),
              severity: 'error',
            });
            setApiErrorScene(null);

            break;
          }

          default:
            pushAlert(ApiUnknown);
            break;
        }
      });

      return;
    }

    /**
     * If the error was not handled above, set to a generic error,
     * no need to show the user potentially confusing errors
     */
    pushAlert(ApiUnknown);
    setApiErrorScene(null);
  }, [AccountType, CurrencyCode, error, pushAlert, showFullPageError]);

  return { ApiErrorScene: apiErrorScene };
}
