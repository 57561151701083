import React from 'react';
import { Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  ElevatedCard,
  InfoTooltip,
  List,
  ListItem,
  ListItemContent,
  ListItemEndContent,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
  TextOrSkeleton,
} from '@noah-labs/fe-shared-ui-components';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-shared';

export type PpTransactionSummaryNew = {
  ActionAmount: React.ReactNode;
  CryptoPriceSlot: React.ReactElement;
  FeeAmountSlot: React.ReactNode;
  FinalAmount: React.ReactNode;
  actionAmountLabel: string;
  cryptoCurrency: TpCryptoCurrencyUI | undefined;
  feeLabel: string;
  feePromotion?: boolean;
  finalAmountLabel: string;
  onActionClick?: () => void;
  onFeeInfoClick?: () => void;
  onProcessingTimeClick?: () => void;
  processingTime?: string;
  sellReference?: string;
};

export function TransactionSummaryCard({
  ActionAmount,
  actionAmountLabel,
  cryptoCurrency,
  CryptoPriceSlot,
  FeeAmountSlot,
  feeLabel,
  feePromotion,
  FinalAmount,
  finalAmountLabel,
  onActionClick,
  onFeeInfoClick,
  onProcessingTimeClick,
  processingTime,
  sellReference,
}: PpTransactionSummaryNew): React.ReactElement {
  const theme = useTheme();

  return (
    <ElevatedCard sx={{ padding: theme.spacing(2, 2) }}>
      <List disablePadding spacing={1.5}>
        <ListItem data-qa="price">
          <ListItemContent padding={0}>
            <ListItemStartContent>
              <ListItemSecondaryText>
                <TextOrSkeleton>
                  {cryptoCurrency ? `${cryptoCurrency.code} Price` : undefined}
                </TextOrSkeleton>
              </ListItemSecondaryText>
            </ListItemStartContent>
            <ListItemEndContent>
              <ListItemPrimaryText>{CryptoPriceSlot}</ListItemPrimaryText>
            </ListItemEndContent>
          </ListItemContent>
        </ListItem>
        <Divider />
        <ListItem data-qa="fee">
          <ListItemContent padding={0}>
            <ListItemStartContent>
              <ListItemSecondaryText>
                <InfoTooltip onClick={onFeeInfoClick}>{feeLabel}</InfoTooltip>
              </ListItemSecondaryText>
            </ListItemStartContent>
            <ListItemEndContent>
              <ListItemPrimaryText {...(feePromotion && { color: 'brand.main' })}>
                {FeeAmountSlot}
              </ListItemPrimaryText>
            </ListItemEndContent>
          </ListItemContent>
        </ListItem>
        <Divider />
        <ListItem data-qa={actionAmountLabel.toLowerCase()}>
          <ListItemContent padding={0}>
            <ListItemStartContent>
              <ListItemSecondaryText>
                {onActionClick ? (
                  <InfoTooltip onClick={onActionClick}>{actionAmountLabel}</InfoTooltip>
                ) : (
                  actionAmountLabel
                )}
              </ListItemSecondaryText>
            </ListItemStartContent>
            <ListItemEndContent>
              <ListItemPrimaryText>{ActionAmount}</ListItemPrimaryText>
            </ListItemEndContent>
          </ListItemContent>
        </ListItem>
        <Divider />
        {processingTime && onProcessingTimeClick && (
          <React.Fragment>
            <ListItem data-qa="processingTime">
              <ListItemContent padding={0}>
                <ListItemStartContent>
                  <ListItemSecondaryText>
                    <InfoTooltip onClick={onProcessingTimeClick}>Processing Time</InfoTooltip>
                  </ListItemSecondaryText>
                </ListItemStartContent>
                <ListItemEndContent>
                  <ListItemPrimaryText>{processingTime}</ListItemPrimaryText>
                </ListItemEndContent>
              </ListItemContent>
            </ListItem>
            <Divider />
          </React.Fragment>
        )}
        {sellReference && (
          <React.Fragment>
            <ListItem data-qa="reference">
              <ListItemContent padding={0}>
                <ListItemStartContent>
                  <ListItemSecondaryText>Reference</ListItemSecondaryText>
                </ListItemStartContent>
                <ListItemEndContent paddingLeft={3}>
                  <ListItemPrimaryText>{sellReference}</ListItemPrimaryText>
                </ListItemEndContent>
              </ListItemContent>
            </ListItem>
            <Divider />
          </React.Fragment>
        )}
        <ListItem data-qa="total">
          <ListItemContent padding={0}>
            <ListItemStartContent>
              <ListItemPrimaryText>{finalAmountLabel}</ListItemPrimaryText>
            </ListItemStartContent>
            <ListItemEndContent>
              <ListItemPrimaryText>{FinalAmount}</ListItemPrimaryText>
            </ListItemEndContent>
          </ListItemContent>
        </ListItem>
      </List>
    </ElevatedCard>
  );
}
