import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { CurrencyDisplayType, CurrencyUnit } from '@noah-labs/shared-schema-gql';
import { getDefaults } from '../../../utils/getDefaults';
import { getReferralUrl } from '../../../utils/utils';
import { useKycApprovalStatusData } from '../data/useKycApprovalStatusData';
import { SettingsScene } from '../scenes/Settings';

const { cryptoCurrency } = getDefaults();

export function Settings(): React.ReactElement {
  const { data: userData } = useUserInitUi();
  const kycApprovalStatusData = useKycApprovalStatusData(
    userData?.userProfile.KycVerification.ApprovalStatus,
  );

  let btcUnit;
  switch (userData?.userProfile.DisplayUnit) {
    case CurrencyUnit.SATS:
      btcUnit = 'Satoshi';
      break;

    case undefined:
      break;

    default:
      btcUnit = cryptoCurrency.label;
  }

  let primaryCurrency;
  switch (userData?.userProfile.PrimaryCurrency) {
    case CurrencyDisplayType.Crypto:
      primaryCurrency = cryptoCurrency;
      break;

    case undefined:
      break;

    default:
      primaryCurrency = userData?.userProfile.fiatCurrency;
  }

  return (
    <SettingsScene
      avatarAlt={userData?.userProfile.Email}
      avatarContent={userData?.userProfile.userInitial}
      backTo={walletRoutes().base}
      btcUnit={btcUnit}
      displayCurrency={userData?.userProfile.fiatCurrency}
      email={userData?.userProfile.Email}
      kycApprovalStatusData={kycApprovalStatusData}
      lnAddress={userData?.userProfile.lnAddress}
      pageTitle="Settings"
      paymentCurrency={userData?.userProfile.fiatPaymentCurrency}
      primaryCurrency={primaryCurrency}
      referralUrl={getReferralUrl(userData?.userProfile.UsernameDisplay)}
      usernameDisplay={userData?.userProfile.UsernameDisplay}
    />
  );
}
