import { useCallback, useEffect, useState } from 'react';
import { disableRefetchRetry } from '@noah-labs/fe-shared-data-access-shared';
import { useLnAddressProxyQuery } from '@noah-labs/fe-shared-data-access-wallet';
import { useFeature } from '@noah-labs/fe-shared-feature-user';
import {
  ApiUnknown,
  generatePath,
  LnurlErrorAlert,
  parseMarketingUrl,
  usePushAlert,
} from '@noah-labs/fe-shared-ui-components';
import type {
  TpAddressData,
  TpLightningAddressData,
} from '@noah-labs/fe-shared-ui-components/crypto';
import { useRouter, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import type { CurrencyCode } from '@noah-labs/shared-schema-gql';
import { Feature } from '@noah-labs/shared-schema-gql';
import { getErrorMessage } from '@noah-labs/shared-util-vanilla';
import type { UseFormSetError } from 'react-hook-form';
import { isProd } from '../../../../webConfigBrowser';
import { useAddress } from '../../hooks/useAddress';
import { useAddressError } from '../../hooks/useAddressError';
import { useRecentAddresses } from '../../hooks/useRecentAddresses';
import type { TpAddressForm } from '../../scenes/AddressManual';
import { AddressManualScene } from '../../scenes/AddressManual';
import { handleParseAddress, isAddressCustomError } from '../../utils/address';
import { getNextUrl } from '../../utils/getNextUrl';

type PpAddressManual = {
  scannedAddress: string;
};

export function AddressManual({ scannedAddress }: PpAddressManual): React.ReactElement {
  const { feature: withdrawFF } = useFeature(Feature.Withdraw);
  const { CurrencyCode, params } = useWalletParams();
  const { push } = useRouter();
  const pushAlert = usePushAlert();
  const [lnData, setLnData] = useState<TpAddressData | undefined>();

  const { data, scannerUnavailable, setData } = useAddress();
  const recentPayees = useRecentAddresses();

  const address = lnData?.address;
  const lnUrlLink = lnData?.lnUrlLink;
  const addressType = lnData?.addressType;

  const isLnAddress = addressType === 'lnaddress';
  const isLnUrl = addressType === 'lnurl';

  const {
    data: lnAddressData,
    error: lnAddressError,
    isFetching: isLnAddressFetching,
  } = useLnAddressProxyQuery(
    {
      Input: {
        LightningAddress: isLnAddress ? address : undefined,
        LnurlLink: lnUrlLink,
      },
    },
    {
      enabled: isLnAddress || isLnUrl,
      ...disableRefetchRetry,
    },
  );

  useEffect(() => {
    if (!isLnAddressFetching) {
      return;
    }
    setData(undefined);
  }, [isLnAddressFetching, setData]);

  useEffect(
    () => () => {
      setData(undefined);
    },
    [setData],
  );

  useEffect(() => {
    if (!lnAddressData) {
      return;
    }

    if (!lnData) {
      return;
    }

    switch (lnAddressData.lightningAddressProxy.__typename) {
      case 'LightningAddressProxySuccess': {
        // safe to do a cast here because we know it's a ln address or lnurl at this point
        const currentlnData = lnData as TpLightningAddressData;
        const lnAddressProxy = {
          ...currentlnData,
          lightningAddressProxy: lnAddressData.lightningAddressProxy,
        };
        setData(lnAddressProxy);
        break;
      }

      case 'LnurlError':
        setData(undefined);
        pushAlert(LnurlErrorAlert(lnAddressData.lightningAddressProxy));
        break;

      default:
        pushAlert(ApiUnknown);
    }
  }, [lnAddressData, lnData, pushAlert, setData]);

  const handleAddress = useCallback(
    (
      addressInput: string,
      currencyCode: CurrencyCode,
      setError: UseFormSetError<TpAddressForm>,
    ) => {
      try {
        if (!withdrawFF?.Networks) {
          return;
        }

        if (!addressInput) {
          setData(undefined);
          return;
        }

        const marketingUrl = parseMarketingUrl(addressInput);
        if (marketingUrl) {
          push(marketingUrl);
          return;
        }

        const { addressData, isLnAddressOrLnUrl } = handleParseAddress({
          address: addressInput,
          availableNetworks: withdrawFF.Networks,
          currencyCode,
          isProd,
        });

        if (!isLnAddressOrLnUrl) {
          setData(addressData);
          return;
        }

        setLnData(addressData);
      } catch (err) {
        setData(undefined);

        const error = getErrorMessage(err);

        if (isAddressCustomError(error)) {
          setError('address', {
            type: error,
          });
          return;
        }

        pushAlert({
          dismissable: true,
          key: 'addressError',
          message: getErrorMessage(error),
          preventDuplicate: true,
          severity: 'error',
        });
      }
    },
    [push, withdrawFF?.Networks, pushAlert, setData, setLnData],
  );

  const handleNextUrl = useCallback(() => {
    if (!data) {
      return;
    }
    const nextUrl = getNextUrl(data);
    push(nextUrl);
  }, [push, data]);

  const handleRedirectToScan = useCallback(() => {
    push(generatePath(walletRoutes().address.scan, params));
  }, [push, params]);

  const { ApiErrorScene } = useAddressError(lnAddressError);

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <AddressManualScene
      addressData={data}
      currencyCode={CurrencyCode}
      handleAddress={handleAddress}
      handleNextUrl={handleNextUrl}
      handleRedirectToScan={handleRedirectToScan}
      isLoading={isLnAddressFetching}
      recentAddresses={recentPayees}
      scannedAddress={scannedAddress}
      scannerUnavailable={scannerUnavailable}
    />
  );
}
