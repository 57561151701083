import { CryptoAmount } from '@noah-labs/fe-shared-ui-components';
import { cryptoCurrencyFromCode } from '@noah-labs/fe-shared-ui-shared';
import type { TpFiatCurrency } from '@noah-labs/shared-currencies';
import { CurrencyUnit } from '@noah-labs/shared-schema-gql';
import type { FiatAmount as TpFiatAmount } from '@noah-labs/shared-schema-gql';
import { isUndefinedOrNull, safeBN } from '@noah-labs/shared-util-vanilla';
import * as yup from 'yup';
import { maxSafetyAmount } from './amounts';
import type { TpAmountForm } from './types';

type TpGetLnReceiveSchema = {
  fiatCurrency: TpFiatCurrency | undefined;
  maxLnSingleSendFiat: TpFiatAmount | undefined | null;
};
export function getLnReceiveSchema({
  fiatCurrency,
  maxLnSingleSendFiat,
}: TpGetLnReceiveSchema): yup.ObjectSchema<Partial<TpAmountForm>> {
  return yup.object<Partial<TpAmountForm>>({
    cryptoAmount: yup.lazy(() => {
      if (
        !isUndefinedOrNull(maxLnSingleSendFiat) &&
        !isUndefinedOrNull(maxLnSingleSendFiat.CryptoAmounts)
      ) {
        const cryptoAmount = maxLnSingleSendFiat.CryptoAmounts[0];
        const AmountSlot = (
          <CryptoAmount
            amount={cryptoAmount.Amount}
            currency={cryptoCurrencyFromCode(cryptoAmount.Currency)}
            currencyUnit={CurrencyUnit.Default}
          />
        );

        return yup.string().test({
          message: () => (
            <span>
              Maximum amount for a single lightning transaction is {AmountSlot}. Consider using an
              onchain transfer
            </span>
          ),
          test: (value) => safeBN(value).lte(cryptoAmount.Amount),
        });
      }

      return yup.string();
    }),
    fiatAmount: maxSafetyAmount(fiatCurrency),
  });
}
