import { mockUSD } from '@noah-labs/fe-shared-ui-components';
import { cryptoCurrencyFromCode } from '@noah-labs/fe-shared-ui-shared';
import type { TpCryptoCurrencyUI, TpFiatCurrencyUI } from '@noah-labs/fe-shared-ui-shared';
import { AccountType, AppStages, CurrencyCode } from '@noah-labs/shared-schema-gql';
import { webConfigBrowser } from '../webConfigBrowser';

type TpDefaults = {
  accountType: AccountType;
  cryptoCurrency: TpCryptoCurrencyUI;
  fiatCurrency: TpFiatCurrencyUI;
};

export function getDefaults(): TpDefaults {
  switch (webConfigBrowser.appStage) {
    case AppStages.Prod:
      return {
        accountType: AccountType.Current,
        cryptoCurrency: cryptoCurrencyFromCode(CurrencyCode.BTC),
        // TODO (cs): remove defaults
        fiatCurrency: mockUSD,
      };

    default:
      return {
        accountType: AccountType.Current,
        cryptoCurrency: cryptoCurrencyFromCode(CurrencyCode.BTC_TEST),
        // TODO (cs): remove defaults
        fiatCurrency: mockUSD,
      };
  }
}
