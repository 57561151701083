import type { ReactElement } from 'react';
import React from 'react';
import { css } from '@emotion/react';
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import { cssMixins } from '@noah-labs/fe-shared-ui-components';
import type { FieldError } from 'react-hook-form';

type PpCheckoutField = {
  FieldSlot: React.ElementType;
  error?: FieldError;
  focused: boolean;
  label: string;
  required?: boolean;
};
export function CheckoutField({
  error,
  FieldSlot,
  focused,
  label,
  required = true,
}: PpCheckoutField): ReactElement {
  const styles = {
    ckoInput: css`
      ${cssMixins.stretchAll};
      position: absolute;
    `,
    container: css`
      position: relative;
    `,
  };

  return (
    <FormControl fullWidth error={Boolean(error)} focused={focused} required={required}>
      <InputLabel variant="outlined">{label}</InputLabel>
      <div css={styles.container}>
        {/* Use a disabled input here to ensure styling is consistent with no layout shifts on load */}
        <OutlinedInput fullWidth inputProps={{ 'aria-hidden': true, disabled: true }} />
        <FieldSlot css={styles.ckoInput} />
      </div>
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
}
