import type { TpUserWithdrawalAllowance } from '@noah-labs/fe-shared-data-access-user';
import { useUserLimitsQuery } from '@noah-labs/fe-shared-data-access-user';
import {
  selectFromFilterWithFallback,
  useAccountsQueryPoll,
} from '@noah-labs/fe-shared-data-access-wallet';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-shared';
import type { AccountType, Network } from '@noah-labs/shared-schema-gql';
import { processAllowances } from '../allowances';

type PpUseUserWithdrawalAllowance = {
  accountType: AccountType | undefined;
  cryptoCurrency: TpCryptoCurrencyUI | undefined;
  network?: Network;
};
export function useUserWithdrawalAllowance({
  accountType,
  cryptoCurrency,
  network,
}: PpUseUserWithdrawalAllowance): TpUserWithdrawalAllowance | undefined {
  const { data: account } = useAccountsQueryPoll(undefined, {
    select: (data) => {
      if (!accountType || !cryptoCurrency) {
        return undefined;
      }
      return selectFromFilterWithFallback({
        AccountType: accountType,
        CurrencyCode: cryptoCurrency.code,
        data,
      });
    },
  });
  const { data: withdrawalAllowance } = useUserLimitsQuery(
    { currencyCodes: cryptoCurrency ? [cryptoCurrency.code] : undefined },
    {
      enabled: !!account,
      select: (data): TpUserWithdrawalAllowance =>
        processAllowances(data, account?.Balance?.Available, network),
    },
  );

  return withdrawalAllowance;
}
