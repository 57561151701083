import { useMemo } from 'react';
import { useCountryFromCode } from '@noah-labs/fe-shared-data-access-countries';
import { useUserInitQuery } from '@noah-labs/fe-shared-data-access-user';
import type { TpBillingAddressSchema } from '@noah-labs/fe-shared-util-validation-schemas';

export type TpBillingAddressForm = TpBillingAddressSchema & {
  CountryName: string;
};

export function useUserDefaultBillingAddress(): TpBillingAddressForm | undefined {
  const { data: userData } = useUserInitQuery();
  const country = useCountryFromCode(userData?.userProfile.PrimaryAddress?.Country);
  return useMemo(() => {
    if (!userData?.userProfile.PrimaryAddress || !country) {
      return undefined;
    }

    return {
      ...userData.userProfile.PrimaryAddress,
      City: userData.userProfile.PrimaryAddress.City || '',
      Country: userData.userProfile.PrimaryAddress.Country as string,
      CountryName: country.name,
      PostCode: userData.userProfile.PrimaryAddress.PostCode || '',
      State: userData.userProfile.PrimaryAddress.State || '',
      Street: userData.userProfile.PrimaryAddress.Street || '',
      Street2: userData.userProfile.PrimaryAddress.Street2 || '',
    };
  }, [country, userData?.userProfile.PrimaryAddress]);
}
