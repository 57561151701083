import { useCallback } from 'react';
import { useSigning } from '@noah-labs/fe-shared-feature-signing';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import type { TpPinForm } from '@noah-labs/fe-shared-ui-signing';
import { SafeWrapper } from '@noah-labs/shared-cryptography';
import { logger } from '@noah-labs/shared-logger/browser';
import { CreatePinScene } from '../scenes/CreatePin';

export type PpCreatePinRecover = {
  backTo?: string;
  exitTo?: string;
  pageTitle?: string;
  phrase?: SafeWrapper<string>;
  sceneConfirmTitle?: string;
  sceneTitle?: string;
  successTo: string;
};

export function CreatePinRecover({
  backTo,
  exitTo,
  pageTitle,
  phrase,
  sceneConfirmTitle,
  sceneTitle,
  successTo,
}: PpCreatePinRecover): React.ReactElement {
  const { push } = useRouter();
  const { recoverWallet } = useSigning();

  /**
   * Recovers wallet with phrase
   */
  const onSubmit = useCallback(
    async ({ pin }: TpPinForm): Promise<void> => {
      try {
        if (!phrase) {
          logger.error('Missing secret phrase');
          return;
        }

        await recoverWallet(new SafeWrapper(pin), phrase);

        push(successTo);
      } catch (error) {
        logger.error(error);
      }
    },
    [phrase, push, recoverWallet, successTo],
  );

  return (
    <CreatePinScene
      backTo={backTo}
      exitTo={exitTo}
      pageTitle={pageTitle}
      sceneConfirmTitle={sceneConfirmTitle}
      sceneTitle={sceneTitle}
      onSubmit={onSubmit}
    />
  );
}
