import type {
  CountLimit,
  FiatAmount,
  FiatCurrencyCode,
  OrderLimitPolicy,
} from '@noah-labs/shared-schema-gql';
import { Network, OrderType, PolicyPeriod } from '@noah-labs/shared-schema-gql';
import { isUndefinedOrNull } from '@noah-labs/shared-util-vanilla';

export const unlimited: TpLimitAndUsage = {
  count: undefined,
  fiatCurrency: undefined,
  limit: undefined,
  minimum: undefined,
  signing: undefined,
  usage: undefined,
};

export type TpSigningLimits = {
  dailySigningLimit?: OrderLimitPolicy;
  singleSigningLimit?: OrderLimitPolicy;
};

export type TpLimitAndUsage = {
  count: CountLimit | undefined | null;
  fiatCurrency: FiatCurrencyCode | undefined;
  /* an undefined limit represents an unlimited amount */
  limit: FiatAmount | undefined | null;
  minimum: FiatAmount | undefined | null;
  signing: FiatAmount | undefined | null;
  usage: FiatAmount | undefined | null;
};

/**
 * Account withdrawal limits. It can get the limits based
 * on the network or for all networks if the network is undefined
 */
export function getWithdrawalDayLimitAndUsage(
  limits: OrderLimitPolicy[],
  network?: Network | null,
): TpLimitAndUsage {
  const policy = limits.find(
    (u) =>
      u.OrderType === OrderType.WithdrawOrder &&
      (network ? u.Network === network : isUndefinedOrNull(u.Network)) &&
      u.Period === PolicyPeriod.DAY,
  );
  return {
    count: policy?.CountLimit,
    fiatCurrency: policy?.VolumeLimit?.Limit?.FiatCurrency,
    limit: policy?.VolumeLimit?.Limit,
    minimum: policy?.VolumeLimit?.Minimum,
    signing: policy?.VolumeLimit?.Signing,
    usage: policy?.VolumeLimit?.Usage,
  };
}

export function getWithdrawalSingleLimitAndUsage(
  limits: OrderLimitPolicy[],
  network: Network | undefined,
): TpLimitAndUsage {
  const policy = limits.find(
    (u) =>
      u.OrderType === OrderType.WithdrawOrder &&
      u.Network === network &&
      u.Period === PolicyPeriod.SINGLE,
  );
  return {
    count: policy?.CountLimit,
    fiatCurrency: policy?.VolumeLimit?.Limit?.FiatCurrency,
    limit: policy?.VolumeLimit?.Limit,
    minimum: policy?.VolumeLimit?.Minimum,
    signing: policy?.VolumeLimit?.Signing,
    usage: policy?.VolumeLimit?.Usage,
  };
}

/**
 * Lightning withdrawal limits.
 */
export function getLnSendLimitAndUsage(
  limits: OrderLimitPolicy[],
  period: PolicyPeriod,
): TpLimitAndUsage {
  const policy = limits.find(
    (u) =>
      u.OrderType === OrderType.WithdrawOrder &&
      (u.Network === Network.LightningTest || u.Network === Network.Lightning) &&
      u.Period === period,
  );
  return {
    count: policy?.CountLimit,
    fiatCurrency: policy?.VolumeLimit?.Limit?.FiatCurrency,
    limit: policy?.VolumeLimit?.Limit,
    minimum: policy?.VolumeLimit?.Minimum,
    signing: policy?.VolumeLimit?.Signing,
    usage: policy?.VolumeLimit?.Usage,
  };
}

export function getSigningLimits(limits: OrderLimitPolicy[]): TpSigningLimits {
  const dailySigningLimit = limits.find(
    (l) => !!l.VolumeLimit?.Signing && l.Period === PolicyPeriod.DAY,
  );
  const singleSigningLimit = limits.find(
    (l) => !!l.VolumeLimit?.Signing && l.Period === PolicyPeriod.SINGLE,
  );

  return {
    dailySigningLimit,
    singleSigningLimit,
  };
}

export function isPinAlwaysRequiredForTransactions(signingLimits: TpSigningLimits): boolean {
  return (
    Number(signingLimits.dailySigningLimit?.VolumeLimit?.Signing?.Amount) === 0 &&
    Number(signingLimits.singleSigningLimit?.VolumeLimit?.Signing?.Amount) === 0
  );
}
