import { getDefaultAccount } from '@noah-labs/fe-shared-data-access-wallet';
import { generatePath } from '@noah-labs/fe-shared-ui-components';
import { userRoutes, walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { webLinks } from '@noah-labs/shared-constants';
import { logger } from '@noah-labs/shared-logger/browser';
import type { QuestTask } from '@noah-labs/shared-schema-gql';
import { QuestTaskRequirement } from '@noah-labs/shared-schema-gql';
import { getDefaults } from '../../../utils/getDefaults';
import { routes as integrationRoutes } from '../../integrations/routes';
import { routes as rewardsRoutes } from '../routes';
import type { TpTaskAction } from '../types';

/**
 * Describes the CTA on the task cards.
 */
const defaults = getDefaults();

export function getQuestTaskAction(task: QuestTask): TpTaskAction | null {
  const da = getDefaultAccount();
  try {
    switch (task.Requirement) {
      /**
       * completed on signup, show loading button until processed by BE
       */
      case QuestTaskRequirement.VerifyEmail:
        return null;
      case QuestTaskRequirement.MarketingSignup:
        return {
          cta: 'Sign up for marketing',
          href: userRoutes.consent,
          requirement: QuestTaskRequirement.MarketingSignup,
        };
      /**
       * completed on signup, show loading button until processed by BE
       */
      case QuestTaskRequirement.UserSignup:
        return null;
      /**
       * Referral task: navigate to /invite-friends page
       */
      case QuestTaskRequirement.Referrals:
      case QuestTaskRequirement.TotalReferrals:
        return {
          cta: 'Refer friends',
          href: rewardsRoutes.inviteFriends,
          requirement: QuestTaskRequirement.TotalReferrals,
        };
      /**
       * Deposit task: navigate to wallet deposit page based on task currency code
       */
      case QuestTaskRequirement.Deposit: {
        if (!task.RequiredCurrencyCode) {
          switch (task.QuestTaskID) {
            case 'WarpStone/0':
              return {
                cta: `Buy Bitcoin Now`,
                href: generatePath(walletRoutes().buy.enterAmount, {
                  accountType: da.AccountType,
                  currencyCode: da.CurrencyCode,
                }),
                requirement: QuestTaskRequirement.Deposit,
              };
            default:
              throw new Error('missing currency code');
          }
        }
        switch (task.QuestTaskID) {
          case 'BuyBitcoin/0':
            return {
              cta: `Buy ${task.RequiredCurrencyCode}`,
              href: generatePath(walletRoutes().buy.base, {
                accountType: da.AccountType,
                currencyCode: task.RequiredCurrencyCode,
              }),
              requirement: QuestTaskRequirement.Deposit,
            };
          default:
            return {
              cta: `Deposit ${task.RequiredCurrencyCode}`,
              // TODO (cs): check this works as expected
              href: generatePath(walletRoutes().receive.base, {
                accountType: da.AccountType,
                currencyCode: task.RequiredCurrencyCode,
              }),
              requirement: QuestTaskRequirement.Deposit,
            };
        }
      }
      /**
       * completed on signup, show loading button until processed by BE
       */
      case QuestTaskRequirement.RegisterLightningAddress:
        return null;
      case QuestTaskRequirement.CreateLightningInvoice:
        return {
          cta: `Create Invoice`,
          href: generatePath(walletRoutes().receive.lightning.enterAmount, {
            accountType: da.AccountType,
            currencyCode: defaults.cryptoCurrency.code,
          }),
          requirement: QuestTaskRequirement.CreateLightningInvoice,
        };
      case QuestTaskRequirement.LightningInvoiceSettled:
        return {
          cta: `Check Transaction Status`,
          href: generatePath(walletRoutes().account.base, {
            accountType: da.AccountType,
            currencyCode: defaults.cryptoCurrency.code,
          }),
          requirement: QuestTaskRequirement.LightningInvoiceSettled,
        };
      /**
       * not implemented
       */
      case QuestTaskRequirement.Claim:
        return {
          cta: 'Happy Hunting!',
          requirement: QuestTaskRequirement.Claim,
        };
      case QuestTaskRequirement.Withdraw:
        switch (task.QuestTaskID) {
          case 'Bitrefill/0':
          case 'FBitrefill/0':
            return {
              cta: 'Go to Bitrefill',
              href: integrationRoutes.bitrefill,
              requirement: QuestTaskRequirement.Withdraw,
            };
          default:
            throw new Error('task id not recognized');
        }
      case QuestTaskRequirement.BotConnection:
        return {
          cta: 'Connect to Discord',
          externalLink: true,
          href: webLinks.social.discord,
          requirement: QuestTaskRequirement.BotConnection,
        };
      default:
        throw new Error('task requirement not recognized');
    }
  } catch (err) {
    logger.error(err);
    return null;
  }
}
