import { Switch404 } from '@noah-labs/fe-shared-ui-components';
import { TpAuthStatus } from '@noah-labs/fe-shared-ui-shared';
import { Feature } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { AccessControlData } from '../auth/AccessControlData';
import { KycAccessControlData } from '../kyc/controllers/KycAccessControlData';
import { BitrefillScene } from './bitrefill/scenes/Bitrefill';
import { routes } from './routes';

export function Router(): React.ReactElement {
  return (
    <Switch404>
      <Route path={routes.bitrefill}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Bitrefill]}
        >
          <KycAccessControlData bypassIfUserHasBalance>
            <BitrefillScene />
          </KycAccessControlData>
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
