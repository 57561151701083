import { forwardRef, useCallback } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { TpAnalyticsEvent, useAnalytics } from '@noah-labs/fe-shared-feature-analytics';
import { ButtonGroup, LowDialog, PrimaryButton } from '@noah-labs/fe-shared-ui-components';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import { useRouter, useToggleDialog } from '@noah-labs/fe-shared-ui-shared';
import { routes } from '../../modules/kyc/routes';

export type PpVerifyAccountDialog = {
  action: 'buying' | 'selling';
  initialOpen?: boolean;
};

export const VerifyAccountDialog = forwardRef<TpDialogToggle, PpVerifyAccountDialog>(
  ({ action, initialOpen }, ref) => {
    const { push } = useRouter();
    const analytics = useAnalytics();
    const { open, toggle } = useToggleDialog({ initialOpen, ref });

    const onVerifyClick = useCallback(() => {
      analytics.track(TpAnalyticsEvent.KycVerifyClicked);
      push(routes.base);
    }, [analytics, push]);

    return (
      <LowDialog open={open} onClose={toggle}>
        <DialogTitle>Please verify your identity to continue</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Financial regulations require us to verify your ID before {action} cryptocurrency. This
            helps to ensure the safety and security of your NOAH account.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <PrimaryButton
              color="primaryBrand"
              data-qa="verify-account-btn"
              onClick={onVerifyClick}
            >
              Verify account
            </PrimaryButton>
            <PrimaryButton variant="text" onClick={toggle}>
              Cancel
            </PrimaryButton>
          </ButtonGroup>
        </DialogActions>
      </LowDialog>
    );
  },
);
VerifyAccountDialog.displayName = VerifyAccountDialog.name;
