import type { ReactElement } from 'react';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import {
  DotBadge,
  ListItem,
  ListItemButton,
  ListItemCard,
  ListItemContent,
  ListItemEndContent,
  ListItemIcon,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
} from '@noah-labs/fe-shared-ui-components';
import type { IMessage } from '@novu/notification-center';
import dayjs from 'dayjs';
import type { NotificationPayload } from '../types';
import { NotificationIcon } from './NotificationIcon';

export type TpIMessage = Pick<IMessage, 'content' | 'createdAt' | 'cta' | 'payload' | 'seen'>;
type PpNotificationItem = {
  message: TpIMessage;
  onClick: (message: TpIMessage) => void;
};

export function NotificationItem({ message, onClick }: PpNotificationItem): ReactElement {
  const theme = useTheme();
  const styles = {
    unseenMessageContent: css`
      background-color: #fff2e7;
      /* use outline vs border to prevent any layoutshift */
      outline: 2px solid ${theme.palette.background.paper};
      outline-offset: -2px;
    `,
  };

  const dotDisplacement = '6px';
  const dotDisplacementOffset = '-6px';
  const payload = message.payload as NotificationPayload | undefined;

  return (
    <ListItem
      // set component to div because this is not use inside a `ul` tag
      component="div"
      sx={{ '&:not(:last-child)': { mb: 1 } }}
    >
      <ListItemCard css={[!message.seen && styles.unseenMessageContent]}>
        <ListItemButton onClick={(): void => onClick(message)}>
          <ListItemContent>
            <ListItemIcon>
              <NotificationIcon payload={payload} />
            </ListItemIcon>
            <ListItemStartContent>
              <ListItemPrimaryText>{message.content}</ListItemPrimaryText>
              <ListItemSecondaryText>{dayjs(message.createdAt).fromNow()}</ListItemSecondaryText>
            </ListItemStartContent>
            <ListItemEndContent
              sx={{ alignSelf: 'flex-start', ml: dotDisplacement, mr: dotDisplacementOffset }}
            >
              {!message.seen && (
                <DotBadge sx={{ position: 'relative', top: dotDisplacementOffset }} />
              )}
            </ListItemEndContent>
          </ListItemContent>
        </ListItemButton>
      </ListItemCard>
    </ListItem>
  );
}
