import {
  selectFromFilterWithFallback,
  useAccountsQueryPoll,
  useCalculateFiatFromCrypto,
} from '@noah-labs/fe-shared-data-access-wallet';
import type { TpAmount, TpCryptoCurrency, TpFiatCurrency } from '@noah-labs/shared-currencies';
import type { AccountType as TpAccountType } from '@noah-labs/shared-schema-gql';

type PpUseUserAccountAvailableBalance = {
  AccountType: TpAccountType | undefined;
  cryptoCurrency: TpCryptoCurrency | undefined;
  fiatCurrency: TpFiatCurrency | undefined;
};

type TpUseUserAccountAvailableBalance = {
  cryptoAmount: TpAmount;
  fiatAmount: TpAmount;
};

export function useUserAccountAvailableBalance({
  AccountType,
  cryptoCurrency,
  fiatCurrency,
}: PpUseUserAccountAvailableBalance): TpUseUserAccountAvailableBalance {
  const { data: account } = useAccountsQueryPoll(undefined, {
    select: (data) => {
      if (!AccountType || !cryptoCurrency) {
        return undefined;
      }
      return selectFromFilterWithFallback({
        AccountType,
        CurrencyCode: cryptoCurrency.code,
        data,
      });
    },
  });

  const cryptoAmount = account?.Balance?.Available;
  const { data: priceData } = useCalculateFiatFromCrypto({
    cryptoAmount,
    cryptoCurrency,
    fiatCurrency,
    priceProvider: 'market',
  });

  return {
    cryptoAmount,
    fiatAmount: priceData?.fiatAmount,
  };
}
