import { useCallback } from 'react';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import type { TpStateMachine } from '@noah-labs/fe-shared-ui-components';
import type { TpCryptoCurrencyUI, TpCryptoNetworkUi } from '@noah-labs/fe-shared-ui-shared';
import { getAvailableCryptoNetworks } from '@noah-labs/fe-shared-ui-shared';
import { SelectNetworkScene } from '@noah-labs/fe-shared-ui-wallet';
import { Feature } from '@noah-labs/shared-schema-gql';
import type { SmDeposit } from '../types';

type PpDepositNetworkController = TpStateMachine<SmDeposit> & {
  cryptoCurrency: TpCryptoCurrencyUI;
  onNext: () => void;
};
export function DepositNetworkController({
  cryptoCurrency,
  onNext,
  updateState,
}: PpDepositNetworkController): React.ReactElement {
  const { data: userData } = useUserInitUi();

  const depositFF = userData?.userProfile.features.get(Feature.Deposit);
  const networks = getAvailableCryptoNetworks(cryptoCurrency.code, depositFF);

  const onNetworkSelect = useCallback(
    (network: TpCryptoNetworkUi) => {
      updateState({ network });
      onNext();
    },
    [onNext, updateState],
  );

  return (
    <SelectNetworkScene action="receive" networks={networks} onNetworkSelect={onNetworkSelect} />
  );
}
