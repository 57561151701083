import { useCallback } from 'react';
import { LoadingPage } from '@noah-labs/fe-shared-ui-components';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import { useParams } from 'react-router-dom';
import {
  useBotUserConfirmMutation,
  useBotUserDisconnectMutation,
  useBotUserQuery,
} from '../data/bot.generated';
import { useAppsError } from '../hooks/useAppsError';
import { routes } from '../routes';
import { ConfirmScene } from '../scenes/Confirm';

type TpBotUserConnectParams = {
  botSource: string | undefined;
  botSourceId: string | undefined;
  otp: string | undefined;
};

export function Confirm(): React.ReactElement {
  const { push } = useRouter();
  const { botSource, botSourceId, otp } = useParams<TpBotUserConnectParams>();
  const botUserId = `${botSource || ''}/${botSourceId || ''}`;

  const {
    error: botUserConfirmError,
    isLoading: confirmLoading,
    mutateAsync: confirm,
  } = useBotUserConfirmMutation();

  const {
    error: botUserCancelError,
    isLoading: cancelLoading,
    mutateAsync: cancel,
  } = useBotUserDisconnectMutation();

  const {
    data: getBotUser,
    error: botUserError,
    isLoading,
  } = useBotUserQuery({ Input: { BotUserID: botUserId } });

  const discordConnectCreated = getBotUser?.botUser.HashCreated;
  const discordUsername = getBotUser?.botUser.BotSourceUsername;

  const onConfirm = useCallback(async () => {
    try {
      await confirm({ Input: { BotUserID: botUserId, OneTimePassword: otp || '' } });

      // on success redirect to Connected scene
      push(routes.confirmed);
    } catch (err) {
      // Handled by useAppsError
    }
  }, [botUserId, push, confirm, otp]);

  const onCancel = useCallback(async () => {
    try {
      await cancel({ Input: { BotUserID: botUserId } });

      // on success redirect to wallet
      push('/');
    } catch (err) {
      // Handled by useAppsError
    }
  }, [botUserId, cancel, push]);

  const { ApiErrorScene } = useAppsError(botUserError || botUserConfirmError || botUserCancelError);
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  if (!discordConnectCreated || !discordUsername || isLoading) {
    return <LoadingPage />;
  }

  return (
    <ConfirmScene
      discordConnectCreated={discordConnectCreated}
      discordUsername={discordUsername}
      isCancelling={cancelLoading}
      isConfirming={confirmLoading}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
}
