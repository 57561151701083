import type { SafeWrapper } from '@noah-labs/shared-cryptography';
import { SecretPhraseScene } from '../scenes/SecretPhrase';

export type PpSecretPhrase = {
  phrase: SafeWrapper<string> | undefined;
  successTo: string;
};

export function SecretPhrase({ phrase, successTo }: PpSecretPhrase): React.ReactElement {
  return <SecretPhraseScene phrase={phrase} successTo={successTo} />;
}
