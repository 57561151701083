import { useCallback, useEffect, useState } from 'react';
import type { TpCheckoutTokenResponse } from '@noah-labs/fe-shared-data-access-cko';
import { useApplePay, useGooglePay } from '@noah-labs/fe-shared-data-access-payment-methods';
import type { FiatPaymentMutation } from '@noah-labs/fe-shared-data-access-wallet';
import {
  useAmountsWithFee,
  useFiatPaymentAppleSessionMutation,
  useFiatPaymentMethodWalletCardSaveMutation,
  useFiatPaymentMutation,
  usePromotionsQuery,
} from '@noah-labs/fe-shared-data-access-wallet';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import type {
  TpFiatPaymentToken,
  TpPaymentMethodChange,
} from '@noah-labs/fe-shared-feature-wallet';
import type { TpStateMachine } from '@noah-labs/fe-shared-ui-components';
import { CryptoAmount, FiatAmount, generatePath } from '@noah-labs/fe-shared-ui-components';
import {
  cryptoCurrencyFromCode,
  fiatAmountForCurrency,
  TpPaymentMethod,
  useRouter,
  useWalletParams,
} from '@noah-labs/fe-shared-ui-shared';
import { useWalletError } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { TpCheckoutPaymentStatus } from '@noah-labs/fe-shared-util-shared';
import { fiatToMinorUnit } from '@noah-labs/shared-currencies';
import { logger } from '@noah-labs/shared-logger/browser';
import type {
  CountryCode,
  FiatPaymentMethodWalletCardSaveInput,
} from '@noah-labs/shared-schema-gql';
import {
  FiatPaymentMethodTokenProvider,
  FiatPaymentProvider,
  FiatPaymentTokenizationSource,
  FiatPaymentType,
  FiatPaymentWalletCardType,
} from '@noah-labs/shared-schema-gql';
import { compareStrings } from '@noah-labs/shared-util-vanilla';
import { useMutation } from 'react-query';
import { isProd, webConfigBrowser } from '../../../../webConfigBrowser';
import { useKycNotApprovedAndCheckoutEnabled } from '../../../user/hooks/useKycNotApprovedAndCheckoutEnabled';
import { useCardIssuerNotifications } from '../../hooks/useCardIssuerNotifications';
import { ConfirmBuyScene } from '../../scenes/ConfirmBuy';
import type { StBuyRouter } from './BuyRouter';
import { getCheckoutPaymentStatus, getTokenSource } from './utils';

/**
 * For GooglePay / ApplePay
 *
 * - Get tokenized card details from Apple / Google SDK
 * - Exchange this token for a Checkout Token (useRequestCheckoutPaymentToken)
 * - Use this token directly in the fiatPaymentMutation
 * - for now, skip fiatPaymentMethodProviderSave
 */

const {
  feeMinimumFiatAmount,
  feePercentage,
  googlePayGateway,
  googlePayMerchantId,
  publicKey: gatewayMerchantId,
} = webConfigBrowser.cko;

const fees = {
  feeMinimumFiatAmount,
  feePercentage,
};

const NoFeeBuysPromotionID = 'NoFeeBuys20230925';

export function Confirm({
  setStore,
  state,
  updateState,
}: TpStateMachine<StBuyRouter>): React.ReactElement {
  const { data: userData } = useUserInitUi();
  useCardIssuerNotifications(state.selectedPaymentCard?.issuer);
  const { CurrencyCode, params } = useWalletParams();
  const [redirect3ds, setRedirect3ds] = useState<string | null>(null);
  const { push } = useRouter();
  const kycRequired = useKycNotApprovedAndCheckoutEnabled();
  const { data: feePromotion } = usePromotionsQuery(undefined, {
    select: (data) =>
      data.promotions.UnclaimedPromotions?.find((p) => p.PromoID === NoFeeBuysPromotionID),
  });

  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);
  const fiatCurrency = userData?.userProfile.fiatPaymentCurrency;

  const {
    getGooglePayCkoToken,
    isReady: googlePayReady,
    paymentError: googlePaymentError,
  } = useGooglePay({
    countryCode: userData?.userProfile.PrimaryAddress?.Country,
    environment: isProd ? 'PRODUCTION' : 'TEST',
    gateway: googlePayGateway,
    gatewayMerchantId,
    merchantId: googlePayMerchantId,
  });

  const { mutateAsync: validateSession } = useFiatPaymentAppleSessionMutation();
  const fetchMerchantSession = useCallback(
    async (url: string): Promise<string | undefined> => {
      try {
        const { fiatPaymentAppleSession } = await validateSession({
          Input: { ValidationURL: url },
        });
        return fiatPaymentAppleSession.MerchantSession;
      } catch (error) {
        logger.error(error);
        return undefined;
      }
    },
    [validateSession],
  );

  const {
    getApplePayCkoToken,
    isReady: applePayReady,
    paymentError: applePaymentError,
  } = useApplePay(fetchMerchantSession);

  const {
    error: fiatPaymentMethodWalletCardSaveError,
    mutateAsync: mutateFiatPaymentMethodWalletCardSave,
  } = useFiatPaymentMethodWalletCardSaveMutation();

  const { error: fiatPaymentError, mutateAsync: mutateFiatPayment } = useFiatPaymentMutation();

  const onPaymentMethodChange = useCallback(
    ({ card, type }: TpPaymentMethodChange): void => {
      updateState({
        paymentMethod: type,
        selectedPaymentCard: card,
      });
    },
    [updateState],
  );

  /**
   * Initiates alternative payment method sessions and returns token data
   */
  const getApmCheckoutTokenData = useCallback(async (): Promise<
    TpCheckoutTokenResponse | undefined
  > => {
    if (!userData) {
      return undefined;
    }
    try {
      switch (state.paymentMethod) {
        case TpPaymentMethod.GooglePay: {
          const res = await getGooglePayCkoToken({
            amount: state.fiatAmount,
            fiatCurrencyCode: userData.userProfile.fiatPaymentCurrency.code,
          });
          return res;
        }
        case TpPaymentMethod.ApplePay: {
          const res = await getApplePayCkoToken({
            amount: state.fiatAmount,
            countryCode: userData.userProfile.PrimaryAddress?.Country,
            fiatCurrencyCode: userData.userProfile.fiatPaymentCurrency.code,
          });
          return res;
        }
        default:
          logger.error('Invalid payment method', state.paymentMethod);
          return undefined;
      }
    } catch (error) {
      // useWalletError handles error
      return undefined;
    }
  }, [getApplePayCkoToken, getGooglePayCkoToken, state.fiatAmount, state.paymentMethod, userData]);

  /**
   * Submits the fiatPayment mutation and redirects to a Complete
   * failed page if it fails
   */
  const submitFiatPaymentRequest = useCallback(
    async (
      paymentId: string,
      paymentToken: FiatPaymentTokenizationSource,
    ): Promise<FiatPaymentMutation | undefined> => {
      if (!userData) {
        return undefined;
      }
      try {
        const data = await mutateFiatPayment({
          Input: {
            CurrencyCode,
            PaymentDetails: {
              ID: paymentId,
              Type: FiatPaymentType.FiatPaymentMethodID,
            },
            Provider: FiatPaymentProvider.Checkout,
            RequestedAmount: {
              Amount: fiatToMinorUnit(state.fiatAmount),
              FiatCurrency: userData.userProfile.fiatPaymentCurrency.code,
            },
            TokenizationSource: paymentToken,
          },
        });

        return data;
      } catch {
        push(
          generatePath(walletRoutes().buy.complete, {
            ...params,
            paymentStatus: TpCheckoutPaymentStatus.failure,
          }),
        );
        return undefined;
      }
    },
    [CurrencyCode, mutateFiatPayment, params, push, state.fiatAmount, userData],
  );

  /**
   * Submits the payment request
   */
  const submitFiatPayment = useCallback(
    async (paymentToken: TpFiatPaymentToken, tokenResponse?: TpCheckoutTokenResponse) => {
      try {
        let paymentId = paymentToken.id;

        switch (state.paymentMethod) {
          case TpPaymentMethod.ApplePay:
          case TpPaymentMethod.GooglePay:
            if (!tokenResponse) {
              throw new Error('Invalid token response');
            }
            {
              const walletCardSaveInput: FiatPaymentMethodWalletCardSaveInput = {
                Details: {
                  Bin: tokenResponse.bin,
                  ExpiryMonth: tokenResponse.expiry_month,
                  ExpiryYear: tokenResponse.expiry_year,
                  Last4: tokenResponse.last4,
                  WalletType:
                    state.paymentMethod === TpPaymentMethod.ApplePay
                      ? FiatPaymentWalletCardType.ApplePay
                      : FiatPaymentWalletCardType.GooglePay,
                },
                Integration: {
                  Provider: FiatPaymentMethodTokenProvider.Checkout,
                  Token: paymentToken.id,
                },
              };

              if (tokenResponse.billing_address) {
                walletCardSaveInput.Details.BillingAddress = {
                  City: tokenResponse.billing_address.city,
                  Country: tokenResponse.billing_address.country as CountryCode,
                  PostCode: tokenResponse.billing_address.zip,
                  State: tokenResponse.billing_address.state,
                  Street: tokenResponse.billing_address.address_line1,
                  Street2: tokenResponse.billing_address.address_line2,
                };
              }

              const fpmWalletSaveData = await mutateFiatPaymentMethodWalletCardSave({
                Input: walletCardSaveInput,
              });
              if (!fpmWalletSaveData.fiatPaymentMethodWalletCardSave.DynamoID) {
                throw new Error('Failed to save payment method');
              }
              paymentId = fpmWalletSaveData.fiatPaymentMethodWalletCardSave.DynamoID;
            }
            break;

          default:
            break;
        }

        const data = await submitFiatPaymentRequest(paymentId, paymentToken.source);

        if (!data) {
          throw new Error('Failed to submit payment request');
        }

        // add the transaction ID to the state
        if (typeof data.fiatPayment.Payment.PaymentID === 'string') {
          updateState({
            transactionId: data.fiatPayment.Payment.PaymentID,
          });
        }

        // redirect to the bank verification screen if required
        if (data.fiatPayment.Links) {
          const redirectLink = data.fiatPayment.Links.find((link) =>
            compareStrings(link.LinkType, 'redirect'),
          );
          if (typeof redirectLink?.Location === 'string') {
            setRedirect3ds(redirectLink.Location);
            return;
          }
        }

        push(
          generatePath(walletRoutes().buy.complete, {
            ...params,
            paymentStatus: getCheckoutPaymentStatus(data.fiatPayment.Payment.PaymentStatus),
          }),
        );
      } catch (e) {
        // useWalletError handles error
      }
    },
    [
      push,
      mutateFiatPaymentMethodWalletCardSave,
      submitFiatPaymentRequest,
      params,
      state.paymentMethod,
      updateState,
    ],
  );

  /**
   * Requests the appropriate token from Checkout and submits the fiat payment
   */
  const { isLoading: isSubmittingPayment, mutateAsync: onConfirm } = useMutation(
    ['checkout/PreparePayment'],
    async () => {
      if (!state.paymentMethod) {
        return;
      }

      // payment using a saved card
      if (state.selectedPaymentCard) {
        await submitFiatPayment({
          id: state.selectedPaymentCard.id,
          source: FiatPaymentTokenizationSource.Checkout,
        });
        return;
      }

      // alternative payment methods
      try {
        const tokenResponse = await getApmCheckoutTokenData();
        if (!tokenResponse) {
          return;
        }

        await submitFiatPayment(
          {
            id: tokenResponse.token,
            source: getTokenSource(state.paymentMethod),
          },
          tokenResponse,
        );
      } catch {
        // useWalletError handles error
      }
    },
  );

  const { feeFiatAmount, netCryptoAmount, price } = useAmountsWithFee({
    cryptoCurrency,
    feePromotion: feePromotion
      ? fiatAmountForCurrency(feePromotion.Amounts, fiatCurrency?.code).Amount
      : undefined,
    fees,
    fiatAmount: state.fiatAmount,
    fiatCurrency,
    priceProvider: 'buy',
  });

  useEffect(() => {
    if (!redirect3ds) {
      return;
    }
    // persist store directly to try to avoid inconsistent beforeunload event handling,
    // e.g. when triggered after checkout redirect in Safari
    setStore();

    // push the redirect on to the next 'tick' to allow setStore to correctly set the sessionStorage
    // this 'error' seems to only manifest in e2e tests but is possible it could happen in other browsers etc.
    setTimeout(() => {
      window.location.assign(redirect3ds);
    }, 10);
  }, [redirect3ds, setStore]);

  const { ApiErrorScene } = useWalletError(
    fiatPaymentError ||
      googlePaymentError ||
      applePaymentError ||
      fiatPaymentMethodWalletCardSaveError,
  );
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <ConfirmBuyScene
      applePayEnabled={applePayReady}
      backTo={generatePath(walletRoutes().buy.enterAmount, params)}
      cryptoCurrency={cryptoCurrency}
      CryptoPriceSlot={<FiatAmount amount={price} currency={fiatCurrency} />}
      ctaButtonLabel="Pay now"
      FeeAmountSlot={<FiatAmount amount={feeFiatAmount} currency={fiatCurrency} />}
      feePromotion={feePromotion}
      googlePayEnabled={googlePayReady}
      isLoading={isSubmittingPayment}
      kycRequired={kycRequired}
      NetCryptoAmountSlot={<CryptoAmount amount={netCryptoAmount} currency={cryptoCurrency} />}
      pageTitle="Buy Confirmation"
      paymentMethod={state.paymentMethod}
      selectedPaymentCard={state.selectedPaymentCard}
      TotalFiatAmountSlot={<FiatAmount amount={state.fiatAmount} currency={fiatCurrency} />}
      onConfirm={onConfirm}
      onPaymentMethodChange={onPaymentMethodChange}
      onSubmitCardDetailsRedirect={generatePath(walletRoutes().buy.confirm, params)}
    />
  );
}
