import React, { useEffect, useState } from 'react';
import { isAppSyncError, isGraphqlError } from '@noah-labs/fe-shared-data-access-shared';
import { ErrorPage } from '@noah-labs/fe-shared-ui-components';
import type { TpUseError } from '@noah-labs/fe-shared-ui-shared';
import { logger } from '@noah-labs/shared-logger/browser';
import type { PpFailedScene } from '../scenes/Failed';
import { FailedScene } from '../scenes/Failed';

// TODO: need to map error details between FE and CORE
const errorCodeExpired = 'this code has expired';
const errorVerifyHash = 'hash does not match';
const errorUserIDMismatch = 'user id does not match';

const errorDetails = [errorCodeExpired, errorUserIDMismatch, errorVerifyHash];

function getErrorProps(det: string): PpFailedScene {
  const backTo = '/';
  switch (det) {
    case errorCodeExpired:
      return {
        backTo,
        errorHelpText:
          'Your connection link expired. Links expire after 10 minutes and can only be used once. Connect from Discord again to have a new link sent to you!',
        errorMessage: 'Oops! Discord connection expired',
      };
    case errorVerifyHash:
    case errorUserIDMismatch:
      return {
        backTo,
        errorHelpText:
          'Connecting to Discord failed as the credentials do not match. Try disconnecting from NOAH and connecting again via Discord.',
        errorMessage: 'Oops! Discord connection failed',
      };
    default:
      return {
        backTo,
        errorHelpText: 'Please go back and try again.',
        errorMessage: 'Something went wrong',
      };
  }
}

export function useAppsError(error: unknown): TpUseError {
  const [apiErrorScene, setApiErrorScene] = useState<React.ReactElement | null>(null);

  useEffect(() => {
    if (!error) {
      setApiErrorScene(null);
      return;
    }

    logger.error(error);

    if (isGraphqlError(error)) {
      error.response.errors.forEach((innerError) => {
        if (!isAppSyncError(innerError) || !innerError.message) {
          return;
        }
        for (let i = 0; i < errorDetails.length; i += 1) {
          if (innerError.message.includes(errorDetails[i])) {
            const props = getErrorProps(errorDetails[i]);
            setApiErrorScene(<FailedScene {...props} />);
            break;
          }
        }
      });
      return;
    }

    /**
     * If the error was not handled above, set to a generic error,
     * no need to show the user potentially confusing errors
     */
    setApiErrorScene(
      <ErrorPage helpText="Please go back and try again." message="Sorry, something went wrong" />,
    );
  }, [error]);

  return { ApiErrorScene: apiErrorScene };
}
