import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { TpAnalyticsEvent, useAnalytics } from '@noah-labs/fe-shared-feature-analytics';
import {
  ButtonGroup,
  List,
  ListItem,
  ListItemCard,
  ListItemContent,
  ListItemEndContent,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
  LowDialog,
  PrimaryButton,
} from '@noah-labs/fe-shared-ui-components';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import { useToggleDialog } from '@noah-labs/fe-shared-ui-shared';
import type { TpCurrencyAmountSlots } from '../utils/getCurrencyAmountSlots';

export type PpWithdrawNetworkFeeDialog = {
  FeeAmountSlots: TpCurrencyAmountSlots;
  TotalAmountSlots: TpCurrencyAmountSlots;
  initialOpen?: boolean;
  isLoading: boolean;
  onContinue: () => Promise<void>;
};

export const WithdrawNetworkFeeDialog = forwardRef<TpDialogToggle, PpWithdrawNetworkFeeDialog>(
  ({ FeeAmountSlots, initialOpen, isLoading, onContinue, TotalAmountSlots }, ref): JSX.Element => {
    const { open, toggle } = useToggleDialog({ initialOpen, ref });
    const { track } = useAnalytics();

    return (
      <LowDialog open={open} onClose={toggle}>
        <DialogTitle>Network fee update</DialogTitle>
        <DialogContent>
          <DialogContentText paragraph>
            The network fee has increased due to elapsed time since initiating the transaction.
            Please review and confirm the updated fee to continue.
          </DialogContentText>

          <ListItemCard sx={{ px: 2 }}>
            <List disablePadding>
              <ListItem divider>
                <ListItemContent sx={{ px: 0 }}>
                  <ListItemStartContent>
                    <ListItemSecondaryText>Network Fee</ListItemSecondaryText>
                  </ListItemStartContent>
                  <ListItemEndContent>
                    <ListItemPrimaryText>{FeeAmountSlots.PrimaryAmountSlot}</ListItemPrimaryText>
                    <ListItemSecondaryText>
                      {FeeAmountSlots.SecondaryAmountSlot}
                    </ListItemSecondaryText>
                  </ListItemEndContent>
                </ListItemContent>
              </ListItem>

              <ListItem>
                <ListItemContent sx={{ px: 0 }}>
                  <ListItemStartContent>
                    <ListItemPrimaryText>Total</ListItemPrimaryText>
                  </ListItemStartContent>
                  <ListItemEndContent>
                    <ListItemPrimaryText>{TotalAmountSlots.PrimaryAmountSlot}</ListItemPrimaryText>
                    <ListItemSecondaryText>
                      {TotalAmountSlots.SecondaryAmountSlot}
                    </ListItemSecondaryText>
                  </ListItemEndContent>
                </ListItemContent>
              </ListItem>
            </List>
          </ListItemCard>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <PrimaryButton
              color="primaryBrand"
              loading={isLoading}
              onClick={async (): Promise<void> => {
                track(TpAnalyticsEvent.WithdrawSubmittedAfterFeeIncrease);
                await onContinue();
              }}
            >
              Continue Anyway
            </PrimaryButton>
            <PrimaryButton
              disabled={isLoading}
              variant="text"
              onClick={(): void => {
                track(TpAnalyticsEvent.WithdrawCanceledAfterFeeIncrease);
                toggle();
              }}
            >
              Cancel
            </PrimaryButton>
          </ButtonGroup>
        </DialogActions>
      </LowDialog>
    );
  },
);

WithdrawNetworkFeeDialog.displayName = WithdrawNetworkFeeDialog.name;
