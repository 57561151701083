import { FiatAmount } from '@noah-labs/fe-shared-ui-components';
import type { TpFiatCurrency } from '@noah-labs/shared-currencies';
import { isBlank, safeBN } from '@noah-labs/shared-util-vanilla';
import * as yup from 'yup';

const MAX_FIAT_AMOUNT = 10000000;

export function maxSafetyAmount(fiatCurrency: TpFiatCurrency | undefined): yup.StringSchema {
  if (fiatCurrency === undefined) {
    return yup.string();
  }
  return yup.string().test({
    message: () => (
      <span>
        Maximum amount is <FiatAmount amount={MAX_FIAT_AMOUNT} currency={fiatCurrency} />
      </span>
    ),
    test: (value) => safeBN(value).lt(safeBN(MAX_FIAT_AMOUNT)),
  });
}

type TpMinRampFiatAmount = {
  fiatCurrency: TpFiatCurrency | undefined;
  minFiatAmount: string;
  prefix: string;
};

export function minRampFiatAmount({
  fiatCurrency,
  minFiatAmount,
  prefix,
}: TpMinRampFiatAmount): yup.StringSchema {
  return yup.string().test({
    message: () => (
      <span>
        {prefix} <FiatAmount amount={minFiatAmount} currency={fiatCurrency} />
      </span>
    ),
    test: (value) => {
      if (isBlank(value)) {
        return true;
      }

      return safeBN(value).gte(minFiatAmount);
    },
  });
}
