import { CurrencyDisplayType, CurrencyUnit, FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import type { QueryClient } from 'react-query';
import type { FrUserProfileFragment, UserInitQuery } from './user.generated';

export type TpUp = FrUserProfileFragment;
export type TpUc = UserInitQuery['userSettingsConsent'];

export const emptyUserProfile = {
  DisplayUnit: CurrencyUnit.Default,
  Email: '',
  FiatCurrencyCode: FiatCurrencyCode.USD,
  KycVerification: {},
  Metadata: {},
  PrimaryCurrency: CurrencyDisplayType.Fiat,
  Referrals: 0,
  UserID: '',
  Username: '',
  UsernameDisplay: '',
};

export function mergeUserInitCache(
  oldData: UserInitQuery | undefined,
  userProfile?: Partial<TpUp>,
  userSettingsConsent?: TpUc,
): UserInitQuery | undefined {
  if (!userSettingsConsent && !userProfile) {
    return oldData;
  }

  const newUserSettingsConsent = userSettingsConsent || oldData?.userSettingsConsent || [];

  let newUserProfile = oldData?.userProfile || emptyUserProfile;

  if (userProfile) {
    newUserProfile = {
      ...newUserProfile,
      ...userProfile,
    };
  }

  return {
    ...oldData,
    userProfile: newUserProfile,
    userSettingsConsent: newUserSettingsConsent,
  };
}

export function updateUserInitCache(
  queryClient: QueryClient,
  userProfile?: Partial<TpUp>,
  userSettingsConsent?: TpUc,
): void {
  /**
   * Directly update the cache in react-query for 'UserInit' - will cause that hook to rerender
   * Be sure to change this key if the query name changes in core-auth/src/hooks/user.graphql
   */
  queryClient.setQueryData<UserInitQuery | undefined>(['UserInit'], (oldData) =>
    mergeUserInitCache(oldData, userProfile, userSettingsConsent),
  );
}
