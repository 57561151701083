import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { useTransactionDetails } from '@noah-labs/fe-shared-feature-wallet';
import { generatePath } from '@noah-labs/fe-shared-ui-components';
import { useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { isUndefinedOrNull } from '@noah-labs/shared-util-vanilla';
import { useBackHijack } from '../../../../hooks/useBackHijack';
import { TransactionDialog } from '../../components/dialogs/TransactionDialog';

type PpTransaction = {
  id: string | undefined | null;
};

export function Transaction({ id }: PpTransaction): React.ReactElement | null {
  const hasId = !isUndefinedOrNull(id);
  const txId = decodeURIComponent(id ?? '');
  const { data: userData } = useUserInitUi();
  const { transaction: txProps } = useTransactionDetails(txId);
  const { params } = useWalletParams();
  const { backTo } = useBackHijack(generatePath(walletRoutes().account.base, params));

  return (
    <TransactionDialog
      {...txProps}
      backTo={backTo}
      open={hasId}
      userCryptoUnit={userData?.userProfile.DisplayUnit}
      userPrimaryCurrency={userData?.userProfile.PrimaryCurrency}
    />
  );
}
