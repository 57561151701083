import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  AppContainer,
  AppMain,
  SceneHeader,
  SceneMain,
  useDesktop,
} from '@noah-labs/fe-shared-ui-components';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';
import { NavBarData } from '../../../components/navigation/NavBarData';
import { SidebarNavData } from '../../../components/navigation/SidebarNavData';

export type PpAccountsScene = {
  AccountsSlot: React.ReactElement;
  ActionsMenuSlot: React.ReactElement;
  TotalBalanceSlot: React.ReactElement;
};

export function AccountsScene({
  AccountsSlot,
  ActionsMenuSlot,
  TotalBalanceSlot,
}: PpAccountsScene): React.ReactElement {
  const isDesktop = useDesktop();
  const theme = useTheme();

  return (
    <AppContainer BottomNavbarSlot={<NavBarData />} SideNavbarSlot={<SidebarNavData />}>
      <Helmet>
        <title>Wallet</title>
      </Helmet>
      <AppMain sx={{ display: 'flex', flexDirection: 'column' }}>
        <AppHeaderData
          avatarButton
          disableBalancing
          helpButton
          notificationCenterButton
          backgroundColor={theme.palette.grayscale.offWhite}
          fullWidth={isDesktop}
        />
        <SceneHeader>
          <Stack spacing={5}>
            {TotalBalanceSlot}
            {ActionsMenuSlot}
          </Stack>
        </SceneHeader>
        <SceneMain dataQa="wallet">{AccountsSlot}</SceneMain>
      </AppMain>
    </AppContainer>
  );
}
