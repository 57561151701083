import { useCallback, useEffect } from 'react';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import {
  AppContainer,
  AppMain,
  ErrorPage,
  LoadingPage,
  SceneMain,
} from '@noah-labs/fe-shared-ui-components';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import { logger } from '@noah-labs/shared-logger/browser';
import SumsubWebSdk from '@sumsub/websdk-react';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { useSumsubAccessTokenMutation } from '../../sumsub/data/sumsub.generated';
import { routes } from '../routes';

type TpSumsubEventName =
  | 'idCheck.onReady'
  | 'idCheck.onResize'
  | 'idCheck.onApplicantLoaded'
  | 'idCheck.onInitialized'
  | 'idCheck.onApplicantSubmitted'
  | 'idCheck.onApplicantStatusChanged';

type TpSumsubEventOnApplicantStatusChangedPayload = {
  attemptCnt: number;
  attemptId: string;
  confirmed?: boolean;
  createDate: string;
  elapsedSincePendingMs?: number;
  elapsedSinceQueuedMs?: number;
  levelAutoCheckMode: unknown;
  levelName: 'Full KYC for Individuals' | 'EDD KYC for Individuals';
  priority: number;
  reprocessing: boolean;
  reviewDate?: string;
  reviewId: string;
  reviewResult?: {
    reviewAnswer: 'GREEN' | 'RED';
  };
  reviewStatus: 'completed' | 'pending';
};

export function Sumsub(): React.ReactElement {
  const {
    data: sumSubData,
    error: sumSubError,
    mutateAsync: sumSubAccessToken,
  } = useSumsubAccessTokenMutation();

  const { data: userData, refetch: userInitRefetch } = useUserInitUi();
  const { replace } = useRouter();

  const getAccessToken = useCallback(async (): Promise<string | undefined> => {
    try {
      const { sumsub } = webConfigBrowser;
      if (!sumsub.fullLevelName) {
        logger.error('sumsub level name is not defined');
        return undefined;
      }

      const data = await sumSubAccessToken({
        Input: {
          LevelName: sumsub.fullLevelName,
        },
      });
      return data.sumSubAccessToken.Token;
    } catch (err) {
      logger.error(err);
      return undefined;
    }
  }, [sumSubAccessToken]);

  const onMessage = useCallback(
    async (event: TpSumsubEventName, payload: TpSumsubEventOnApplicantStatusChangedPayload) => {
      /**
       * Events:
       * First open:
       * - onReady
       * - onResize
       * - onApplicantLoaded
       * - onInitialized
       *
       * Low Risk User
       * - onApplicantStatusChanged
       * {
       *  "reviewId" : "zmmVw",
       *  "attemptId" : "YbZQu",
       *  "attemptCnt" : 1,
       *  "elapsedSincePendingMs" : 51327,
       *  "elapsedSinceQueuedMs" : 51327,
       *  "reprocessing" : true,
       *  "levelName" : "Full KYC for Individuals",
       *  "levelAutoCheckMode" : null,
       *  "createDate" : "2024-02-21 14:43:40+0000",
       *  "reviewDate" : "2024-02-21 14:44:32+0000",
       *  "reviewResult" : {
       *    "reviewAnswer" : "GREEN"
       *  },
       *  "reviewStatus" : "completed",
       *  "confirmed" : true,
       *  "priority" : 0
       * },
       *
       * Low Risk User, Answering High Risk question
       * - onApplicantStatusChanged
       * {
       *  "reviewId" : "LZQud",
       *  "attemptId" : "Jxabi",
       *  "attemptCnt" : 0,
       *  "reprocessing" : false,
       *  "levelName" : "Full KYC for Individuals",
       *  "levelAutoCheckMode" : null,
       *  "createDate" : "2024-02-21 14:30:33+0000",
       *  "reviewStatus" : "pending",
       *  "priority" : 0
       * }
       *
       */

      /**
       * We can close the iframe if:
       * - user is low / med risk (full KYC) and has completed the flow (user is auto-reviewed)
       * - user was high risk and asked to do EDD after submission (user will definitely need manual review)
       */
      const anyKycComplete =
        event === 'idCheck.onApplicantStatusChanged' && payload.reviewStatus === 'completed';

      const eddKycSubmitted =
        event === 'idCheck.onApplicantStatusChanged' &&
        payload.levelName === 'EDD KYC for Individuals' &&
        payload.reviewStatus === 'pending';

      if (anyKycComplete || eddKycSubmitted) {
        try {
          await userInitRefetch();
        } catch (err) {
          // can 'ignore' the error, not critical to user completing this flow
          logger.error(err);
        }
        replace(routes.complete);
      }
    },
    [replace, userInitRefetch],
  );

  useEffect(() => {
    void getAccessToken();
  }, [getAccessToken]);

  if (sumSubError) {
    return <ErrorPage message="Sorry, something went wrong" />;
  }

  if (!sumSubData || !userData) {
    return <LoadingPage />;
  }

  return (
    <AppContainer>
      <Helmet>
        <title>Identity verification</title>
      </Helmet>
      <AppMain>
        <AppHeaderData disableFade exitButton />
        <SceneMain dataQa="kyc-sumsub" sx={{ paddingY: 0 }}>
          <SumsubWebSdk
            accessToken={sumSubData.sumSubAccessToken.Token}
            config={{ email: userData.userProfile.Email }}
            expirationHandler={getAccessToken}
            onMessage={onMessage}
          />
        </SceneMain>
      </AppMain>
    </AppContainer>
  );
}
