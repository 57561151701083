import { useCallback, useEffect, useRef } from 'react';
import {
  AuthErrorType,
  useOrySignUpCallback,
  useOrySignUpFlowError,
  useOrySocialSignUpCallback,
} from '@noah-labs/fe-shared-data-access-auth';
import { orySessionKey } from '@noah-labs/fe-shared-feature-auth';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import { authRoutes } from '@noah-labs/fe-shared-util-routes';
import type { AwsCaptchaApi } from '@noah-labs/shared-schema-gql';
import { SardineFlows } from '@noah-labs/shared-schema-gql';
import { useQueryClient } from 'react-query';
import { useAwsCaptcha } from '../hooks/useAwsCaptcha';
import type { TpOnSignUp, TpOnSocialSignUp } from '../scenes/SignUpScene';
import { SignUpScene } from '../scenes/SignUpScene';

export type PpSignUp = {
  captcha: AwsCaptchaApi | undefined;
  helpButton?: React.ReactNode;
  referralCode: string | null;
  updateGuestSardineConfig: (flow: SardineFlows) => string;
};

export function SignUp({
  captcha,
  helpButton,
  referralCode,
  updateGuestSardineConfig,
}: PpSignUp): React.ReactElement {
  const { push } = useRouter();
  const queryClient = useQueryClient();

  const onContinueWithVerification = useCallback(
    async (flowId: string) => {
      await queryClient.invalidateQueries(orySessionKey);
      push({
        pathname: authRoutes.verify,
        search: new URLSearchParams([['flow', flowId]]).toString(),
      });
    },
    [queryClient, push],
  );

  const onPasswordSignUp = useOrySignUpCallback({
    onContinueWithVerification,
  });

  const onSocialSignUp = useOrySocialSignUpCallback();
  const flowError = useOrySignUpFlowError();
  const sessionKeyRef = useRef<string | undefined>(undefined);

  useEffect(() => {
    if (sessionKeyRef.current !== undefined) {
      return;
    }
    sessionKeyRef.current = updateGuestSardineConfig(SardineFlows.Registration);
  }, [updateGuestSardineConfig]);

  const { captchaCallback } = useAwsCaptcha(captcha);
  const handleSignup = useCallback<TpOnSignUp>(
    async (values, setError) => {
      const captchaSucceed = await captchaCallback();
      if (!captchaSucceed) {
        setError('root.serverError', {
          message: 'CAPTCHA verification failed.',
          type: AuthErrorType.Unspecified,
        });
        return;
      }

      await onPasswordSignUp(values, setError, {
        referralCode,
        sessionKey: sessionKeyRef.current,
      });
    },
    [onPasswordSignUp, captchaCallback, referralCode],
  );

  const handleSocialSignUp = useCallback<TpOnSocialSignUp>(
    async (provider, setError) => {
      await onSocialSignUp(provider, setError, {
        referralCode,
        sessionKey: sessionKeyRef.current,
      });
    },
    [onSocialSignUp, referralCode],
  );

  return (
    <SignUpScene
      error={flowError}
      helpButton={helpButton}
      signinUrl={authRoutes.signIn}
      onSignUp={handleSignup}
      onSocialSignUp={handleSocialSignUp}
    />
  );
}
