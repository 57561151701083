import { CircleTickIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CircleTickIcon';
import {
  AppContainer,
  AppFooter,
  AppMain,
  PrimaryButton,
  SceneIcon,
  SceneMain,
  SceneParagraph,
  SceneTitleLarge,
} from '@noah-labs/fe-shared-ui-components';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';

export type PpConfirmedScene = {
  backTo: string;
};

export function ConfirmedScene({ backTo }: PpConfirmedScene): React.ReactElement {
  return (
    <AppContainer>
      <Helmet>
        <title>Discord connection confirmed</title>
      </Helmet>
      <AppMain>
        <AppHeaderData exitButton helpButton />
        <SceneMain dataQa="confirmed">
          <SceneIcon>
            <CircleTickIcon color="success" />
          </SceneIcon>
          <SceneTitleLarge>Connection confirmed</SceneTitleLarge>
          <SceneParagraph>
            Please go back to Discord to finish the connection process.
          </SceneParagraph>
          <SceneParagraph>You will now be able to use NOAH on Discord.</SceneParagraph>
        </SceneMain>
      </AppMain>
      <AppFooter>
        <PrimaryButton color="primary" href={backTo}>
          Close
        </PrimaryButton>
      </AppFooter>
    </AppContainer>
  );
}
