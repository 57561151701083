import { IconButton, Stack, Typography } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { CloseDialogIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CloseDialogIcon';
import { ShareIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ShareIcon';
import {
  AddressCopiedAlert,
  AppLogo,
  ButtonGroup,
  DynamicCopyIcon,
  LowDialog,
  PrimaryButton,
  QRCodeWithImage,
  TextOrSkeleton,
  useClickToCopy,
  usePushAlert,
  useShare,
} from '@noah-labs/fe-shared-ui-components';
import type { TpCryptoCurrencyUI, TpCryptoNetworkUi } from '@noah-labs/fe-shared-ui-shared';
import { assetPaths } from '@noah-labs/shared-constants';
import { QRCodeArea } from '../qr/QRCodeArea';

export type PpOnChainQRCodeDialog = {
  address?: string;
  cryptoCurrency: TpCryptoCurrencyUI;
  network: TpCryptoNetworkUi;
  onClose: () => void;
  open: boolean;
};

const dialogMaxWidth = '327px';

const dialogWidthStyles = {
  maxWidth: dialogMaxWidth,
  width: '100%',
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.grayscale[100],
  position: 'absolute',
  right: theme.spacing(0),
  top: theme.spacing(0),
}));

export function OnChainQRCodeDialog({
  address,
  cryptoCurrency,
  network,
  onClose,
  open,
}: PpOnChainQRCodeDialog): React.ReactElement {
  const theme = useTheme();
  const pushAlert = usePushAlert();
  const { handleSharing, isSupported } = useShare({ text: address });

  const { copied, copyHandler } = useClickToCopy({
    onCopy: () => pushAlert(AddressCopiedAlert),
    text: address,
  });

  const backdropProps = {
    backdropFilter: 'blur(10px)',
    background: alpha(theme.palette.grayscale[600], 0.5),
  };
  const paperProps = {
    alignItems: 'center',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    maxWidth: 'fit-content',
    padding: theme.spacing(3),
  };

  return (
    <LowDialog
      fullScreen
      keepMounted
      backgroundColor="transparent"
      dataQa="qrcode"
      open={open}
      PaperProps={{ sx: paperProps }}
      slotProps={{
        backdrop: {
          sx: backdropProps,
        },
      }}
      onClose={onClose}
    >
      <QRCodeArea network={network}>
        <StyledIconButton data-qa="qrcode-dialog-exit-button" onClick={onClose}>
          <CloseDialogIcon />
        </StyledIconButton>
        <Stack alignItems="flex-start" p={2} spacing={3} sx={dialogWidthStyles}>
          <Stack spacing={0.5}>
            <AppLogo color={theme.palette.grayscale[100]} />
            <Typography variant="paragraphBodyS">{cryptoCurrency.label}</Typography>
          </Stack>
          <QRCodeWithImage
            data={address}
            height={600}
            imageUrl={`${assetPaths.images}/logo-icon-black.svg`}
            width={600}
          />
          <Stack spacing={0.5} width="100%">
            <Typography sx={{ opacity: 0.8 }} variant="paragraphBodyS">
              Your {network.label} address
            </Typography>
            <Typography noWrap variant="paragraphBodySBold">
              <TextOrSkeleton>{address}</TextOrSkeleton>
            </Typography>
          </Stack>
        </Stack>
      </QRCodeArea>
      <Stack width="100%">
        <ButtonGroup mt={2} sx={dialogWidthStyles}>
          <PrimaryButton
            color="ghost"
            data-qa="copy-button"
            data-qa-address={address}
            startIcon={<DynamicCopyIcon copied={copied} />}
            onClick={copyHandler}
          >
            Copy
          </PrimaryButton>
          {isSupported && (
            <PrimaryButton color="ghost" startIcon={<ShareIcon />} onClick={handleSharing}>
              Share
            </PrimaryButton>
          )}
        </ButtonGroup>
      </Stack>
    </LowDialog>
  );
}
