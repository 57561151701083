import { useContext } from 'react';
import { useAuth } from '@noah-labs/fe-shared-feature-auth';
import { useFeature } from '@noah-labs/fe-shared-feature-user';
import { checkAuthStatus, TpAuthStatus } from '@noah-labs/fe-shared-ui-shared';
import { logger } from '@noah-labs/shared-logger/browser';
import { Feature } from '@noah-labs/shared-schema-gql';
import type { CxSigning } from './SigningProvider';
import { SigningContext } from './SigningProvider';

export function useSigning(): CxSigning {
  const context = useContext(SigningContext);
  const { feature: fpin } = useFeature(Feature.Pin);
  const { authStatus } = useAuth();

  if (!fpin?.Enabled && checkAuthStatus({ has: authStatus, needs: [TpAuthStatus.authenticated] })) {
    return {} as CxSigning;
  }
  if (context === undefined) {
    return logger.fatal('useSigning must be used within a SigningProvider');
  }
  return context;
}
