import { Switch404, useStateMachine } from '@noah-labs/fe-shared-ui-components';
import { TpAuthStatus, useRouter } from '@noah-labs/fe-shared-ui-shared';
import { userRoutes } from '@noah-labs/fe-shared-util-routes';
import type { Maybe } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { webConfigBrowser } from '../../webConfigBrowser';
import { AccessControlData } from '../auth/AccessControlData';
import { MarketingUpdates } from './controllers/MarketingUpdates';
import { TermsAndCookies } from './controllers/TermsAndCookies';
import { Username } from './controllers/Username';
import type { TpConsentFormValues } from './scenes/Consent';

export type StRegistration = Omit<TpConsentFormValues, 'EmailContact'> & {
  isDirty: boolean;
};

const emptyState = {
  Cookies: {
    advertising: false,
    functional: true,
    marketingAndAnalytics: false,
  },
  isDirty: false,
  Terms: true,
};

type PpPostSignupRouter = {
  logoutTo: string;
  successTo: string;
  usernameDisplay?: Maybe<string>;
};

/**
 * PostSignupRouter controls user's who still need to accept terms and / or set their username
 */
export function PostSignupRouter({
  logoutTo,
  successTo,
  usernameDisplay,
}: PpPostSignupRouter): React.ReactElement {
  const { push } = useRouter();
  const sm = useStateMachine<StRegistration>({ emptyState, name: 'registrationState' });

  return (
    <Switch404>
      <Route exact path={userRoutes.username}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          redirectInvalid={Boolean(usernameDisplay) && successTo}
        >
          <Username
            logoutTo={logoutTo}
            successTo={userRoutes.consent}
            suffix={`@${webConfigBrowser.lnDomain}`}
          />
        </AccessControlData>
      </Route>
      <Route exact path={userRoutes.consent}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          redirectInvalid={!usernameDisplay && userRoutes.username}
        >
          <TermsAndCookies logoutTo={logoutTo} successTo={userRoutes.acceptUpdates} {...sm} />
        </AccessControlData>
      </Route>
      <Route exact path={userRoutes.acceptUpdates}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <MarketingUpdates
            {...sm}
            nextAction={(): void => push(successTo)}
            nextActionDirty={(): void => window.location.assign(successTo)}
          />
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
