import { useCallback } from 'react';
import {
  useOptimisticUserInitOptions,
  useUpdateUserCurrencyUnitSettingMutation,
} from '@noah-labs/fe-shared-data-access-user';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import bitcoin from '@noah-labs/fe-shared-ui-assets/svg/icons/bitcoin.svg';
import { PreferredCryptoCurrencyForm } from '@noah-labs/fe-shared-ui-user';
import { CurrencyCode, CurrencyUnit } from '@noah-labs/shared-schema-gql';
import { useUserError } from '../hooks/useUserError';
import { UserSettingOptionsScene } from '../scenes/UserSettingOptions';

const items = [
  {
    label: 'Bitcoin',
    value: CurrencyCode.BTC,
  },
  {
    label: 'Satoshi',
    value: CurrencyUnit.SATS,
  },
];

export function CurrencyUnitSetting(): React.ReactElement {
  const { data: userData, error } = useUserInitUi();
  const updateOptions = useOptimisticUserInitOptions();
  const { error: mutateError, mutate } = useUpdateUserCurrencyUnitSettingMutation(updateOptions);
  const { ApiErrorScene } = useUserError(error || mutateError);

  const handleChange = useCallback(
    (value: CurrencyCode.BTC | CurrencyUnit) => {
      const unitValue = value === CurrencyCode.BTC ? CurrencyUnit.Default : value;
      mutate({ Input: { DisplayUnit: unitValue } });
    },
    [mutate],
  );

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  const displayUnitValue =
    userData?.userProfile.DisplayUnit === CurrencyUnit.SATS ? CurrencyUnit.SATS : CurrencyCode.BTC;

  return (
    <UserSettingOptionsScene
      CurrencyFormSlot={
        <PreferredCryptoCurrencyForm
          items={items}
          name="cryptoUnit"
          title="Select your preferred Bitcoin display units."
          value={displayUnitValue}
          onChange={handleChange}
        />
      }
      dataQa="user-settings-bitcoin-unit"
      headerSvg={bitcoin}
      pageTitle="Bitcoin unit"
    />
  );
}
