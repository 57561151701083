import type { ForwardedRef, MutableRefObject, ReactElement } from 'react';
import React, { forwardRef, useCallback, useState } from 'react';
import { css } from '@emotion/react';
import Popover from '@mui/material/Popover';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { generatePath } from '@noah-labs/fe-shared-ui-components';
import type { IMessage } from '@novu/notification-center';
import { ScreensEnum, useNotifications } from '@novu/notification-center';
import { EmptyMessage } from '../components/EmptyMessage';
import { Header } from '../components/Header';
import type { TpIMessage } from '../components/NotificationItem';
import { NotificationItem } from '../components/NotificationItem';
import type { RenderHeaderParams } from '../scenes/NotificationsCenter';
import { NotificationsCenter } from '../scenes/NotificationsCenter';

export type Section = 'notification-list' | 'user-preferences';

export type SetScreenFn = {
  (screen: ScreensEnum): void;
};

type NotificationsCenterControllerProps = {
  isNotificationCenterOpen: boolean;
  setIsNotificationCenterOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NotificationsCenterController = forwardRef(
  (
    { isNotificationCenterOpen, setIsNotificationCenterOpen }: NotificationsCenterControllerProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const theme = useTheme();

    const { markAllNotificationsAsSeen } = useNotifications();

    // Our theme needs to be wrapped in a function so it doesn't get merged with Novu's theme
    // See: https://emotion.sh/docs/theming#themeprovider-reactcomponenttype
    const themeCallback = useCallback(() => theme, [theme]);

    const [section, setSection] = useState<Section>('notification-list');

    const styles = {
      container: css`
        .MuiPopover-paper {
          overflow: hidden;

          ${theme.breakpoints.down('md')} {
            min-width: 100vw;
            max-width: 100vw;
            min-height: 100vh;
            max-height: 100vh;
            position: static;
            border-radius: 0;
          }
        }
      `,
    };

    function handleOpenSettingsClick({ setScreen }: { setScreen: SetScreenFn }): void {
      setScreen(ScreensEnum.SETTINGS);
      setSection('user-preferences');
    }

    function handleReturnClick({ setScreen }: { setScreen: SetScreenFn }): void {
      if (section === 'user-preferences') {
        setScreen(ScreensEnum.NOTIFICATIONS);
        setSection('notification-list');

        return;
      }

      markAllNotificationsAsSeen();
      setSection('notification-list');
      setIsNotificationCenterOpen(false);
    }

    function handleNotificationClick(message: TpIMessage): void {
      markAllNotificationsAsSeen();

      if (!message.cta.data.url) {
        return;
      }

      window.location.assign(generatePath(message.cta.data.url));
    }

    function renderNotificationItem(message: IMessage): ReactElement {
      return (
        // We need to wrap the NotificationItem in our theme provider otherwise the Novu's theme will be used
        <ThemeProvider theme={themeCallback}>
          <NotificationItem message={message} onClick={(m): void => handleNotificationClick(m)} />
        </ThemeProvider>
      );
    }

    function renderHeader(params: RenderHeaderParams): ReactElement {
      return (
        // We need to wrap the Header in our theme provider otherwise the Novu's theme will be used
        <ThemeProvider theme={themeCallback}>
          <Header
            showSettingsButton={section === 'notification-list'}
            title={section === 'notification-list' ? 'Notifications' : 'Notifications Settings'}
            onOpenSettingsClick={(): void => handleOpenSettingsClick(params)}
            onReturnClick={(): void => handleReturnClick(params)}
          />
        </ThemeProvider>
      );
    }

    function renderEmptyMessage(): ReactElement {
      return (
        // We need to wrap the EmptyMessage in our theme provider otherwise the Novu's theme will be used
        <ThemeProvider theme={themeCallback}>
          <EmptyMessage />
        </ThemeProvider>
      );
    }

    function handleClose(): void {
      markAllNotificationsAsSeen();
      setIsNotificationCenterOpen(false);
    }

    return (
      <Popover
        anchorEl={(ref as MutableRefObject<HTMLButtonElement>).current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        css={styles.container}
        id="notification-center-popover"
        open={isNotificationCenterOpen}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        transitionDuration={300}
        onClose={(): void => handleClose()}
      >
        <NotificationsCenter
          renderEmptyMessage={(): ReactElement => renderEmptyMessage()}
          renderHeader={(params): ReactElement => renderHeader(params)}
          renderNotificationItem={(message): ReactElement => renderNotificationItem(message)}
        />
      </Popover>
    );
  },
);

NotificationsCenterController.displayName = 'NotificationCenterController';
