import { useCallback, useState } from 'react';
import { useAuthError } from '@noah-labs/fe-shared-ui-auth';
import {
  AppContainer,
  AppFooter,
  AppHeader,
  AppMain,
  PrimaryButton,
  SceneHeader,
  SceneMain,
  SceneParagraph,
  SceneTitleLarge,
} from '@noah-labs/fe-shared-ui-components';
import { authRoutes } from '@noah-labs/fe-shared-util-routes';
import { Helmet } from 'react-helmet';
import type { UseFormSetError } from 'react-hook-form';
import { ResendVerificationCode } from '../components/ResendVerificationCode';

export type TpVerifyAccountForm = {
  root?: { serverError: void };
  verificationCode: string;
};

export type TpResendCode = {
  root?: { serverError: void };
};

export type PpVerifyEmailEmailSentScene = {
  email: string;
  helpButton?: React.ReactNode;
  loading: boolean;
  onRefresh: () => Promise<void>;
  onResend: (email: string, setError: UseFormSetError<TpResendCode>) => Promise<void>;
};

export function VerifyEmailEmailSentScene({
  email,
  helpButton,
  loading,
  onRefresh,
  onResend,
}: PpVerifyEmailEmailSentScene): React.ReactElement {
  const [isResending, setIsResending] = useState(false);
  const [error, setError] = useState<unknown>();

  useAuthError({ error });

  const onResendCallback = useCallback(async () => {
    try {
      setIsResending(true);
      await onResend(email, (_, e) => setError(e));
    } finally {
      setIsResending(false);
    }
  }, [email, onResend, setError]);

  return (
    <AppContainer>
      <Helmet>
        <title>Verify your email</title>
      </Helmet>
      <AppMain>
        <AppHeader backTo={authRoutes.signOut} endIconsSlot={helpButton} />
        <SceneHeader>
          <SceneTitleLarge>Verify your email</SceneTitleLarge>
          <SceneParagraph>
            To continue, please verify your email address by clicking on the link in your
            verification email and return to NOAH.
          </SceneParagraph>
        </SceneHeader>
        <SceneMain dataQa="verify-account">
          <ResendVerificationCode loading={isResending} onResend={onResendCallback} />
        </SceneMain>
      </AppMain>
      <AppFooter wide>
        <PrimaryButton
          color="primaryBrand"
          data-qa="continue-button"
          disabled={loading}
          loading={loading}
          onClick={onRefresh}
        >
          Continue
        </PrimaryButton>
      </AppFooter>
    </AppContainer>
  );
}
