import { useEffect } from 'react';
import {
  findDepositAddress,
  useAccountCreateMutation,
} from '@noah-labs/fe-shared-data-access-wallet';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import type { TpCryptoCurrencyUI, TpCryptoNetworkUi } from '@noah-labs/fe-shared-ui-shared';
import { ReceiveScene, useWalletError } from '@noah-labs/fe-shared-ui-wallet';
import { isLightningNetwork } from '@noah-labs/shared-currencies';
import type { AccountType } from '@noah-labs/shared-schema-gql';

type PpReceiveController = {
  AccountType: AccountType;
  cryptoCurrency: TpCryptoCurrencyUI;
  lightningInvoiceHref: string;
  network: TpCryptoNetworkUi;
};
export function ReceiveController({
  AccountType,
  cryptoCurrency,
  lightningInvoiceHref,
  network,
}: PpReceiveController): React.ReactElement {
  const { data: userData } = useUserInitUi();

  const {
    data: accountCreateData,
    error: accountCreateErr,
    isIdle: accountCreateIdle,
    isLoading: accountCreateLoading,
    mutate: accountCreate,
  } = useAccountCreateMutation();
  const address = findDepositAddress(accountCreateData?.accountCreate);
  const lnAddress = userData?.userProfile.lnAddress;

  useEffect(() => {
    // If accountCreate is running or there is already an address, don't do anything
    if (!accountCreateIdle || address || isLightningNetwork(network)) {
      return;
    }
    // Otherwise, create a new account
    accountCreate({
      Input: {
        AccountType,
        CurrencyCode: cryptoCurrency.code,
        DepositAddressRequest: {
          Create: true,
          Network: network.id,
        },
      },
    });
  }, [AccountType, cryptoCurrency.code, accountCreate, accountCreateIdle, address, network]);

  const { ApiErrorScene } = useWalletError(accountCreateErr);

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <ReceiveScene
      address={address}
      cryptoCurrency={cryptoCurrency}
      isAddressLoading={accountCreateLoading}
      lightningInvoiceHref={lightningInvoiceHref}
      lnAddress={lnAddress}
      network={network}
    />
  );
}
