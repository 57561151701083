import React from 'react';
import { Stack } from '@mui/material';
import { TpAnalyticsEvent, useAnalytics } from '@noah-labs/fe-shared-feature-analytics';
import {
  AddressWithCopy,
  AppContainer,
  AppMain,
  PrimaryButton,
  SceneHeader,
  SceneMain,
  SceneParagraph,
  SceneTitleLarge,
  useShare,
} from '@noah-labs/fe-shared-ui-components';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';
import { routes } from '../routes';

export type PpInviteFriendsScene = {
  referralUrl: string | undefined;
};

export function InviteFriendsScene({ referralUrl }: PpInviteFriendsScene): React.ReactElement {
  const { handleSharing, isSupported } = useShare({ text: referralUrl });
  const { track } = useAnalytics();

  const ShareButton = isSupported ? (
    <PrimaryButton disabled={!referralUrl} onClick={handleSharing}>
      Share Referral Link
    </PrimaryButton>
  ) : undefined;

  return (
    <AppContainer>
      <Helmet>
        <title>Invite friends</title>
      </Helmet>
      <AppMain>
        <AppHeaderData helpButton backTo={routes.base} />
        <SceneHeader>
          <SceneTitleLarge>Invite friends, earn points!</SceneTitleLarge>
          <SceneParagraph>
            Earn NOAH points and redeem for rewards. Copy your code and share with friends!
          </SceneParagraph>
        </SceneHeader>
        <SceneMain dataQa="invite-friends">
          <Stack spacing={3.5}>
            <AddressWithCopy
              address={referralUrl}
              truncate={false}
              onCopy={(): void => track(TpAnalyticsEvent.ReferralLinkCopied)}
            />
            {ShareButton}
          </Stack>
        </SceneMain>
      </AppMain>
    </AppContainer>
  );
}
