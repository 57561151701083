import React, { createContext, useMemo, useState } from 'react';
import type { TpAddressData } from '@noah-labs/fe-shared-ui-components/crypto';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';

export type PpAddressContext = {
  data: TpAddressData | undefined;
  scannerUnavailable: boolean;
  setData: (data: TpAddressData | undefined) => void;
  setScannerUnavailable: (scannerUnavailable: boolean) => void;
};

export const AddressContext = createContext({} as PpAddressContext);

export function AddressProvider({ children }: PpWC): React.ReactElement {
  const [data, setData] = useState<TpAddressData | undefined>();
  const [scannerUnavailable, setScannerUnavailable] = useState(false);

  const value = useMemo(
    () => ({
      data,
      scannerUnavailable,
      setData,
      setScannerUnavailable,
    }),
    [data, scannerUnavailable],
  );

  return <AddressContext.Provider value={value}>{children}</AddressContext.Provider>;
}
