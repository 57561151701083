import { useEffect, useState } from 'react';
import { generatePath, Switch404 } from '@noah-labs/fe-shared-ui-components';
import { TpAuthStatus, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Route } from 'react-router-dom';
import { AccessControlData } from '../../../auth/AccessControlData';
import { useAddress } from '../../hooks/useAddress';
import { AddressManual } from './AddressManual';
import { AddressScan } from './AddressScan';

export function AddressRouter(): React.ReactElement {
  const { params } = useWalletParams();
  const { scannerUnavailable, setData } = useAddress();
  const [scannedAddress, setScannedAddress] = useState('');

  useEffect(() => () => setData(undefined), [setData]);

  return (
    <Switch404>
      <Route exact path={walletRoutes().address.scan}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          redirectInvalid={scannerUnavailable && generatePath(walletRoutes().address.text, params)}
        >
          <AddressScan
            handleScannedAddress={(address: string): void => setScannedAddress(address)}
          />
        </AccessControlData>
      </Route>
      <Route exact path={walletRoutes().address.text}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <AddressManual scannedAddress={scannedAddress} />
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
