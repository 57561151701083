import { useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Divider, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCountryFromCode } from '@noah-labs/fe-shared-data-access-countries';
import { useCurrenciesForSelect } from '@noah-labs/fe-shared-data-access-currencies';
import { getAllowedBankCurrencies } from '@noah-labs/fe-shared-data-access-wallet';
import type { TpSelectOption } from '@noah-labs/fe-shared-ui-components';
import {
  AppContainer,
  AppFooter,
  AppHeaderTitle,
  AppMain,
  DialogSelectField,
  InputField,
  PrimaryButton,
  SceneHeader,
  SceneMain,
  SceneParagraph,
  Switch,
} from '@noah-labs/fe-shared-ui-components';
import type { defaultBankFormSchema } from '@noah-labs/fe-shared-util-validation-schemas';
import type { CountryCode } from '@noah-labs/shared-schema-gql';
import { compareStrings } from '@noah-labs/shared-util-vanilla';
import { Helmet } from 'react-helmet';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import type { z } from 'zod';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';
import { BillingAddressesForm } from '../components/forms/BillingAddresses/BillingAddressesForm';
import type { TpBillingAddressForm } from '../components/forms/BillingAddresses/schema';
import { emptyBillingAddress } from '../components/forms/BillingAddresses/schema';

export type TpAccountDetailsFormValues = z.infer<typeof defaultBankFormSchema>;

const defaults: TpAccountDetailsFormValues = {
  accountCurrency: '',
  accountCurrencyName: '',
  accountHolderName: '',
  accountNumber: '',
  bankCode: '',
  billingAddress: emptyBillingAddress,
  saveAccount: true,
};

const accountDetailsFormId = 'accountDetailsFormId';

export type PpEnterAccountDetailsScene = {
  FormSlot: React.ReactElement;
  countries: TpSelectOption[] | undefined;
  defaultAddress: TpBillingAddressForm | undefined | null;
  onSubmit: SubmitHandler<TpAccountDetailsFormValues>;
  pageTitle: string;
  schema: z.ZodSchema<TpAccountDetailsFormValues>;
  selectedCountry: CountryCode | undefined;
};

export function EnterAccountDetailsScene({
  countries,
  defaultAddress,
  FormSlot,
  onSubmit,
  pageTitle,
  schema,
  selectedCountry,
}: PpEnterAccountDetailsScene): React.ReactElement {
  const theme = useTheme();
  const { data: allCurrenciesOptions, isFetched: currenciesFetched } = useCurrenciesForSelect();
  const countryData = useCountryFromCode(selectedCountry);
  const countryCurrencyCode = countryData?.currency.split(',')[0];

  const supportedCurrencyOptions = useMemo(() => {
    if (!allCurrenciesOptions) {
      return [];
    }
    const allowedBankCurrencies = getAllowedBankCurrencies();
    return allCurrenciesOptions.filter((currency) => allowedBankCurrencies[currency.value]);
  }, [allCurrenciesOptions]);

  const defaultValues = useMemo(() => {
    const accountCurrency = supportedCurrencyOptions.find(({ value }) =>
      compareStrings(countryCurrencyCode, value),
    );

    return {
      ...defaults,
      accountCurrency: accountCurrency?.value || defaults.accountCurrency,
      accountCurrencyName: accountCurrency?.label || defaults.accountCurrencyName,
      billingAddress: defaultAddress || defaults.billingAddress,
    };
  }, [defaultAddress, countryCurrencyCode, supportedCurrencyOptions]);

  const styles = {
    saveAccount: css`
      margin-top: ${theme.spacing(3)};
      justify-content: space-between;
    `,
  };

  const methods = useForm<TpAccountDetailsFormValues>({
    defaultValues,
    mode: 'onBlur',
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
    resolver: zodResolver(schema),
    values: defaultValues,
  });

  const handleCurrencyChange = useCallback(
    (value: TpSelectOption | null) => {
      methods.setValue('accountCurrency', value?.value || '', { shouldValidate: true });
      methods.setValue('accountCurrencyName', value?.label || '');
    },
    [methods],
  );

  return (
    <AppContainer>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <AppMain>
        <AppHeaderData backButton exitButton helpButton>
          <AppHeaderTitle>{pageTitle}</AppHeaderTitle>
        </AppHeaderData>
        <SceneHeader>
          <SceneParagraph>
            Your funds will be sent directly to your account in your local currency after selling
            your bitcoin.
          </SceneParagraph>
        </SceneHeader>
        <SceneMain dense dataQa="account-details">
          <FormProvider {...methods}>
            <form id={accountDetailsFormId} onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack spacing={3}>
                <Stack spacing={2}>
                  <DialogSelectField
                    required
                    dataQa="account-currency"
                    isFetched={currenciesFetched}
                    label="Account currency"
                    name="accountCurrencyName"
                    options={supportedCurrencyOptions}
                    onChange={handleCurrencyChange}
                  />
                  <InputField
                    fullWidth
                    required
                    dataQa="account-holder"
                    label="Name of account holder"
                    name="accountHolderName"
                  />
                  {FormSlot}
                </Stack>

                <Divider sx={{ flexBasis: '100%' }} />

                <Stack spacing={3}>
                  <Stack spacing={1}>
                    <Typography variant="paragraphBodyLBold">Account holder address</Typography>
                    <SceneParagraph>
                      This is the address associated to your bank account.
                    </SceneParagraph>
                  </Stack>

                  <BillingAddressesForm countries={countries} defaultAddress={defaultAddress} />
                </Stack>

                <Switch
                  dataQa="save-card"
                  inputCss={styles.saveAccount}
                  label={
                    <Typography color="text.light" variant="paragraphBodyM">
                      Save details for future payments?
                    </Typography>
                  }
                  labelPlacement="start"
                  name="saveAccount"
                />
              </Stack>
            </form>
          </FormProvider>
        </SceneMain>
      </AppMain>
      <AppFooter>
        <PrimaryButton
          color="primaryBrand"
          data-qa="save-account-details-button"
          disabled={!methods.formState.isValid}
          form={accountDetailsFormId}
          loading={methods.formState.isSubmitting}
          type="submit"
        >
          Save
        </PrimaryButton>
      </AppFooter>
    </AppContainer>
  );
}
