import type { TpBillingAddressSchema } from '@noah-labs/fe-shared-util-validation-schemas';

export type TpBillingAddressForm = TpBillingAddressSchema & {
  CountryName: string;
};

export const fieldNames = {
  City: 'billingAddress.City',
  Country: 'billingAddress.Country',
  CountryName: 'billingAddress.CountryName',
  PostCode: 'billingAddress.PostCode',
  State: 'billingAddress.State',
  Street: 'billingAddress.Street',
  Street2: 'billingAddress.Street2',
};

export const emptyBillingAddress: TpBillingAddressForm = {
  City: '',
  Country: '',
  CountryName: '',
  PostCode: '',
  State: '',
  Street: '',
  Street2: '',
};
