import { Fragment } from 'react';
import { useTheme } from '@mui/material/styles';
import { useIsFeatureEnabled } from '@noah-labs/fe-shared-feature-user';
import type { PpAppHeader } from '@noah-labs/fe-shared-ui-components';
import { AppHeader } from '@noah-labs/fe-shared-ui-components';
import type { PpWOC } from '@noah-labs/fe-shared-ui-shared';
import { Feature } from '@noah-labs/shared-schema-gql';
import { NovuProvider } from '../../modules/notifications/providers/NovuProvider';
import { webConfigBrowser } from '../../webConfigBrowser';
import { AvatarIconButtonData } from '../buttons/AvatarIconButtonData';
import { ExitIconButtonData } from '../buttons/ExitIconButtonData';
import { HelpIconButtonData } from '../buttons/HelpIconButtonData';
import { NotificationCenterButtonData } from '../buttons/NotificationCenterButtonData';
import { ScanIconButtonData } from '../buttons/ScanIconButtonData';

export type PPAppHeaderData = Omit<PpAppHeader, 'endIconsSlot'> & {
  avatarButton?: boolean;
  exitButton?: boolean;
  helpButton?: boolean;
  notificationCenterButton?: boolean;
  scanButton?: boolean;
} & PpWOC;
export function AppHeaderData({
  avatarButton = false,
  children,
  exitButton = false,
  fullWidth,
  helpButton = false,
  notificationCenterButton = false,
  scanButton = false,
  ...rest
}: PPAppHeaderData): React.ReactElement {
  // helpButton needs edge=end, unless there is an exitButton, scanButton or avatarButton
  // exitButton needs edge=end, unless there is an scanButtonm or avatarButton
  // scanButton needs edge=end, unless there is an avatarButton

  const theme = useTheme();

  const avatarMarginLeft =
    helpButton || notificationCenterButton || exitButton || scanButton
      ? theme.spacing(0.625)
      : undefined;

  const notificationsEnabled = useIsFeatureEnabled(Feature.Notifications);

  return (
    <AppHeader
      fullWidth={fullWidth}
      {...rest}
      endIconsSlot={
        <Fragment>
          {helpButton && (
            <HelpIconButtonData
              edge={
                exitButton || scanButton || avatarButton || notificationCenterButton
                  ? undefined
                  : 'end'
              }
            />
          )}
          {notificationsEnabled && (
            <NovuProvider applicationId={webConfigBrowser.novu.applicationId}>
              {notificationCenterButton && (
                <NotificationCenterButtonData
                  edge={exitButton || scanButton || avatarButton ? undefined : 'end'}
                />
              )}
            </NovuProvider>
          )}
          {exitButton && (
            <ExitIconButtonData edge={scanButton || avatarButton ? undefined : 'end'} />
          )}
          {scanButton && <ScanIconButtonData edge={avatarButton ? undefined : 'end'} />}
          {avatarButton && (
            <AvatarIconButtonData showLnAddress={fullWidth} sx={{ ml: avatarMarginLeft, p: 0 }} />
          )}
        </Fragment>
      }
    >
      {children}
    </AppHeader>
  );
}
