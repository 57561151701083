import { useCallback } from 'react';
import {
  getOryCsrfToken,
  isNoSecurityKeyError,
  isRedirectBrowserError,
  useOry,
} from '@noah-labs/fe-shared-data-access-auth';
import { logger } from '@noah-labs/shared-logger/browser';
import type { RegistrationFlow } from '@ory/client';
import type { AxiosError } from 'axios';
import type { PpSignInScene } from '../scenes/SignInScene';

export function useOryHasSecurityKeyCallback(): PpSignInScene['getHasSecurityKey'] {
  const { ory } = useOry();

  return useCallback(
    async (email): Promise<boolean> => {
      try {
        const { data: flow } = await ory.createBrowserLoginFlow();

        const csrfToken = getOryCsrfToken(flow.ui.nodes);

        await ory.updateLoginFlow({
          flow: flow.id,
          updateLoginFlowBody: {
            csrf_token: csrfToken,
            identifier: email,
            method: 'webauthn',
          },
        });
        return false;
      } catch (error: unknown) {
        const oryError = error as AxiosError<RegistrationFlow>;
        if (isRedirectBrowserError(oryError)) {
          return true;
        }
        if (isNoSecurityKeyError(oryError)) {
          return false;
        }

        logger.error(error);
        return false;
      }
    },
    [ory],
  );
}
