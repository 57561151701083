import { Box, Stack } from '@mui/material';
import { TpAnalyticsEvent, useAnalytics } from '@noah-labs/fe-shared-feature-analytics';
import {
  AddressWithCopy,
  AppFooter,
  AppMain,
  Balance,
  PrimaryButton,
  QRCodeWithImage,
  SceneMain,
  useShare,
} from '@noah-labs/fe-shared-ui-components';
import type { TpAddressData } from '@noah-labs/fe-shared-ui-components/src/mui/tools/parseAddressData';
import { type TpCryptoCurrencyUI, type TpFiatCurrencyUI } from '@noah-labs/fe-shared-ui-shared';
import { assetPaths } from '@noah-labs/shared-constants';
import type { TpAmount } from '@noah-labs/shared-currencies';
import type { CurrencyDisplayType, CurrencyUnit } from '@noah-labs/shared-schema-gql';
import { AlertTransactionTime } from '../alerts/AlertTransactionTime';
import { TransactionSummary } from '../components/transactions/TransactionSummary';
import { getCurrencyAmountSlots } from '../utils/getCurrencyAmountSlots';

export type PpQRDisplayScene = {
  addressData?: Partial<TpAddressData>;
  cryptoAmount?: TpAmount;
  cryptoCurrency?: TpCryptoCurrencyUI;
  cryptoUnit: CurrencyUnit | undefined;
  faqLink?: string;
  fiatAmount?: TpAmount;
  fiatCurrency?: TpFiatCurrencyUI;
  primaryCurrency: CurrencyDisplayType | undefined;
  transactionId?: string;
};

export function QRDisplayScene({
  addressData,
  cryptoAmount,
  cryptoCurrency,
  cryptoUnit,
  faqLink,
  fiatAmount,
  fiatCurrency,
  primaryCurrency,
  transactionId,
}: PpQRDisplayScene): React.ReactElement {
  const Amounts = getCurrencyAmountSlots({
    cryptoAmount,
    cryptoCurrency,
    cryptoUnit,
    fiatAmount,
    fiatCurrency,
    primaryCurrency,
  });
  const { handleSharing, isSupported } = useShare({ text: addressData?.address });
  const { track } = useAnalytics();
  return (
    <AppMain>
      <SceneMain dense dataQa="qr-display">
        <Stack spacing={2}>
          <TransactionSummary
            dataQa={{ publicId: transactionId }}
            description={addressData?.description}
            expiryTime={addressData?.expiryTime}
          >
            {/* need to wrap in a div so we can apply -ve margin that is not removed by the stack */}
            <div>
              <AddressWithCopy
                address={addressData?.address}
                variant="text"
                onCopy={(): void => track(TpAnalyticsEvent.LNInvoiceUrlCopied)}
              />
            </div>
            <Box maxWidth="300px">
              <QRCodeWithImage
                data={addressData?.address}
                height={300}
                imageUrl={`${assetPaths.images}/logo-icon-black.svg`}
                width={300}
              />
            </Box>
            {cryptoAmount && <Balance {...Amounts} />}
          </TransactionSummary>
          {addressData?.processingTime && (
            <AlertTransactionTime delay={addressData.processingTime} faqLink={faqLink} />
          )}
        </Stack>
        {isSupported && (
          <AppFooter>
            <PrimaryButton data-qa="share-button" onClick={handleSharing}>
              Share QR Code
            </PrimaryButton>
          </AppFooter>
        )}
      </SceneMain>
    </AppMain>
  );
}
