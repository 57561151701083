import {
  AppContainer,
  AppHeader,
  AppLogo,
  AppMain,
  PrimaryButton,
  SceneHeader,
  SceneMain,
  SceneParagraph,
  SceneTitleLarge,
} from '@noah-labs/fe-shared-ui-components';

type PpSignedOut = {
  signinUrl: string;
};

export function SignedOutScene({ signinUrl }: PpSignedOut): React.ReactElement {
  return (
    <AppContainer>
      <AppMain>
        <AppHeader>
          <AppLogo css={{ margin: '0 auto' }} />
        </AppHeader>
        <SceneHeader textAlign="center">
          <SceneTitleLarge>You have been signed&nbsp;out</SceneTitleLarge>
          <SceneParagraph>
            Sorry, your session has expired, you have been signed out for security reasons. Please
            log in again to continue.
          </SceneParagraph>
        </SceneHeader>
        <SceneMain dataQa="signed-out">
          <PrimaryButton color="primaryBrand" data-qa="signin-button" href={signinUrl}>
            Login
          </PrimaryButton>
        </SceneMain>
      </AppMain>
    </AppContainer>
  );
}
