import { CryptoAmount, FiatAmount } from '@noah-labs/fe-shared-ui-components';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-shared';
import type { TpAmount, TpFiatCurrency } from '@noah-labs/shared-currencies';
import type { CurrencyUnit } from '@noah-labs/shared-schema-gql';
import { CurrencyDisplayType } from '@noah-labs/shared-schema-gql';
import { isNonZero } from '@noah-labs/shared-util-vanilla';

export type TpCurrencyAmountSlots = {
  PrimaryAmountSlot: React.ReactElement | undefined;
  SecondaryAmountSlot: React.ReactElement | undefined;
};

export type PpGetCurrencyAmountSlots = {
  cryptoAmount: TpAmount;
  cryptoCurrency: TpCryptoCurrencyUI | undefined;
  cryptoUnit: CurrencyUnit | undefined;
  fiatAmount: TpAmount;
  fiatCurrency: TpFiatCurrency | undefined;
  isCryptoApprox?: boolean;
  isFiatApprox?: boolean;
  nonZeroCrypto?: boolean;
  nonZeroFiat?: boolean;
  primaryCurrency: CurrencyDisplayType | undefined;
  roundDown?: boolean;
  signDisplay?: Intl.NumberFormatOptions['signDisplay'];
  strikeThrough?: boolean;
};

export function getCurrencyAmountSlots({
  cryptoAmount,
  cryptoCurrency,
  cryptoUnit,
  fiatAmount,
  fiatCurrency,
  isCryptoApprox,
  isFiatApprox,
  nonZeroCrypto = false,
  nonZeroFiat = false,
  primaryCurrency,
  roundDown,
  signDisplay,
  strikeThrough = false,
}: PpGetCurrencyAmountSlots): TpCurrencyAmountSlots {
  const CA = (
    <CryptoAmount
      amount={cryptoAmount}
      approx={isCryptoApprox}
      currency={cryptoCurrency}
      currencyUnit={cryptoUnit}
      fallback={cryptoCurrency && `Confirming ${cryptoCurrency.code}`}
      fallbackCheck={(value): boolean => nonZeroCrypto && !isNonZero(value)}
      roundDown={roundDown}
      signDisplay={signDisplay}
      strikeThrough={strikeThrough}
    />
  );

  const FA = (
    <FiatAmount
      amount={fiatAmount}
      approx={isFiatApprox}
      currency={fiatCurrency}
      fallback={fiatCurrency && `Confirming ${fiatCurrency.code}`}
      fallbackCheck={(value): boolean => nonZeroFiat && !isNonZero(value)}
      roundDown={roundDown}
      signDisplay={signDisplay}
      strikeThrough={strikeThrough}
    />
  );

  if (primaryCurrency === CurrencyDisplayType.Crypto) {
    return {
      PrimaryAmountSlot: CA,
      SecondaryAmountSlot: FA,
    };
  }

  return {
    PrimaryAmountSlot: FA,
    SecondaryAmountSlot: CA,
  };
}
