import type { ReactElement } from 'react';
import { css } from '@emotion/react';
import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SvgEmptyBox } from '@noah-labs/fe-shared-ui-assets/images/EmptyBox';
import { cssMixins } from '@noah-labs/fe-shared-ui-components';
import {
  headerHeight,
  notificationListDesktopHeight,
  notificationListMobileHeight,
} from '../constants';

export function EmptyMessage(): ReactElement {
  const theme = useTheme();

  const styles = {
    container: css`
      ${cssMixins.colCentered};

      min-height: calc(${notificationListMobileHeight} - ${headerHeight});

      ${theme.breakpoints.up('md')} {
        min-height: calc(${notificationListDesktopHeight} - ${headerHeight});
      }
    `,
  };

  return (
    <Stack css={styles.container}>
      <SvgEmptyBox height={88} />
      <Box marginTop={theme.spacing(4)} textAlign="center">
        <Typography variant="subHeadingS">No notifications yet</Typography>
        <Typography variant="paragraphBodyM">
          We will let you know when we get news for you
        </Typography>
      </Box>
    </Stack>
  );
}
