import React from 'react';
import { Switch404 } from '@noah-labs/fe-shared-ui-components';
import { TpAuthStatus } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Route } from 'react-router-dom';
import { AccessControlData } from '../auth/AccessControlData';
import { KycAccessControlData } from '../kyc/controllers/KycAccessControlData';
import { KycReminderOrigin } from '../kyc/controllers/Reminder';
import { AccountRouter } from './controllers/account/AccountRouter';
import { Accounts } from './controllers/account/Accounts';
import { AddressRouter } from './controllers/address/AddressRouter';
import { BuyRouter } from './controllers/buy/BuyRouter';
import { LightningSendRouter } from './controllers/lightning-send/LightningSendRouter';
import { ReceiveRouter } from './controllers/receive/ReceiveRouter';
import { SelectCryptoRouter } from './controllers/select-crypto/SelectCryptoRouter';
import { SellRouter } from './controllers/sell/SellRouter';
import { WithdrawRouter } from './controllers/withdraw/WithdrawRouter';
import { AddressProvider } from './hooks/AddressProvider';

export function Router(): React.ReactElement {
  return (
    <AddressProvider>
      <Switch404>
        <Route exact path={walletRoutes().base}>
          <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
            <Accounts />
          </AccessControlData>
        </Route>
        <Route path={walletRoutes().selectCrypto.base}>
          <SelectCryptoRouter />
        </Route>
        <Route path={walletRoutes().receive.base}>
          <KycAccessControlData origin={KycReminderOrigin.Deposit}>
            <ReceiveRouter />
          </KycAccessControlData>
        </Route>
        <Route path={walletRoutes().lightningSend.base}>
          {/* Can't wrap the whole LightningSendRouter in KycAccessControlData because a user who has just sent all, needs access to the complete screen */}
          <LightningSendRouter />
        </Route>
        <Route path={walletRoutes().withdraw.base}>
          {/* Can't wrap the whole WithdrawRouterin KycAccessControlData because a user who has just withdrawn all, needs access to the complete screen */}
          <WithdrawRouter />
        </Route>
        <Route path={walletRoutes().buy.base}>
          <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
            <BuyRouter />
          </AccessControlData>
        </Route>
        <Route path={walletRoutes().sell.base}>
          <SellRouter />
        </Route>
        <Route path={walletRoutes().address.base}>
          <AddressRouter />
        </Route>
        <Route path={walletRoutes().account.base}>
          <AccountRouter />
        </Route>
      </Switch404>
    </AddressProvider>
  );
}
