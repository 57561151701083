import { useCallback, useMemo, useState } from 'react';
import { useSigning } from '@noah-labs/fe-shared-feature-signing';
import { LoadingPage } from '@noah-labs/fe-shared-ui-components';
import { useWalletError } from '@noah-labs/fe-shared-ui-wallet';
import { isUndefinedOrNull } from '@noah-labs/shared-util-vanilla';
import { useLocation } from 'react-router-dom';
import { PinSuccessScene } from '../scenes/PinSuccess';
import { RevokePinScene } from '../scenes/RevokePin';

export type PpRevokePin = {
  successTo: string;
};

export type TpParams = {
  documentType: string;
  revoke: boolean;
};

function getParams(search: string): TpParams {
  const params = new URLSearchParams(search);
  const documentType = params.get('documentType');
  const revoke = params.get('revoke');

  if (documentType !== 'NonCustodyKey') {
    throw new Error('Invalid document type');
  }

  return {
    documentType,
    revoke: revoke ? JSON.parse(revoke) : false,
  };
}

export function RevokePin({ successTo }: PpRevokePin): React.ReactElement {
  const { isJwtFetched, isRevokeLoading, revoke: revokeFn, sdStatus } = useSigning();
  const { search } = useLocation();

  const [actioned, setActioned] = useState(false);
  const [error, setError] = useState<unknown>();
  const { ApiErrorScene } = useWalletError(error, true);

  const params = useMemo(() => {
    try {
      return getParams(search);
    } catch (err) {
      setError(err);
      return undefined;
    }
  }, [search]);

  const isServiceReady = isJwtFetched && !isUndefinedOrNull(sdStatus);

  const onRevokePin = useCallback(async () => {
    if (!params) {
      return;
    }

    const { documentType, revoke } = params;

    if (documentType !== 'NonCustodyKey') {
      setError('Invalid document type');
      return;
    }

    try {
      await revokeFn({ documentType, revoke });
      setActioned(true);
    } catch (err) {
      setError(err);
    }
  }, [params, revokeFn]);

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  if (!isServiceReady) {
    return <LoadingPage />;
  }

  if (actioned) {
    return (
      <PinSuccessScene
        pageTitle="PIN Revocation Started"
        sceneDescription="Your PIN will be revoked in 7 days. You will be able to create a new PIN when you login after the time has expired."
        sceneTitle="PIN Revocation Started"
        successTo={successTo}
      />
    );
  }

  return <RevokePinScene isLoading={isRevokeLoading} onRevokePin={onRevokePin} />;
}
