import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { useInAppReviewTrigger } from '@noah-labs/fe-shared-feature-wallet';
import type { TpStateMachine } from '@noah-labs/fe-shared-ui-components';
import { AppContainer, Balance, generatePath } from '@noah-labs/fe-shared-ui-components';
import { cryptoCurrencyFromCode, useRouter, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { CompleteScene, getCurrencyAmountSlots } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { AppHeaderData } from '../../../../components/layout/AppHeaderData';
import type { StLightningSendRouter } from './types';

export function Complete({
  resetState,
  state,
}: TpStateMachine<StLightningSendRouter>): React.ReactElement {
  const { CurrencyCode, params } = useWalletParams();
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);
  const { data: userData } = useUserInitUi();
  const { useOnRouteChange } = useRouter();

  /**
   * End of the flow so reset the flow state machine
   */
  useOnRouteChange(resetState);

  const Amounts = getCurrencyAmountSlots({
    cryptoAmount: state.cryptoAmount,
    cryptoCurrency,
    cryptoUnit: userData?.userProfile.DisplayUnit,
    fiatAmount: state.fiatAmount,
    fiatCurrency: userData?.userProfile.fiatCurrency,
    primaryCurrency: userData?.userProfile.PrimaryCurrency,
  });

  const paymentHash = state.paymentRequestData?.paymentHash;

  const enableInAppReviewTrigger = !!paymentHash;
  useInAppReviewTrigger(enableInAppReviewTrigger);

  return (
    <AppContainer headTitle="Payment Processing">
      <AppHeaderData exitButton helpButton />
      <CompleteScene
        BalanceSlot={<Balance {...Amounts} />}
        processingTimeMs={state.paymentRequestData?.processingTime}
        transactionUrl={generatePath(walletRoutes().account.base, params)}
      />
    </AppContainer>
  );
}
