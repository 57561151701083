import { useCallback } from 'react';
import { useAmountsWithFee, useFiatPayoutMutation } from '@noah-labs/fe-shared-data-access-wallet';
import type { TpOnSign } from '@noah-labs/fe-shared-feature-signing';
import { useSignWithdrawal } from '@noah-labs/fe-shared-feature-signing';
import type { TpStateMachine } from '@noah-labs/fe-shared-ui-components';
import { CryptoAmount, FiatAmount, generatePath } from '@noah-labs/fe-shared-ui-components';
import {
  cryptoCurrencyFromCode,
  TpPaymentMethod,
  useRouter,
  useWalletParams,
} from '@noah-labs/fe-shared-ui-shared';
import { useWalletError } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { TpCheckoutPayoutStatus } from '@noah-labs/fe-shared-util-shared';
import type { TpSlippage } from '@noah-labs/shared-currencies';
import { getManualRampCurrencyConfig } from '@noah-labs/shared-currencies';
import { FiatPaymentType } from '@noah-labs/shared-schema-gql';
import { duration, isUndefinedOrNull } from '@noah-labs/shared-util-vanilla';
import { useMutation } from 'react-query';
import { webConfigBrowser } from '../../../../webConfigBrowser';
import { useFiatCurrencyFromCode } from '../../../user/data/useFiatCurrencyFromCode';
import { useKycNotApprovedAndCheckoutEnabled } from '../../../user/hooks/useKycNotApprovedAndCheckoutEnabled';
import { ConfirmSellScene } from '../../scenes/ConfirmSell';
import { getPayoutTansferDestinationType } from '../../utils/utils';
import type { StSellRouter } from './SellRouter';

const {
  cko: { feeMinimumFiatAmount, feePercentage, slippagePercentage },
  manualRamp,
} = webConfigBrowser;

const checkoutFees = {
  feeMinimumFiatAmount,
  feePercentage,
};

const slippage: TpSlippage = {
  slippage: slippagePercentage,
  type: 'negative',
};

type PpConfirm = TpStateMachine<StSellRouter>;

export function Confirm({ state, updateState }: PpConfirm): React.ReactElement {
  const { AccountType, CurrencyCode, params } = useWalletParams();
  const { push } = useRouter();
  const kycRequired = useKycNotApprovedAndCheckoutEnabled();

  const { error: fiatPayoutError, mutateAsync: mutateFiatPayout } = useFiatPayoutMutation();

  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);
  const fiatCurrency = useFiatCurrencyFromCode(state.fiatCurrencyCode);

  const isBankPayoutMethod = state.payoutMethod === TpPaymentMethod.BankTransfer;

  const manualRampCurrencyConfig = getManualRampCurrencyConfig({
    config: manualRamp,
    fiatCurrencyCode: state.fiatCurrencyCode,
  });
  const currencyData = isBankPayoutMethod && manualRampCurrencyConfig;
  const fees = currencyData
    ? {
        feeMinimumFiatAmount: currencyData.FeeMinimumFiatAmount,
        feePercentage: currencyData.FeePercentage,
      }
    : checkoutFees;

  const { feeFiatAmount, fetchedAt, netFiatAmount, price, totalCryptoAmount } = useAmountsWithFee({
    cryptoCurrency,
    fees,
    fiatAmount: state.fiatAmount,
    fiatCurrency,
    isAmountNet: true,
    options: {
      refetchInterval: duration.seconds(3),
    },
    priceProvider: 'sell',
    slippage,
  });

  /**
   * Submits the payment request
   */
  const { isLoading: isSubmittingPayment, mutateAsync: submitPayout } = useMutation(
    async ({ signature }: TpOnSign) => {
      if (!price || !fetchedAt) {
        return;
      }

      const paymentId =
        state.payoutMethod === TpPaymentMethod.Card
          ? state.selectedPayoutCard?.id
          : state.selectedPayoutBank?.id;

      try {
        const { fiatPayout } = await mutateFiatPayout({
          Input: {
            MaximumDeduction: totalCryptoAmount,
            PaymentDetails: {
              ID: paymentId,
              Type: FiatPaymentType.FiatPaymentMethodID,
            },
            RequestedAmount: {
              Amount: netFiatAmount,
              FetchedAt: fetchedAt,
              FiatCurrency: fiatCurrency.code,
              Price: price,
            },
            ...(signature && { Nonce: signature.nonce, Signature: signature.signature }),
            SourceCurrency: cryptoCurrency.code,
          },
        });

        updateState({
          transactionId: fiatPayout.Entries[0].EventId,
        });

        if (fiatPayout.FailedEntryCount) {
          push(
            generatePath(walletRoutes().sell.complete, {
              ...params,
              payoutStatus: TpCheckoutPayoutStatus.failure,
            }),
          );
          return;
        }

        push(
          generatePath(walletRoutes().sell.complete, {
            ...params,
            payoutStatus: TpCheckoutPayoutStatus.pending,
          }),
        );
      } catch (e) {
        // useWalletError handles error
      }
    },
  );

  const { loading: signLoading, sign } = useSignWithdrawal({
    payload: {
      AccountType,
      Amount: totalCryptoAmount,
      CurrencyCode,
      Destination: getPayoutTansferDestinationType(state.payoutMethod),
      inputType: 'payout',
      RequestedAmount: {
        Amount: netFiatAmount,
        FiatCurrency: fiatCurrency.code,
      },
    },
  });

  const readyForPayment = !signLoading && !isUndefinedOrNull(fetchedAt);

  const onConfirm = useCallback(async () => {
    await sign(submitPayout);
  }, [sign, submitPayout]);

  const { ApiErrorScene } = useWalletError(fiatPayoutError);
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <ConfirmSellScene
      cryptoCurrency={cryptoCurrency}
      CryptoPriceSlot={<FiatAmount amount={price} currency={fiatCurrency} />}
      ctaDisabled={!readyForPayment}
      FeeAmountSlot={<FiatAmount amount={feeFiatAmount} currency={fiatCurrency} />}
      fiatCurrency={fiatCurrency}
      isLoading={isSubmittingPayment}
      kycRequired={kycRequired}
      NetFiatAmountSlot={<FiatAmount amount={netFiatAmount} currency={fiatCurrency} />}
      pageTitle="Sell Confirmation"
      payoutMethod={state.payoutMethod}
      payoutMethodHref={generatePath(walletRoutes().sell.payoutMethod, params)}
      selectedPayoutBank={state.selectedPayoutBank}
      selectedPayoutCard={state.selectedPayoutCard}
      TotalCryptoAmountSlot={<CryptoAmount amount={totalCryptoAmount} currency={cryptoCurrency} />}
      onConfirm={onConfirm}
    />
  );
}
