import { getFeatureCurrencies } from '@noah-labs/fe-shared-data-access-user';
import {
  sortFilterWithFallback,
  useAccountsQueryPoll,
} from '@noah-labs/fe-shared-data-access-wallet';
import { Feature } from '@noah-labs/shared-schema-gql';
import { useFeature } from './useFeature';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useUserAccounts() {
  const { feature: buyFF } = useFeature(Feature.Buy);
  const { feature: depositFF } = useFeature(Feature.Deposit);
  const { feature: withdrawFF } = useFeature(Feature.Withdraw);

  return useAccountsQueryPoll(undefined, {
    select: (data) =>
      sortFilterWithFallback(data.accounts.items, {
        currencies: getFeatureCurrencies([buyFF, depositFF, withdrawFF]),
        isFeatureEnabled: Boolean(buyFF?.Enabled || depositFF?.Enabled || withdrawFF?.Enabled),
      }),
  });
}
