import type {
  TpCryptoCurrency,
  TpFiatCurrency,
  TpSimpleAmount,
} from '@noah-labs/shared-currencies';
import type { Network } from '@noah-labs/shared-schema-gql';
import { duration } from '@noah-labs/shared-util-vanilla';
import { useCalculateFiatFromCrypto } from './useCalculate';
import { useWithdrawFeeQuery } from './wallet.generated';

type PpUseWithdrawFeeApprox = {
  cryptoCurrency: TpCryptoCurrency;
  fiatCurrency: TpFiatCurrency | undefined;
  network: Network;
};

type TpUseWithdrawFeeApprox = {
  feeCryptoAmount: TpSimpleAmount;
  feeFiatAmount: TpSimpleAmount;
};

export function useWithdrawFeeApprox({
  cryptoCurrency,
  fiatCurrency,
  network,
}: PpUseWithdrawFeeApprox): TpUseWithdrawFeeApprox {
  const { data: feeData } = useWithdrawFeeQuery(
    {
      Input: {
        Currency: cryptoCurrency.code,
        Network: network,
      },
    },
    {
      refetchInterval: duration.seconds(10),
    },
  );

  const { data: priceData } = useCalculateFiatFromCrypto({
    cryptoAmount: feeData?.withdrawFee.NetworkFee,
    cryptoCurrency,
    fiatCurrency,
    priceProvider: 'market',
  });

  return {
    feeCryptoAmount: feeData?.withdrawFee.NetworkFee,
    feeFiatAmount: priceData?.fiatAmount,
  };
}
