import { useEffect } from 'react';
import { useAuth } from '@noah-labs/fe-shared-feature-auth';
import { useFeature, useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { useRouter, useUserLocalStorage } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Feature } from '@noah-labs/shared-schema-gql';
import { useRouteMatch } from 'react-router-dom';
import { KycReminderOrigin } from '../controllers/Reminder';
import { routes } from '../routes';

export function useLoginKycReminder(): void {
  const { addSignOutSubscriber } = useAuth();
  const { push } = useRouter();
  const isWalletPath = useRouteMatch(walletRoutes().base);
  const { data: userData } = useUserInitUi();
  const {
    isLoaded: isStorageLoaded,
    kycReminderWasShownOnLogin,
    setKycReminderWasShownOnLogin,
  } = useUserLocalStorage(userData?.userProfile.UserID);

  const { feature: fSumsub } = useFeature(Feature.Sumsub);
  const { feature: fKyc } = useFeature(Feature.Kyc);

  useEffect(
    () =>
      addSignOutSubscriber('kycReminder', () => {
        setKycReminderWasShownOnLogin(false);
      }),
    [addSignOutSubscriber, setKycReminderWasShownOnLogin],
  );

  useEffect(() => {
    if (!userData) {
      return;
    }

    /* we don't need to redirect to the reminder page if the KYC/Sumsub feature flag
    is disabled */
    if (!fSumsub?.Enabled || !fKyc?.Enabled) {
      return;
    }

    const attemptedOrHasKyc = !!userData.userProfile.KycVerification.ApprovalStatus;

    if (
      !isStorageLoaded ||
      !isWalletPath?.isExact ||
      attemptedOrHasKyc ||
      kycReminderWasShownOnLogin
    ) {
      return;
    }

    push({
      pathname: routes.reminder,
      search: new URLSearchParams({ origin: KycReminderOrigin.Login }).toString(),
    });
    setKycReminderWasShownOnLogin(true);
  }, [
    fKyc?.Enabled,
    fSumsub?.Enabled,
    isStorageLoaded,
    isWalletPath?.isExact,
    kycReminderWasShownOnLogin,
    push,
    setKycReminderWasShownOnLogin,
    userData,
  ]);
}
