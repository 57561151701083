import { useCallback } from 'react';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import KycId from '../../../images/kyc-id.svg?url';
import KycWallet from '../../../images/kyc-wallet.svg?url';
import { routes } from '../routes';
import { KycReminderScene } from '../scenes/KycReminder';

export enum KycReminderOrigin {
  Buy = 'buy',
  Deposit = 'deposit',
  Login = 'login',
  Onboarding = 'onboarding',
  Sell = 'sell',
  Withdraw = 'withdraw',
}

function getParagraph(origin: KycReminderOrigin | undefined): string {
  switch (origin) {
    case KycReminderOrigin.Onboarding:
      return 'Please complete KYC verification to unlock all the features of the NOAH app.';
    case KycReminderOrigin.Login:
      return "We've made important security upgrades. Please complete your KYC verification now to continue enjoying all app features.";
    case KycReminderOrigin.Deposit:
    case KycReminderOrigin.Buy:
    case KycReminderOrigin.Sell:
      return 'To ensure the safety of your assets, please complete your identity verification. Additionally, avoid sending funds to previously used NOAH wallet addresses to prevent potential loss.';
    case KycReminderOrigin.Withdraw:
      return 'Your NOAH account currently has a zero balance. Please complete your identity verification to continue using our transfer services.';
    default:
      return "We've made important security upgrades. Please complete your KYC verification now to continue enjoying all app features.";
  }
}

function getTitle(origin: KycReminderOrigin | undefined): string {
  switch (origin) {
    case KycReminderOrigin.Onboarding:
    case KycReminderOrigin.Login:
      return 'Please complete identity verification to continue';
    case KycReminderOrigin.Deposit:
      return 'Verify your identity to access your deposit addresses';
    case KycReminderOrigin.Buy:
    case KycReminderOrigin.Sell:
      return 'Complete KYC to ensure the safety of your assets';
    case KycReminderOrigin.Withdraw:
      return 'Verify your identity to enable transfers';
    default:
      return 'Complete KYC to continue using the NOAH app';
  }
}

export function KycReminder(): React.ReactElement {
  const { goBack, push, search } = useRouter();

  const origin = new URLSearchParams(search).get('origin') as KycReminderOrigin | undefined;

  const handleNotNowClick = useCallback(() => {
    goBack();
  }, [goBack]);

  const handleYesClick = useCallback(() => {
    push(routes.base);
  }, [push]);

  const showFaqLink = Boolean(origin === 'login' || origin === 'withdraw');

  const image = origin === KycReminderOrigin.Deposit ? KycWallet : KycId;
  const paragraph = getParagraph(origin);
  const title = getTitle(origin);

  return (
    <KycReminderScene
      handleNotNowClick={handleNotNowClick}
      handleYesClick={handleYesClick}
      image={image}
      paragraph={paragraph}
      showFaqLink={showFaqLink}
      title={title}
    />
  );
}
