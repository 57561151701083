import { TpFeExtraFeature } from '@noah-labs/fe-shared-data-access-user';
import { generatePath, Switch404, useStateMachine } from '@noah-labs/fe-shared-ui-components';
import type { TpPaymentBank, TpPaymentCard, TpPaymentMethod } from '@noah-labs/fe-shared-ui-shared';
import { TpAuthStatus, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import type { CountryCode, FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import { CurrencyUnit, Feature } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { getDefaults } from '../../../../utils/getDefaults';
import { AccessControlData } from '../../../auth/AccessControlData';
import { KycAccessControlData } from '../../../kyc/controllers/KycAccessControlData';
import { KycReminderOrigin } from '../../../kyc/controllers/Reminder';
import { Complete } from './Complete';
import { Confirm } from './Confirm';
import { EnterAccountDetails } from './EnterAccountDetails';
import { EnterAmount } from './EnterAmount';
import { EnterPaymentInfo } from './EnterPaymentInfo';
import { SelectPayoutMethod } from './SelectPayoutMethod';

export type StSellRouter = {
  cryptoAmount: string;
  cryptoUnit: CurrencyUnit;
  fetchedAt: string;
  fiatAmount: string;
  fiatCurrencyCode: FiatCurrencyCode;
  payoutMethod?: TpPaymentMethod;
  reference?: string;
  selectedCountry: CountryCode | undefined;
  selectedPayoutBank?: TpPaymentBank;
  selectedPayoutCard?: TpPaymentCard;
  transactionId?: string;
};

const { fiatCurrency } = getDefaults();

const emptyState: StSellRouter = {
  cryptoAmount: '',
  cryptoUnit: CurrencyUnit.Default,
  fetchedAt: '',
  fiatAmount: '',
  fiatCurrencyCode: fiatCurrency.code,
  selectedCountry: undefined,
  selectedPayoutCard: undefined,
  transactionId: undefined,
};

export function SellRouter(): React.ReactElement {
  const { params } = useWalletParams();
  const initialState = { ...emptyState };

  const sm = useStateMachine<StSellRouter>({
    emptyState,
    initialState,
    name: 'SellRouter',
  });

  const hasPaymentMethod = sm.state.selectedPayoutCard || sm.state.selectedPayoutBank;

  return (
    <Switch404>
      <Route exact path={[walletRoutes().sell.payoutMethod, walletRoutes().sell.enterPaymentInfo]}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[TpFeExtraFeature.SellRoutes]}
        >
          <Route exact path={walletRoutes().sell.payoutMethod}>
            <SelectPayoutMethod {...sm} />
          </Route>
          {/* Keep the screen in the DOM to preload Frames card fields */}
          <Route
            exact
            path={[walletRoutes().sell.payoutMethod, walletRoutes().sell.enterPaymentInfo]}
          >
            <EnterPaymentInfo {...sm} />
          </Route>
        </AccessControlData>
      </Route>
      <Route exact path={walletRoutes().sell.enterAccountDetails}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[TpFeExtraFeature.SellRoutes]}
        >
          <EnterAccountDetails {...sm} />
        </AccessControlData>
      </Route>

      <Route exact path={walletRoutes().sell.enterAmount}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[TpFeExtraFeature.SellRoutes]}
        >
          <EnterAmount {...sm} />
        </AccessControlData>
      </Route>

      <Route exact path={walletRoutes().sell.confirm}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[TpFeExtraFeature.SellRoutes]}
          redirectInvalid={
            !hasPaymentMethod && generatePath(walletRoutes().sell.payoutMethod, params)
          }
        >
          <KycAccessControlData origin={KycReminderOrigin.Sell}>
            <Confirm {...sm} />
          </KycAccessControlData>
        </AccessControlData>
      </Route>

      <Route exact path={walletRoutes().sell.complete}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Sell]}
        >
          <KycAccessControlData origin={KycReminderOrigin.Sell}>
            <Complete {...sm} />
          </KycAccessControlData>
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
