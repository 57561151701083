import { Badge } from '@mui/material';
import { FaqIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/FaqIcon';
import type { PpHeaderIconButton } from '@noah-labs/fe-shared-ui-components';
import { HeaderIconButton } from '@noah-labs/fe-shared-ui-components';
import { useLiveChat } from '../../modules/help/hooks/useLiveChat';
import { webConfigBrowser } from '../../webConfigBrowser';

type PpHelpIconButtonData = Pick<PpHeaderIconButton, 'edge'>;

export function HelpIconButtonData({ edge }: PpHelpIconButtonData): React.ReactElement {
  const { loaded, unreadMessages } = useLiveChat();

  return (
    <HeaderIconButton
      aria-label="help"
      className={webConfigBrowser.intercom.customLauncherSelector}
      data-qa={webConfigBrowser.intercom.customLauncherSelector}
      disabled={!loaded}
      edge={edge}
      id={webConfigBrowser.intercom.customLauncherSelector}
    >
      <Badge
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        color="error"
        invisible={!unreadMessages}
        variant="dot"
      >
        <FaqIcon />
      </Badge>
    </HeaderIconButton>
  );
}
