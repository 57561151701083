import React, { useCallback } from 'react';
import { useCountriesForFeature } from '@noah-labs/fe-shared-data-access-countries';
import { useFiatPaymentMethodBankSaveMutation } from '@noah-labs/fe-shared-data-access-wallet';
import { useUserDefaultBillingAddress, useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import type { TpStateMachine } from '@noah-labs/fe-shared-ui-components';
import { TpPaymentMethod, useRouter } from '@noah-labs/fe-shared-ui-shared';
import {
  DefaultBankFormFields,
  SortCodeFormFields,
  UsBankFormFields,
  useWalletError,
} from '@noah-labs/fe-shared-ui-wallet';
import {
  defaultBankFormSchema,
  SortCodeFormSchema,
  USBankFormSchema,
} from '@noah-labs/fe-shared-util-validation-schemas';
import { Feature } from '@noah-labs/shared-schema-gql';
import type {
  CountryCode,
  FiatCurrencyCode,
  FiatPaymentBankSaveInput,
} from '@noah-labs/shared-schema-gql';
import { useLocation } from 'react-router-dom';
import type { z } from 'zod';
import { webConfigBrowser } from '../../../../webConfigBrowser';
import type { TpAccountDetailsFormValues } from '../../scenes/EnterAccountDetails';
import { EnterAccountDetailsScene } from '../../scenes/EnterAccountDetails';
import { getAccountHolderName, getBankingSystem } from '../../utils/utils';
import type { StSellRouter } from './SellRouter';

type PpEnterAccountDetails = TpStateMachine<StSellRouter>;

export function EnterAccountDetails({
  state,
  updateState,
}: PpEnterAccountDetails): React.ReactElement {
  const { data: userData } = useUserInitUi();
  const defaultAddress = useUserDefaultBillingAddress();
  const { data: countriesData } = useCountriesForFeature(
    userData?.userProfile.PrimaryAddress?.Country,
    [Feature.Sell],
    webConfigBrowser.regions.ProhibitedCountries,
    webConfigBrowser.regions.RestrictedRegions,
  );
  const { error: saveBankAccountError, mutateAsync: saveBankAccountMutation } =
    useFiatPaymentMethodBankSaveMutation();

  const { push } = useRouter();
  const { state: locationState } = useLocation<{ successTo: string }>();

  const onSubmit = useCallback(
    async (accountDetails: TpAccountDetailsFormValues) => {
      try {
        const accountHolderAddress = {
          City: accountDetails.billingAddress.City,
          Country: accountDetails.billingAddress.Country as CountryCode,
          PostCode: accountDetails.billingAddress.PostCode,
          State: accountDetails.billingAddress.State,
          Street: accountDetails.billingAddress.Street,
          Street2: accountDetails.billingAddress.Street2,
        };
        const { accountNumber, bankCode } = accountDetails;
        const accountCurrency = accountDetails.accountCurrency as FiatCurrencyCode;

        const fpmBankSaveData = await saveBankAccountMutation({
          Input: {
            AccountCurrency: accountCurrency,
            AccountHolderDetails: {
              Address: accountHolderAddress,
              Name: getAccountHolderName(accountDetails.accountHolderName),
            },
            AccountNumber: accountNumber,
            BankCode: bankCode,
            BankCountry: state.selectedCountry as CountryCode,
            Reusable: Boolean(accountDetails.saveAccount),
            System: getBankingSystem(state.selectedCountry),
          } satisfies FiatPaymentBankSaveInput,
        });

        if (!fpmBankSaveData.fiatPaymentMethodBankSave.DynamoID) {
          throw new Error('Failed to save payment method');
        }

        updateState({
          fiatCurrencyCode: accountCurrency,
          payoutMethod: TpPaymentMethod.BankTransfer,
          selectedPayoutBank: {
            accountCurrency,
            accountHolderAddress,
            accountNumber,
            bankCode,
            id: fpmBankSaveData.fiatPaymentMethodBankSave.DynamoID,
          },
        });

        push(locationState.successTo);
      } catch {
        // Handled by useWalletError
      }
    },
    [locationState.successTo, state.selectedCountry, updateState, saveBankAccountMutation, push],
  );

  let schema: z.ZodSchema<TpAccountDetailsFormValues>;
  let FormSlot: React.ReactElement;

  switch (state.selectedCountry) {
    case 'US': {
      schema = USBankFormSchema;
      FormSlot = UsBankFormFields;

      break;
    }

    case 'GB': {
      schema = SortCodeFormSchema;
      FormSlot = SortCodeFormFields;
      break;
    }

    default:
      schema = defaultBankFormSchema;
      FormSlot = DefaultBankFormFields;
      break;
  }

  const { ApiErrorScene } = useWalletError(saveBankAccountError);
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <EnterAccountDetailsScene
      countries={countriesData?.countries}
      defaultAddress={defaultAddress}
      FormSlot={FormSlot}
      pageTitle="Account details"
      schema={schema}
      selectedCountry={state.selectedCountry}
      onSubmit={onSubmit}
    />
  );
}
